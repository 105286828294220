import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import apiService from "../../core/apiService";
import { useSelector } from "react-redux";
import Select from 'react-select';
import {
  dropDownOption, perdropDownOption
} from "../../helpers";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import Multiselect from "multiselect-react-dropdown";
import CustomError from "../../components/CustomError";
const GameDrinkCreate = () => {
  const restaurant = useSelector((state) => state.restaurant);
  const [Step, setStep] = useState(0);
  const [createdSuccess, setcreatedSuccess] = useState(false);
  const [drinkNameText, setDrinkNameText] = useState('');
  const [isFormTouched, setIsFormTouched] = useState(false);
  const [winenames, setWinetypes] = useState([])
  const [drinkData, setDrinkData] = useState([])
  const navigate = useNavigate();
  const location = useLocation();
  const fromPage = location.state?.from?.pathname || "/game-day-special/drink";
  const createPage =
    location.state?.from?.pathname || "/game-day-special/drink";
  const [liquorType, setliquorType] = useState("1");
  const [gameList, setGameList] = useState([]);
  const [gameteamList, setGameTeamList] = useState([]);
  const [game, setGame] = useState("");
  const [gameError, setGameError] = useState("");
  const [gameteam, setGameTeam] = useState("");
  const [drinkList, setDrinkList] = useState([]);
  const [containerList, setContainerList] = useState([]);
  const [sizeList, setSizeList] = useState([]);
  const [regionList, setRegionList] = useState([]);
  const [wineTypeList, setwineTypeList] = useState([]);
  const [wineSubTypeList, setwineSubTypeList] = useState([]);
  const [discountType, setDiscountType] = useState("");
  const [discountPrice, setDiscountPrice] = useState("");
  const [description, setDescription] = useState("");
  const [winename, setWine] = useState(0)
  const [region, setRegion] = useState(0);
  const [winetype, setWineType] = useState("-1");
  const [winesubtype, setWineSubType] = useState("-1");
  const [container, setContainer] = useState(0);
  const [size, setSize] = useState(0);
  const [drinkname, setDrinkname] = useState("-1");
  const [winenamess, setWinename] = useState([])
  const [selectedtype, setSelectedWineType] = useState([])
  const [selectedWineName, setSelectedWineName] = useState([])
  const [drinknameError, setDrinkNameError] = useState("");
  const [standaradError, setstandandError] = useState("");
  const [wineTypes, setMasterType] = useState([])
  const [wineError, setWineandError] = useState("");
  const [sizeShow, setSizeShow] = useState(true);
  const [typeShow, setTypeShow] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [drink, setDrink] = useState([])
  const special_id = "2";
  const type_id = "2";
  const [drinkLists, setDrinkLists] = useState([]);
  const Priceoption = dropDownOption();
  const perPriceoption = perdropDownOption()
  const [standared, setSelectedStanderd] = useState([])
  const [special, setSelectedSpecials] = useState([]);

  const nextStep = () => {
    if (Step === 0 && (game === "" || gameteam === "")) {
      setGameError("Select one option!");
      return;
    }
    setStep(Step + 1);
  };
  const backStep = () => {
    setStep(Step - 1);
  };

  const changeliquorType = (e) => {
    setliquorType(e.target.value);
  };

  const back = () => {
    navigate(createPage, { replace: true });
  };


  console.log(winenames.filter(item => item.value == winetype), 'filtertype')




  useEffect(() => {
    apiService.get(`/restaurants/get-sports`, {}, ({ err, data }) => {
      if (err) return console.log(err);
      setGameList(data.data);
    });
    apiService.get(`/restaurants/get-sportsteam`, {}, ({ err, data }) => {
      if (err) return console.log(err);
      setGameTeamList(data.data);
    });
    apiService.get(`/restaurants/drinkTypes`, {}, ({ err, data }) => {
      if (err) return console.log(err);
      setDrinkList(data.data);
    });
    apiService.get(`/restaurants/get-container`, {}, ({ err, data }) => {
      if (err) return console.log(err);
      setContainerList(data.data);
    });
    apiService.get(`/restaurants/get-size`, {}, ({ err, data }) => {
      if (err) return console.log(err);
      setSizeList(data.data);
    });
    apiService.get(`/restaurants/get-region`, {}, ({ err, data }) => {
      if (err) return console.log(err);
      setRegionList(data.data);
    });
    // apiService.get(`/restaurants/get-winetype`, {}, ({ err, data }) => {
    //   if (err) return console.log(err);
    //   setwineTypeList(data.data);
    // });
    apiService.get(`/restaurants/get-winesubtype`, {}, ({ err, data }) => {
      if (err) return console.log(err);
      setwineSubTypeList(data.data);
    });
    apiService.get(`/listMasterWine`, {}, ({ err, data }) => {
      if (err) return console.log(err);
      console.log(data.data, "datdatda")
      setMasterType(data.data);
    });
  }, []);


  useEffect(() => {
    apiService.get(`/listDrinkTags`, { drink_type: liquorType }, ({ err, data }) => {
      if (err) return console.log(err);

      let res = [];
      data.data?.map((item) => {
        const temp = {};
        temp["label"] = `${item["tag_name"]}`;
        temp["value"] = item["id"];
        res.push(temp);
      });


      setDrinkData(res);
    });
    apiService.get(`/listDrinkNames`, { drink_type: liquorType }, ({ err, data }) => {
      let Name = ""
      if (liquorType == 1) Name = "Multiple Wines"
      if (liquorType == 2) Name = "Multiple Beers"
      if (liquorType == 3) Name = "Multiple Cider"
      if (liquorType == 4) Name = "Multiple Others"
      if (err) return console.log(err);
      let temp = data.data?.map((drinkname) => ({
        value: drinkname.id,
        label: drinkname.drink_name,
      }))
      setDrinkLists([{ value: 0, label: Name }, ...temp]);
      let res = [];
      data.data?.map((item) => {
        console.log(item, 'itemmms1')
        const temp = {};
        temp["label"] = `${item["drink_name"]}`;
        temp["value"] = item["id"];
        res.push(temp);
      });
      setDrink(res)

    });


    apiService.get(`/restaurants/get-winetype`, {}, ({ err, data }) => {
      console.log(data.data, 'dtatdta')
      if (err) return console.log(err);
      let res = [];
      (winename == 5 ? data.data : data.data?.filter(drink => drink.masterwine_id == winename))?.map((item) => {

        const temp = {};
        temp["label"] = `${item["wine_name"]}`;
        temp["value"] = item["id"];


        res.push(temp);
      });
      console.log(res, 'resresresd')
      if (res.length !== 0) {
        res.unshift({ value: 0, label: "Multiple Wine Types" })
      }
      // setDrink(res) 

      setwineTypeList(res);

      let res1 = [];
      data.data?.map((item) => {

        const temp = {};
        temp["label"] = `${item["wine_name"]}`;
        temp["value"] = item["id"];

        res1.push(temp);

      });

      setWinetypes(res1)
    });


    apiService.get(`/restaurants/get-winesubtype`, {}, ({ err, data }) => {
      if (err) return console.log(err);
      let temp = data.data?.map((winetype) => ({
        value: winetype.id,
        label: winetype.winesubtype_name,
      }))
      setwineSubTypeList([{ value: 0, label: "Multiple Wine Names" }, ...temp]);
      // setwineSubTypeList(data.data);
      let res2 = [];
      data.data?.map((item) => {
        console.log(item, 'itemmms1wines')
        const temp = {};
        temp["label"] = `${item["winesubtype_name"]}`;
        temp["value"] = item["id"];
        res2.push(temp);
      });
      console.log(res2, 'res2')
      setWinename(res2)
    });

  }, [liquorType, winename])

  useEffect(() => {
    setWineType('0')
  }, [winename])

  const handleOnchangeSpecials = (val) => {

    setSelectedSpecials(val);

  };
  const handleOnchangeStand = (val) => {

    setSelectedStanderd(val);

  };
  const handleOnchangeWineType = (val) => {

    setSelectedWineType(val);

  };
  const handleOnchangeWineName = (val) => {

    setSelectedWineName(val);

  };

  useEffect(() => {
    if (drinkname > 0) {
      setSelectedStanderd([])
    }
    if (winesubtype > 0) {
      setSelectedWineName([])
    }
    if (winetype > 0) {
      setSelectedWineType([])
    }
  }, [drinkname, winesubtype, winetype])

  useEffect(() => {
    setWineType('-1')
    setSelectedWineType([])
  }, [winename])

  const handleSubmit = () => {
    setIsFormTouched(true);
    let sizing = sizeList?.filter(item => item.container_id == container && item.Drink_type_id == liquorType)
    if ((sizing.length > 0 && !size) && parseInt(liquorType) !== 4) {
      console.log("inside if")
      return
    };
    let temp = null
    if (!sizing.length) {
      console.log("inside  if 2nd ")
      temp = 0
    }

    if (liquorType == '1' && winetype == 0 && selectedtype.length == 0) return setstandandError('Wine Type Tag  is required!');
    if (liquorType == '1' && winesubtype == 0 && selectedWineName.length == 0) return setWineandError('Wine Name  Tag  is required!');

    let drink_ids = {}
    if (standared.length == 0) {
      drink_ids = { "drink_ids": JSON.stringify([drinkname]), tags_ids: JSON.stringify([...special.map(item => item.value)]) }
    } else
      drink_ids = { "drink_ids": JSON.stringify([...standared.map(item => item.value)]), tags_ids: JSON.stringify([...special.map(item => item.value)]) }
    console.log(drink_ids, "chekci")


    let winestag = {}
    if (selectedWineName.length == 0 && selectedtype.length == 0) {
      winestag = { "wine_ids": JSON.stringify([winesubtype]), "wine_type_ids": JSON.stringify([Number(winetype)]), tags_ids: JSON.stringify([...special.map(item => item.value)]) }
    }
    if (selectedWineName.length > 0 && selectedtype.length == 0) {
      winestag = { "wine_ids": JSON.stringify([...selectedWineName.map(item => item.value)]), "wine_type_ids": JSON.stringify([Number(winetype)]), tags_ids: JSON.stringify([...special.map(item => item.value)]) }
    }
    if (selectedWineName.length == 0 && selectedtype.length > 0) {
      winestag = { "wine_ids": JSON.stringify([winesubtype]), "wine_type_ids": JSON.stringify([...selectedtype.map(item => item.value)]), tags_ids: JSON.stringify([...special.map(item => item.value)]) }
    }
    if (selectedWineName.length > 0 && selectedtype.length > 0) {
      winestag = { "wine_ids": JSON.stringify([...selectedWineName.map(item => item.value)]), "wine_type_ids": JSON.stringify([...selectedtype.map(item => item.value)]), tags_ids: JSON.stringify([...special.map(item => item.value)]) }

    }

    if (winesubtype < 0 && parseInt(liquorType) === 1) return;
    let data = {};
    if (liquorType === '4' && drinkname < 0) return setDrinkNameError('Drink Name is required!');
    if (liquorType !== '1' && drinkname == 0 && standared.length < 1) return setstandandError('Standrad Tag is required!');

    if (liquorType === "1") {
      data = {
        restaurant_id: restaurant?.id,
        special_id: special_id,
        type_id: type_id,
        sports_id: game,
        team_id: gameteam,
        drinktype_id: liquorType,
        region_id: region,
        wine_subtype_id: winesubtype,
        wine_type_id: winetype,
        container_type_id: container,
        size_id: temp == null ? size : temp,
        discount_type: discountType,
        discount_amount: discountPrice,
        item_description: description,
        masterwine_id: winename,
        data1: winestag


      };
    } else {
      data = {
        restaurant_id: restaurant?.id,
        special_id: special_id,
        drink_name: drinkNameText,
        type_id: type_id,
        sports_id: game,
        team_id: gameteam,
        drinktype_id: liquorType,
        drink_name_id: drinkname,
        container_type_id: container,
        size_id: size,
        discount_type: discountType,
        discount_amount: discountPrice,
        item_description: description,
        data1: drink_ids
      };
    }
    // if( !((sizeList?.filter(item=>item.container_id == container && item.Drink_type_id == liquorType)).length))data.size_id=0;

    if (discountPrice && description && discountType && ((liquorType === "1" || liquorType === '4') || drinkname >= 0)) {
      if (((liquorType === "1" || liquorType === "2" || liquorType === "3") && container > 0) || liquorType === "4") {
        if (liquorType == 1) {
          if (!(winetype >= 0 && winename > 0 && winesubtype >= 0)) {
            CustomError('Please fill all the required fields', '')
            return
          }
        }


        setIsLoading(true)
        apiService.post("/restaurants/specials/create", data, ({ err, data }) => {
          if (err) {
            console.log(err);
            setIsLoading(false)
          }
          setIsLoading(false)
          setcreatedSuccess(createdSuccess === false ? true : false);
          setTimeout(() => {
            navigate(`/game-day-special/drink?type=${liquorType}`, { replace: true });
          }, 5000);
        });
      }
      else CustomError('Please fill all the required fields', '')
    }
    else CustomError('Please fill all the required fields', '')
  };


  let filter = gameteamList?.filter(items => items.sports_id == game)

  return (
    <>
      <div className="dashboard-auto-wrapper">
        <div className="create-from">
          {Step === 0 && (
            <div className="create-from-steps active">
              <h4>What sporting event is your special for?</h4>
              <div className="create-form-inner mt-4">
                <div className="row">
                  <div className="col-md-7">
                    <div className="form-group mb-4">
                      <label className="form-label">Sport <span className="text-danger">*</span></label>
                      <select
                        className="form-control"
                        name="sports"
                        value={game}
                        onChange={(e) => setGame(e.target.value)}
                      >
                        <option value=''>Select</option>
                        {gameList.length ? (
                          gameList.map((sport, i) => (
                            <option value={sport.id}>
                              {sport.sports_name}
                            </option>
                          ))
                        ) : (
                          <></>
                        )}
                      </select>
                    </div>
                  </div>
                  <div className="col-md-7">
                    <div className="form-group mb-4">
                      <label className="form-label">Team <span className="text-danger">*</span></label>
                      <select
                        className="form-control"
                        name="sportsteam"
                        value={gameteam}
                        onChange={(e) => setGameTeam(e.target.value)}
                      >
                        <option disabled value=''>Select</option>
                        {gameteamList.length ?

                          (
                            (
                              filter?.length > 0 ? (
                                filter?.map((team, i) => (
                                  <option key={i} value={team.id}>
                                    {team.team_name}
                                  </option>
                                ))
                              ) : (

                                <option disabled value='' >
                                  No matching teams found
                                </option>
                              )
                            )
                          ) : (
                            <></>
                          )}
                      </select>
                    </div>
                  </div>
                  <span className="error">{gameError}</span>
                </div>
              </div>
              <div className="create-from-btn">
                <button className="btn btn-transparent" onClick={back}>
                  Back
                </button>
                <button className="btn btn-dark" disabled={!(filter?.length)} onClick={nextStep}>
                  Next
                </button>
              </div>
            </div>
          )}
          {Step === 1 && (
            <div className="create-from-steps active">

              <h4>Please Select Type</h4>
              <div className="create-form-inner mt-4">
                <div className="form-group mb-4">
                  <label className="form-label">Type <span className="text-danger">*</span></label>
                  <select
                    value={liquorType}
                    onChange={changeliquorType}
                    className="form-control"
                  >
                    {drinkList.length ? (
                      drinkList.map((drink, i) => (
                        <option value={drink.id}>{drink.drinktype_name}</option>
                      ))
                    ) : (
                      <></>
                    )}
                  </select>

                </div>

              </div>

              <div className="create-from-btn mt-5">
                <button className="btn btn-transparent" onClick={backStep}>
                  Back
                </button>
                <button className="btn btn-dark" onClick={nextStep}>
                  Next
                </button>

              </div>
            </div>
          )}
          {Step === 2 && liquorType === "1" && (
            <div className="create-from-steps active">
              <h4>Wine</h4>
              <div className="create-form-inner mt-4">
                <div className="form-group mb-4">
                  <label className="form-label">Region </label>
                  <select
                    className="form-control"
                    onChange={(e) => setRegion(e.target.value)}
                  >
                    <option value='0'>Select </option>
                    {regionList.length ? (
                      regionList.map((region, i) => (
                        <option value={region.id}>{region.region_name}</option>
                      ))
                    ) : (
                      <></>
                    )}
                  </select>
                </div>
                <div className="form-group mb-4">
                  <label className="form-label">Red/White<span className="text-danger">*</span></label>
                  <select
                    className="form-control"
                    onChange={(e) => {
                      setWine(e.target.value)
                      // {
                      //   e.target.value !== "10"
                      //     ? setTypeShow(true)
                      //     : setTypeShow(false); setWineSubType(0)
                      // }
                    }}
                  >
                    <option value=''>Select </option>
                    {wineTypes.length ? (
                      wineTypes.map((wine, i) => (
                        <option value={wine.id}>{wine.wine_type_name}</option>
                      ))
                    ) : (
                      <></>
                    )}
                  </select>
                </div>
                {typeShow === true ? (
                  <div className="form-group mb-4">
                    <label className="form-label">Type <span className="text-danger">*</span></label>
                    <select
                      className="form-control"
                      value={winetype}
                      onChange={(e) => setWineType(e.target.value)}
                    >
                      <option value='-1'>Select </option>
                      {wineTypeList?.length ? (
                        wineTypeList
                          .map((wine, i) => (

                            <option value={wine.value}>{wine.label}</option>
                          ))
                      ) : (
                        <></>
                      )}
                    </select>
                    {
                      (isFormTouched && winesubtype < 0) && <span className="error">Type is required!</span>

                    }
                  </div>
                ) : (
                  <></>
                )}
                <div className="col-md-12 mb-4">
                  <div className="form-group">
                    <div className="input-right-icon">
                      <label className="form-label">Tags for wine Type</label>
                      <Multiselect
                        placeholder="Select Wine type Tags"
                        options={winenames.filter(item => item.value == winetype).length ? winenames.filter(item => item.value == winetype) : wineTypeList?.slice(1)}
                        onSelect={handleOnchangeWineType}
                        disablePreSelectedValues={!!winenames.filter(item => item.value == winetype).length}
                        displayValue="label"
                        // selectedValues={selectedtype}    
                        selectedValues={winenames.filter(item => item.value == winetype).length ? winenames.filter(item => item.value == winetype) : selectedtype}
                        onRemove={(selectedList, removedItem) => {
                          setSelectedWineType(selectedList)
                        }}
                      />
                    </div>
                    <span className="error">{standaradError}</span>

                  </div>
                </div>
                <div className="form-group mb-4">
                  <label className="form-label">Name <span className="text-danger">*</span></label>
                  <Select
                    classNamePrefix="form-control"

                    onChange={(selectedOption) => setWineSubType(selectedOption.value)}

                    options={wineSubTypeList}
                  />

                  {/* {
											(isFormTouched && !winesubtype) && <span className="error">Name is required!</span>

										} */}
                  {/* <span className="error">{drinknameErrorr}</span> */}
                </div>
                <div className="col-md-12 mb-4">
                  <div className="form-group">
                    <div className="input-right-icon">
                      <label className="form-label">Tags for wine Names</label>
                      <Multiselect
                        placeholder="Select Wine name Tags"

                        options={winenamess.filter(item => item.value == winesubtype).length ? winenamess.filter(item => item.value == winesubtype) : winenamess}
                        // options={food.filter((item => item.value == foodCat))?.length ? food.filter((item => item.value == foodCat)) : food}
                        onSelect={handleOnchangeWineName}
                        disablePreSelectedValues={!!winenamess.filter(item => item.value == winesubtype).length}
                        displayValue="label"
                        selectedValues={winenamess.filter(item => item.value == winesubtype)}
                        onRemove={(selectedList, removedItem) => {
                          setSelectedStanderd(selectedList)
                        }}
                      />
                    </div>
                    <span className="error">{wineError}</span>

                  </div>
                </div>
                <div className="col-md-12 mb-4">
                  <div className="form-group">
                    <div className="input-right-icon">
                      <label className="form-label">Special Tags</label>
                      <Multiselect
                        placeholder="Select Special Tags"
                        options={drinkData}
                        displayValue="label"
                        onSelect={handleOnchangeSpecials}
                        onRemove={(selectedList, removedItem) => {
                          setSelectedSpecials(selectedList)
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="form-group mb-4">
                  <label className="form-label">Container Type <span className="text-danger">*</span></label>
                  <select
                    className="form-control"
                    onChange={(e) => {
                      setContainer(e.target.value);
                      // {
                      //   e.target.value === "6"
                      //     ? setSizeShow(true)
                      //     : setSizeShow(false); setSize(0)
                      // }
                    }}
                  >
                    <option >Select </option>
                    {containerList.length ? (
                      containerList?.filter(item => item.Drink_type_id == liquorType)?.map((container, i) => (
                        <option value={container.id}>
                          {container.container_name}
                        </option>
                      ))
                    ) : (
                      <></>
                    )}
                  </select>

                </div>
                {((sizeList?.filter(item => item.container_id == container && item.Drink_type_id == liquorType)).length) ? (
                  <div className="form-group mb-4">
                    <label
                      className="form-label"
                    >
                      Size <span className="text-danger">*</span>
                    </label>
                    <select className="form-control" onChange={(e) => setSize(e.target.value)}>
                      <option value='0'>Select </option>
                      {sizeList?.length ? (
                        sizeList?.filter(item => item.container_id == container && item.Drink_type_id == liquorType)?.map((size, i) => (
                          <option value={size.id}>{size.size_name}</option>
                        ))
                      ) : (
                        <></>
                      )}
                    </select>
                    {
                      (isFormTouched && !size && container) && <span className="error">Size is required!</span>
                    }
                  </div>
                ) : (
                  <></>
                )}
                <div className="form-group mb-4">
                  <label className="form-label">Discount <span className="text-danger">*</span></label>
                  <div className="form-group d-flex align-items-center flex-row mb-2">
                    <label className="custom-radio mb-0">
                      Percentage
                      <input
                        type="radio"
                        name="discountType"
                        onChange={(e) => setDiscountType("percentage")}
                      />
                      <span className="checkmark"></span>
                    </label>
                    <label className="custom-radio ms-3 mb-0">
                      Price
                      <input
                        type="radio"
                        name="discountType"
                        onChange={(e) => setDiscountType("price")}
                      />
                      <span className="checkmark"></span>
                    </label>
                    <label className="custom-radio ms-3 mb-0">
                      Amount Off
                      <input
                        type="radio"
                        name="discountType"
                        onChange={(e) => setDiscountType("amount")}
                      />
                      <span className="checkmark"></span>
                    </label>
                  </div>
                  <Select
                    classNamePrefix="form-control"
                    name="discountPrice"
                    onChange={(e) => setDiscountPrice(e.value)}
                    options={discountType == "percentage" ? perPriceoption : Priceoption}
                    value={discountType == "percentage" ? perPriceoption.filter(function (option) {
                      console.log(option.value, 'value')
                      return option.value === discountPrice;
                    }) :
                      Priceoption.filter(function (option) {
                        return option.value === discountPrice;
                      })}
                  />
                </div>
                <div className="form-group mb-4">
                  <label className="form-label">Description (255 characters)<span className="text-danger">*</span></label>
                  <textarea maxLength={255}
                    className="form-control"
                    name="description"
                    onChange={(e) => setDescription(e.target.value)}
                  />
                </div>
              </div>
              <div className="create-from-btn mt-5">
                <button className="btn btn-transparent" onClick={backStep}>
                  Back
                </button>
                <button className="btn btn-dark" onClick={handleSubmit} disabled={isLoading} >
                  {
                    isLoading ? <FontAwesomeIcon icon={faCircleNotch} spin /> : 'Save'

                  }</button>
              </div>
            </div>
          )}
          {Step === 2 && liquorType === "2" && (
            <div className="create-from-steps active">
              <h4>Beer</h4>
              <div className="create-form-inner mt-4">
                <div className="form-group mb-4">
                  <label className="form-label">Name <span className="text-danger">*</span></label>
                  {/* <Select
										classNamePrefix="form-control"
									
										onChange={(selectedOption) => setDrinkname(selectedOption.value)}
										options={drinkList?.length ?(drinkList
											.filter((drink) => drink.id == liquorType)
											.map((drink) =>
											  drink.drinknames.map((drinkname) => ({
												value: drinkname.id,
												label: drinkname.drink_name,
											  }))
											)
											.flat()):(<></>)} 

									/>	 */}
                  <Select
                    classNamePrefix="form-control"
                    onChange={(selectedOption) => setDrinkname(selectedOption.value)}
                    options={drinkLists}
                  />
                  <div className="d-flex align-items-center  graph-sets mt-2">

                    <p> <b>Note:</b> Don’t see your option here? Submit a request to admin.</p>
                  </div>
                </div>
                <div className="form-group mb-4">
                  <label className="form-label">Container Type <span className="text-danger">*</span></label>
                  <select
                    className="form-control"
                    onChange={(e) => {
                      setContainer(e.target.value);
                      // {
                      //   e.target.value === "6"
                      //     ? setSizeShow(true)
                      //     : setSizeShow(false); setSize(0)
                      // }
                    }}
                  >
                    <option value=''>Select </option>
                    {containerList.length ? (
                      containerList?.filter(item => item.Drink_type_id == liquorType)?.map((container, i) => (
                        <option value={container.id}>
                          {container.container_name}
                        </option>
                      ))
                    ) : (
                      <></>
                    )}
                  </select>
                </div>
                {((sizeList?.filter(item => item.container_id == container && item.Drink_type_id == liquorType)).length) ? (
                  <div className="form-group mb-4">
                    <label
                      className="form-label"
                    > Size
                      <span className="text-danger">*</span> </label>
                    <select className="form-control" onChange={(e) => setSize(e.target.value)}>
                      <option value=''>Select </option>
                      {sizeList.length ? (
                        sizeList?.filter(item => item.container_id == container && item.Drink_type_id == liquorType).map((size, i) => (
                          <option value={size.id}>{size.size_name}</option>
                        ))
                      ) : (
                        <></>
                      )}
                    </select>
                    {
                      // (isFormTouched && !size && parseInt(container) === 3) && <span className="error">Size is required!</span>
                    }
                  </div>
                ) : (
                  <></>
                )}
                <div className="form-group mb-4">
                  <label className="form-label">Discount <span className="text text-danger">*</span></label>
                  <div className="form-group d-flex align-items-center flex-row mb-2">
                    <label className="custom-radio mb-0">
                      Percentage
                      <input
                        type="radio"
                        name="discountType"
                        onChange={(e) => setDiscountType("percentage")}
                      />
                      <span className="checkmark"></span>
                    </label>
                    <label className="custom-radio ms-3 mb-0">
                      Price
                      <input
                        type="radio"
                        name="discountType"
                        onChange={(e) => setDiscountType("price")}
                      />
                      <span className="checkmark"></span>
                    </label>
                    <label className="custom-radio ms-3 mb-0">
                      Amount Off
                      <input
                        type="radio"
                        name="discountType"
                        onChange={(e) => setDiscountType("amount")}
                      />
                      <span className="checkmark"></span>
                    </label>
                  </div>
                  <Select
                    classNamePrefix="form-control"
                    name="discountPrice"
                    onChange={(e) => setDiscountPrice(e.value)}
                    options={discountType == "percentage" ? perPriceoption : Priceoption}
                    value={discountType == "percentage" ? perPriceoption.filter(function (option) {
                      console.log(option.value, 'value')
                      return option.value === discountPrice;
                    }) :
                      Priceoption.filter(function (option) {
                        return option.value === discountPrice;
                      })}
                  />
                </div>
                <div className="form-group mb-4">
                  <label className="form-label">Description (255 characters)<span className="text-danger">*</span></label>
                  <textarea maxLength={255}
                    className="form-control"
                    name="description"
                    onChange={(e) => setDescription(e.target.value)}
                  />
                </div>
                <div className="col-md-12 mb-4">
                  <div className="form-group">
                    <div className="input-right-icon">
                      <label className="form-label">Special Tags</label>
                      <Multiselect
                        placeholder="Select Special Tags"
                        options={drinkData}
                        displayValue="label"
                        onSelect={handleOnchangeSpecials}
                        onRemove={(selectedList, removedItem) => {
                          setSelectedSpecials(selectedList)
                        }}
                      />
                    </div>
                  </div>
                </div>

                <div className="col-md-12">
                  <div className="form-group">
                    <div className="input-right-icon">
                      <label className="form-label">Standard Tags</label>
                      <Multiselect
                        placeholder="Select Beer Tags"
                        options={drink.filter((item => item.value == drinkname))?.length ? drink.filter((item => item.value == drinkname)) : drink}
                        // options={food.filter((item => item.value == foodCat))?.length ? food.filter((item => item.value == foodCat)) : food}
                        onSelect={handleOnchangeStand}
                        disablePreSelectedValues={!!drink.filter((item => item.value == drinkname)).length}
                        displayValue="label"
                        selectedValues={drink.filter((item => item.value == drinkname))}
                        onRemove={(selectedList, removedItem) => {
                          setSelectedStanderd(selectedList)
                        }}
                      />
                    </div>
                    <span className="error">{standaradError}</span>

                  </div>
                  <div className="d-flex align-items-center  graph-sets mt-2">

                    <p> <b>Note:</b>  If ‘Multiple Items’ is selected for Name Category, select all tags that apply.</p>
                  </div>
                </div>
              </div>
              <div className="create-from-btn mt-5">
                <button className="btn btn-transparent" onClick={backStep}>
                  Back
                </button>
                <button className="btn btn-dark" onClick={handleSubmit} disabled={isLoading} >
                  {
                    isLoading ? <FontAwesomeIcon icon={faCircleNotch} spin /> : 'Save'

                  }</button>
              </div>
            </div>
          )}
          {Step === 2 && liquorType === "3" && (
            <div className="create-from-steps active">
              <h4>Cider</h4>
              <div className="create-form-inner mt-4">
                <div className="form-group mb-4">
                  <label className="form-label">Name <span className="text-danger">*</span></label>
                  {/* <Select
										classNamePrefix="form-control"
									
										onChange={(selectedOption) => setDrinkname(selectedOption.value)}
										options={drinkList?.length ?(drinkList
											.filter((drink) => drink.id == liquorType)
											.map((drink) =>
											  drink.drinknames.map((drinkname) => ({
												value: drinkname.id,
												label: drinkname.drink_name,
											  }))
											)
											.flat()):(<></>)} 

									/>	 */}
                  <Select
                    classNamePrefix="form-control"
                    onChange={(selectedOption) => setDrinkname(selectedOption.value)}
                    options={drinkLists}
                  />
                  <div className="d-flex align-items-center  graph-sets mt-2">

                    <p> <b>Note:</b> Don’t see your option here? Submit a request to admin.</p>
                  </div>
                </div>
                <div className="form-group mb-4">
                  <label className="form-label">Container Type<span className="text-danger">*</span></label>
                  <select
                    className="form-control"
                    onChange={(e) => {
                      setContainer(e.target.value);
                      // {
                      //   e.target.value === "6"
                      //     ? setSizeShow(true)
                      //     : setSizeShow(false); setSize(0)
                      // }
                    }}
                  >
                    <option value=''>Select </option>
                    {containerList.length ? (
                      containerList?.filter(item => item.Drink_type_id == liquorType)?.map((container, i) => (
                        <option value={container.id}>
                          {container.container_name}
                        </option>
                      ))
                    ) : (
                      <></>
                    )}
                  </select>
                </div>
                {((sizeList?.filter(item => item.container_id == container && item.Drink_type_id == liquorType)).length) ? (
                  <div className="form-group mb-4">
                    <label
                      className="form-label"
                    >
                      Size
                    </label>
                    <select className="form-control" onChange={(e) => setSize(e.target.value)}>
                      <option value=''>select</option>
                      {sizeList.length ? (
                        sizeList?.filter(item => item.container_id == container && item.Drink_type_id == liquorType).map((size, i) => (
                          <option value={size.id}>{size.size_name}</option>
                        ))
                      ) : (
                        <></>
                      )}
                    </select>
                    {
                      (isFormTouched && !size && container) && <span className="error">Size is required!</span>
                    }
                  </div>
                ) : (
                  <></>
                )}
                <div className="form-group mb-4">
                  <label className="form-label">Discount <span className="text-danger">*</span> </label>
                  <div className="form-group d-flex align-items-center flex-row mb-2">
                    <label className="custom-radio mb-0">
                      Percentage
                      <input
                        type="radio"
                        name="discountType"
                        onChange={(e) => setDiscountType("percentage")}
                      />
                      <span className="checkmark"></span>
                    </label>
                    <label className="custom-radio ms-3 mb-0">
                      Price
                      <input
                        type="radio"
                        name="discountType"
                        onChange={(e) => setDiscountType("price")}
                      />
                      <span className="checkmark"></span>
                    </label>
                    <label className="custom-radio ms-3 mb-0">
                      Amount Off
                      <input
                        type="radio"
                        name="discountType"
                        onChange={(e) => setDiscountType("amount")}
                      />
                      <span className="checkmark"></span>
                    </label>
                  </div>
                  <Select
                    classNamePrefix="form-control"
                    name="discountPrice"
                    onChange={(e) => setDiscountPrice(e.value)}
                    options={discountType == "percentage" ? perPriceoption : Priceoption}
                    value={discountType == "percentage" ? perPriceoption.filter(function (option) {
                      console.log(option.value, 'value')
                      return option.value === discountPrice;
                    }) :
                      Priceoption.filter(function (option) {
                        return option.value === discountPrice;
                      })}
                  />
                </div>
                <div className="form-group mb-4">
                  <label className="form-label">Description (255 characters)<span className="text-danger">*</span></label>
                  <textarea maxLength={255}
                    className="form-control"
                    name="description"
                    onChange={(e) => setDescription(e.target.value)}
                  />
                </div>
                <div className="col-md-12 mb-4">
                  <div className="form-group">
                    <div className="input-right-icon">
                      <label className="form-label">Special Tags</label>
                      <Multiselect
                        placeholder="Select Special Tags"
                        options={drinkData}
                        displayValue="label"
                        onSelect={handleOnchangeSpecials}
                        onRemove={(selectedList, removedItem) => {
                          setSelectedSpecials(selectedList)
                        }}
                      />
                    </div>
                  </div>
                </div>

                <div className="col-md-12">
                  <div className="form-group">
                    <div className="input-right-icon">
                      <label className="form-label">Standard Tags</label>
                      <Multiselect
                        placeholder="Select Cider Tags"
                        options={drink.filter((item => item.value == drinkname))?.length ? drink.filter((item => item.value == drinkname)) : drink}
                        // options={food.filter((item => item.value == foodCat))?.length ? food.filter((item => item.value == foodCat)) : food}
                        onSelect={handleOnchangeStand}
                        disablePreSelectedValues={!!drink.filter((item => item.value == drinkname)).length}
                        displayValue="label"
                        selectedValues={drink.filter((item => item.value == drinkname))}
                        onRemove={(selectedList, removedItem) => {
                          setSelectedStanderd(selectedList)
                        }}
                      />
                    </div>
                    <span className="error">{standaradError}</span>

                  </div>
                  <div className="d-flex align-items-center  graph-sets mt-2">

                    <p> <b>Note:</b> If ‘Multiple Items’ is selected for Name Category, select all tags that apply.</p>
                  </div>
                </div>
              </div>
              <div className="create-from-btn mt-5">
                <button className="btn btn-transparent" onClick={backStep}>
                  Back
                </button>
                <button className="btn btn-dark" onClick={handleSubmit} disabled={isLoading} >
                  {
                    isLoading ? <FontAwesomeIcon icon={faCircleNotch} spin /> : 'Save'

                  }</button>
              </div>
            </div>
          )}
          {Step === 2 && liquorType === "4" && (
            <div className="create-from-steps active">
              <h4>Other</h4>
              <div className="create-form-inner mt-4">
                <div className="form-group mb-4">
                  <label className="form-label">Name <span className="text-danger">*</span></label>

                  {/* <Select
										classNamePrefix="form-control"
									
										onChange={(selectedOption) => setDrinkNameText(selectedOption.value)}
										options={drinkList?.length ?(drinkList
											.filter((drink) => drink.id == liquorType)
											.map((drink) =>
											  drink.drinknames.map((drinkname) => ({
												value: drinkname.id,
												label: drinkname.drink_name,
											  }))
											)
											.flat()):(<></>)} 

									/>	 */}
                  <Select
                    classNamePrefix="form-control"

                    onChange={(selectedOption) => setDrinkname(selectedOption.value)}
                    options={drinkLists}

                  />
                  <span className="error">{drinknameError}</span>
                  {/* <select
                    className="form-control"
                    onChange={(e) => setDrinkname(e.target.value)}
                  >
                    {drinkList.length ? (
                      drinkList
                        .filter((drink) => drink.id == liquorType)
                        .map((drink, i) =>
                          drink.drinknames.map((drinkname, i) => (
                            <option value={drinkname?.id}>
                              {drinkname?.drink_name}
                            </option>
                          ))
                        )
                    ) : (
                      <></>
                    )}
                  </select> */}
                  <div className="d-flex align-items-center  graph-sets mt-2">

                    <p> <b>Note:</b> Don’t see your option here? Submit a request to admin.</p>
                  </div>
                </div>
                <div className="form-group mb-4">
                  <label className="form-label">Discount <span className="text-danger">*</span></label>
                  <div className="form-group d-flex align-items-center flex-row mb-2">
                    <label className="custom-radio mb-0">
                      Percentage
                      <input
                        type="radio"
                        name="discountType"
                        onChange={(e) => setDiscountType("percentage")}
                      />
                      <span className="checkmark"></span>
                    </label>
                    <label className="custom-radio ms-3 mb-0">
                      Price
                      <input
                        type="radio"
                        name="discountType"
                        onChange={(e) => setDiscountType("price")}
                      />
                      <span className="checkmark"></span>
                    </label>
                    <label className="custom-radio ms-3 mb-0">
                      Amount Off
                      <input
                        type="radio"
                        name="discountType"
                        onChange={(e) => setDiscountType("amount")}
                      />
                      <span className="checkmark"></span>
                    </label>
                  </div>
                  <Select
                    classNamePrefix="form-control"
                    name="discountPrice"
                    onChange={(e) => setDiscountPrice(e.value)}
                    options={discountType == "percentage" ? perPriceoption : Priceoption}
                    value={discountType == "percentage" ? perPriceoption.filter(function (option) {
                      console.log(option.value, 'value')
                      return option.value === discountPrice;
                    }) :
                      Priceoption.filter(function (option) {
                        return option.value === discountPrice;
                      })}
                  />
                </div>
                <div className="form-group mb-4">
                  <label className="form-label">Description (255 characters)<span className="text-danger">*</span></label>
                  <textarea maxLength={255}
                    className="form-control"
                    name="description"
                    onChange={(e) => setDescription(e.target.value)}
                  />
                </div>
                <div className="col-md-12 mb-4">
                  <div className="form-group">
                    <div className="input-right-icon">
                      <label className="form-label">Special Tags</label>
                      <Multiselect
                        placeholder="Select Special Tags"
                        options={drinkData}
                        displayValue="label"
                        onSelect={handleOnchangeSpecials}
                        onRemove={(selectedList, removedItem) => {
                          setSelectedSpecials(selectedList)
                        }}
                      />
                    </div>
                  </div>
                </div>

                <div className="col-md-12">
                  <div className="form-group">
                    <div className="input-right-icon">
                      <label className="form-label">Standard Tags</label>
                      <Multiselect
                        placeholder="Select Other Tags"
                        options={drink.filter((item => item.value == drinkname))?.length ? drink.filter((item => item.value == drinkname)) : drink}
                        // options={food.filter((item => item.value == foodCat))?.length ? food.filter((item => item.value == foodCat)) : food}
                        onSelect={handleOnchangeStand}
                        disablePreSelectedValues={!!drink.filter((item => item.value == drinkname)).length}
                        displayValue="label"
                        selectedValues={drink.filter((item => item.value == drinkname))}
                        onRemove={(selectedList, removedItem) => {
                          setSelectedStanderd(selectedList)
                        }}
                      />
                    </div>
                    <span className="error">{standaradError}</span>
                  </div>
                  <div className="d-flex align-items-center  graph-sets mt-2">

                    <p> <b>Note:</b> If ‘Multiple Items’ is selected for Name Category, select all tags that apply.</p>
                  </div>
                </div>
              </div>
              <div className="create-from-btn mt-5">
                <button className="btn btn-transparent" onClick={backStep}>
                  Back
                </button>
                <button className="btn btn-dark" onClick={handleSubmit} disabled={isLoading} >
                  {
                    isLoading ? <FontAwesomeIcon icon={faCircleNotch} spin /> : 'Save'

                  }</button>
              </div>
            </div>
          )}
          {createdSuccess === true ? (
            <>
              <div className="common-message-pop show" id="created-success-pop">
                <div className="common-pop-inner">
                  <div className="common-pop-box created-message-box">
                    <h2>Cheers!</h2>
                    <p className="message-txt">
                      You just created a Game Day Special!!
                    </p>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <></>
          )}
        </div>
      </div>
    </>
  );
};

export default GameDrinkCreate;

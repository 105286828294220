import React, { useEffect, useState } from 'react';
import apiService from '../core/apiService';
import { useForm } from 'react-hook-form';

const DrinkForm = ({
    onBack
}) => {
    const { register, handleSubmit, watch, formState: { errors }, setValue } = useForm();
    const [drinkList, setDrinkList] = useState([]);
    const [selectedDrink, setSelectedDrink] = useState();

    useEffect(() => {
        apiService.get('/restaurants/drinkTypes', {}, ({ err, data }) => {
            if (err) return console.log(err);
            setDrinkList(data.data)
        })
    });

    const setDrinkType = (value) => {
        let drink = drinkList.find(x => x.id === parseInt(value.drinktype_id));
        setSelectedDrink(drink);
        console.log(value, drink)
    };

    const onBackClick = () => {
        onBack()
    }

    const selectDrinkType = !selectedDrink && (
        <form onSubmit={handleSubmit(setDrinkType)}>
            <div className="create-form-inner mt-4">
                <div className="form-group mb-4">
                    <label className="form-label">Type</label>
                    <select {...register('drinktype_id')} defaultValue={""} className="form-control">
                        <option value={""} selected disabled>Please Select</option>
                        {
                            drinkList.map((x, i) => (
                                <option key={i} value={x.id}>{x.drinktype_name}</option>
                            ))
                        }
                    </select>
                </div>
                <div className="create-from-btn mt-5">
                    <button htmlType="button" onClick={onBackClick} className="btn btn-transparent">Back</button>
                    <button htmlType="submit" className="btn btn-dark">save</button>
                </div>
            </div>
        </form>
    );

    const drinkForm = selectedDrink && (
        <form>
            <div className="create-form-inner mt-4">
                {
                    selectedDrink?.drinktype_name === 'Wine' && (
                        <>
                            <div className="form-group mb-4">
                                <label className="form-label">Region</label>
                                <select className="form-control">
                                    <option></option>
                                </select>
                            </div>
                            <div className="form-group mb-4">
                                <label className="form-label">Red/White</label>
                                <select className="form-control">
                                    <option></option>
                                </select>
                            </div>
                            <div className="form-group mb-4">
                                <label className="form-label">Type</label>
                                <select className="form-control">
                                    <option></option>
                                </select>
                            </div>
                        </>
                    )
                }
                {
                    selectedDrink?.drinktype_name !== 'Wine' && (
                        <div className="form-group mb-4">
                            <label className="form-label">Name</label>
                            <select className="form-control">
                                <option></option>
                            </select>
                        </div>
                    )
                }
                <div className="form-group mb-4">
                    <label className="form-label">Container Type</label>
                    <select className="form-control">
                        <option></option>
                    </select>
                </div>
                <div className="form-group mb-4">
                    <label className="form-label">Size</label>
                    <select className="form-control">
                        <option></option>
                    </select>
                </div>
                {
                    selectedDrink?.drinktype_name === 'Wine' && (
                        <div className="form-group mb-4">
                            <label className="form-label">Discount</label>
                            <div className="form-group d-flex align-items-center flex-row mb-2">
                                <label className="custom-radio mb-0">Percentage
                                    <input type="radio" name="price" checked />
                                    <span className="checkmark"></span>
                                </label>
                                <label className="custom-radio ms-3 mb-0">Price
                                    <input type="radio" name="price" />
                                    <span className="checkmark"></span>
                                </label>
                                <label className="custom-radio ms-3 mb-0">Amount Off
                                    <input type="radio" name="price" />
                                    <span className="checkmark"></span>
                                </label>
                            </div>
                            <select className="form-control">
                                <option></option>
                            </select>
                        </div>
                    )
                }
                {
                    selectedDrink?.drinktype_name !== 'Wine' && (
                        <div className="form-group mb-4">
                            <label className="form-label">Price</label>
                            <select className="form-control">
                                <option></option>
                            </select>
                        </div>
                    )
                }
                <div className="form-group mb-4">
                      <label className="form-label">Description(255 characters)</label>
                    <input className="form-control" />
                </div>
            </div>
            <div className="create-from-btn mt-5">
                <button onClick={() => setSelectedDrink(null)} className="btn btn-transparent">Back</button>
                <button className="btn btn-dark">save</button>
            </div>
        </form>
    )

    return (
        <div className="create-from-steps active">
            <h4>{!selectedDrink ? 'Please Select' : `${selectedDrink?.drinktype_name}!`}</h4>
            {selectDrinkType}
            {drinkForm}
        </div>
    )
};

export default DrinkForm;
import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';

const ScrollableListModal = ({componentToPassDown , visiblity}) => {
  const [show, setShow] = useState(false );

  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  return (
    <>
    {visiblity?  <span variant="primary " onClick={handleShow} className='show-more' style={{cursor:'pointer'}}>
     Show more 
      </span>:<></>}  
      <Modal show={show} onHide={handleClose} centered className='searched-items'>
        <Modal.Body>
        <div style={{ maxHeight: '300px', overflowY: 'auto' }}>
        {componentToPassDown}  
        </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ScrollableListModal;

import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router';
import apiService from '../../core/apiService';

import logo from '../../assets/images/logo.png';
import sucessTick from '../../assets/images/sucess-tick.png';
import { useSelector } from 'react-redux';

const Success = () => {
    const restaurant = useSelector(state => state.restaurant);
    const [paymentSuccess, setPaymentSuccess] = React.useState(false);
    const sessionId = new URLSearchParams(window.location.search).get('session_id');
    const navigate = useNavigate();

    useEffect(() => {
        apiService.get(`/stripe/get-session`, { sessionId }, ({ err, data }) => {
            if (err) return console.log(err);
            if (data.success) {
          
                if (data.data.payment_status === 'paid') {
                    apiService.post('/restaurants/subscription/update', {
                        restaurant_id: data.data.metadata.restaurant_id,
                        susbcription_id: data.data.metadata.subscription_id,
                        line_item_id: data.data.line_items[0].id,
                        stripe_subscription_id : data.data.subscription,
                    }, ({ err, datas }) => {
                        if (err) return console.log(err);
                        setPaymentSuccess(true);
                        setTimeout(() => {
                            navigate('/dashboard', {
                                replace: true
                            });
                        }, 2000);
                    })
                } else {
                    alert('Payment failed');
                }
            }
        });
    }, []);



    return (
        paymentSuccess ? (
            <div className="common-message-pop show" id="payment-success-pop" >
                <div className="common-pop-inner">
                    <div className="common-pop-box">
                        <div className="popup-logo">
                            <img alt="" src={logo} />
                        </div>
                        <div className="message-icon">
                            <img alt="" src={sucessTick} />
                        </div>
                        <p className="message-txt">Payment Successful!!</p>
                    </div>
                </div>
            </div >
        ) : <></>

    )
}

export default Success;
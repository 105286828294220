import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import apiService from '../../core/apiService';
import { useSelector } from 'react-redux';
import logo from '../../assets/images/logo.png';
import sucessTick from '../../assets/images/sucess-tick.png';
import { Spinner } from 'react-bootstrap';
import Subscription from '../../components/Subscription';

const UpgradeMembership = () => {
	const navigate = useNavigate();
	const [loading, setLoading] = useState(false);
	const [updateSuccess, setUpdateSuccess] = useState(false);
	const [subscriptionList, setSubscriptionList] = useState([]);
	const restaurant = useSelector(state => state.restaurant);
	const [productId, setProductId] = useState("");
	const [plan, setPlan] = useState('');
	const [subscriptionId, setSubscriptionId] = useState('');
	const [lineItemId, setLinItemId] = useState('');
	const [isDisabled, setDisabled] = useState(false);
	const [customerId, setCustomerId] = useState('');
	const [currentPlan, setCurrentPlan] = useState();

	useEffect(() => {
		const data = {
			'name': restaurant?.restaurant_name,
			'email': restaurant?.restaurant_email,
			'phone': restaurant?.restaurant_phone_number,
			'restaurant_id': restaurant?.id,
		}
		apiService.SubscriptionPost('/stripe/create-customer', data, ({ err, data }) => {
			if (err) return console.log(err);
			setCustomerId(data.data.customer);
		});
		apiService.get(`/subscriptions/get-subscriptions`, {}, ({ err, data }) => {
			if (err) return console.log(err);
			setSubscriptionList(data.data);
			apiService.get(`/restaurants/subscription/getpackage`, { restaurant_id: restaurant?.id }, ({ err, data }) => {
				if (err) return console.log(err);
				setPlan(data.data.subscription.id);
				setCurrentPlan(data.data.subscription);
				setProductId(data.data.subscription.subscription_product_id);
				setSubscriptionId(data.data.stripe_subscription_id);
				setLinItemId(data.data.line_item_id);
				changePlan(data.data.subscription.id, data.data.subscription.subscription_product_id)
			})
		});
	}, []);

	const changePlan = (id, product) => {
		setPlan(id);
		setProductId(product);
		setDisabled(true);
	};

	const updateSubscription = ({ selectedplan }) => {
		let plan_id = selectedplan.id;
		let product_id = selectedplan.subscription_product_id;
		setLoading(true);
		let __subscription = [...subscriptionList];
		__subscription.map((subscription, i) => {
			if (subscription.id === plan_id) {
				subscription.loading = true;
			}
		})
		setSubscriptionList(__subscription);
		if (currentPlan?.id && currentPlan?.subscription_product_id) {
			apiService.post('/stripe/update-subscription', {
				subscriptionId,
				product_id,
				lineItemId,
				plan_id
			}, ({ err, data }) => {
				if (err) return console.log(err);
				console.log(data);
				setLoading(false);
				setUpdateSuccess(true);
				setTimeout(() => {
					navigate('/dashboard')
				}, 2000);
				// apiService.post('/restaurants/subscription/update', {
				// 	restaurant_id: restaurant?.id,
				// 	susbcription_id: plan_id
				// }, ({ err, data }) => {
				// 	if (err) return console.log(err);
				// 	console.log(data)
				// 	setTimeout(() => {
				// 		navigate('/dashboard')
				// 	}, 2000);
				// })
			})

		} else {
			apiService.post('/stripe/create-session', {
				priceId: product_id,
				customerId,
				metaData: {
					restaurant_id: restaurant?.id,
					restaurant_name: restaurant?.restaurant_name,
					restaurant_email: restaurant?.restaurant_email,
					subscription_id: plan_id,
				}
			}, ({ err, data }) => {
				if (err) return console.log(err);
				if (data.success) {
					window.location.href = data.data.url;
				}
			})
		}

	}
	return (
		<>
			<Subscription changePlan={updateSubscription} selectedPlan={currentPlan} msg={2}/>
			{/* <div class="profile-membership-plan" style={{ position: 'relative' }}>
				<div className="row">
					<div className="col-md-2"></div>
					{subscriptionList.length ? (
						subscriptionList.map((subscription, i) => (
							<div className="col-md-4">
								<div className={plan === subscription.id ? "plan-box selected" : "plan-box " + { plan }}>
									<h4>{subscription.subscription_name}</h4>
									<div className="plan-price">
										<span className="price-con">${subscription.subscription_price}</span>
										<span>/month</span>
									</div>
									<ul className="plan-point">
										{subscription.subscriptionfeatures.map((subscriptionf, i) => (
											<li>{subscriptionf.feature.feature_name}</li>
										))
										}
									</ul>
									<button className="btn gray-transparent-btn choose-plan-btn" disabled={(plan === subscription.id) || loading} onClick={() => updateSubscription(subscription.id, subscription.subscription_product_id)}>{
										subscription.loading ? <Spinner /> : "Choose Plan"
									}</button>
								</div>
							</div>
						))
					) : (
						<></>
					)}
				</div>
			</div> */}
			{
				updateSuccess ?
					<div className="common-message-pop show" id="payment-success-pop" >
						<div className="common-pop-inner">
							<div className="common-pop-box">
								<div className="popup-logo">
									<img alt="" src={logo} />
								</div>
								<div className="message-icon">
									<img alt="" src={sucessTick} />
								</div>
								<p className="message-txt">Subscription updated!!</p>
							</div>
						</div>
					</div > : <></>
			}
		</>
	);
};

export default UpgradeMembership;
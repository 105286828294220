import { useEffect, useState } from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "./CheckoutForm";
import { loadStripe } from "@stripe/stripe-js";
import apiService from '../../core/apiService';
import logo from '../../assets/images/logo.png';

function Stripe() {
  const location = useLocation();
  const publishKey = process.env.REACT_APP_PUBLISH_KEY; 
  const stripePromise = loadStripe(`${publishKey}`);
  const clientSecret = useState(`${location.state.clientSecret}`);
  return (
    <div>
      <div id="login-page">
        <div className="login-form">
          <div className="login-form-inner">
            <div className="login-logo">
              <img src={logo} alt="Whatson" />
              <p>Enter Card Details</p>
            </div>
            <div className="login-form-box">
              {stripePromise && (
                <Elements stripe={stripePromise} options={clientSecret.toString}>
                  <CheckoutForm options={{ hidePostalCode: true }} />
                </Elements>
              )}
          </div>
        </div>
      </div>
      <div className="login-img">
        <div className="login-img-inner">
          <div className="dashboard-title">
            <h3>WELCOME TO</h3>
            <h1>Restaurant Panel</h1>
            <p>Register to Access Dashboard</p>
          </div>
        </div>
      </div>
    </div>
        </div >
  );
}

export default Stripe;
import React, { useEffect, useState } from 'react';
import FoodForm from './FoodForm';
import DrinkForm from './DrinkForm';
import apiService from '../core/apiService';
import { useForm, useWatch } from 'react-hook-form';

const SpecialCreate = () => {
    const { register, handleSubmit, watch, formState: { errors }, setValue } = useForm();
    const [types, setTypes] = useState([]);
    const [foodType, setFoodType] = useState();
    const saveFoodType = (value) => {
        console.log(value);
        let selectedFood = types.find(x => x.id === parseInt(value.type_id));
        setFoodType(selectedFood)

    };

    const onBack = () => {
        setFoodType(null);
    }

    useEffect(() => {
        apiService.get('/types', {}, ({ err, data }) => {
            if (err) return console.log(err);
            setTypes(data.data);
        })
    }, []);

    return (
        <div className="dashboard-auto-wrapper">
            {
                !foodType && (
                    <div className="create-from-steps active">
                        <form onSubmit={handleSubmit(saveFoodType)}>
                            <h4>Hey, tell me what is this special for?</h4>
                            <div className="create-form-inner mt-4">
                                <div className="form-group d-flex align-items-center flex-row mb-4">
                                    {
                                        types.map((type, index) => (
                                            <label key={index} className="custom-radio mb-0">{type.type_name}
                                                <input {...register('type_id')} type="radio" value={type.id} />
                                                <span className="checkmark"></span>
                                            </label>
                                        ))
                                    }
                                </div>
                            </div>
                            <div className="create-from-btn">
                                <button onClick={onBack} htmlType="button" className="btn btn-transparent">Back</button>
                                <button htmlType="submit" className="btn btn-dark">Next</button>
                            </div>
                        </form>
                    </div>
                )
            }
            {
                foodType?.type_name === 'Food' && (
                    <FoodForm onBack={onBack} />
                )
            }
            {
                foodType?.type_name === 'Drink' && (
                    <DrinkForm onBack={onBack} />
                )
            }
        </div>
    )
};

export default SpecialCreate;
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircle, faCircleNotch, faMapMarkerAlt, faPlus, faUpload } from "@fortawesome/free-solid-svg-icons";
import { useForm } from 'react-hook-form';
import apiService from '../../core/apiService';
import { useSelector } from 'react-redux';
import logo from '../../assets/images/logo.png';

const BusinessVerify = () => {
	const [loading, setLoading] = useState(false);
	const [fileName, setFileName] = useState('')
	const restaurant = useSelector(state => state.restaurant);
	const { register, watch, handleSubmit, formState: { errors }, setValue } = useForm();
	const navigate = useNavigate();
	const location = useLocation();
	const { search } = useLocation();
	const promocode = new URLSearchParams(search).get('promoCode');
	const fromPage = location.state?.from?.pathname || '/membership';
	const restaurant_document = watch('restaurant_document');
	const [errorMessage, setErrorMessage] = useState('');
	const [imageType, setImageType] = useState(true);
	const maxSize = 512000; // 1MB in bytes
	const submitForm = (values) => {
		setLoading(true);
		if(values.restaurant_document.size < maxSize ){
			apiService.post('/restaurants/upload-doc', values, ({ err, data }) => {
				if (err) return setLoading(false);
				setLoading(false);
			}, { isFormData: true });
			if (promocode) {
				navigate(`${fromPage}?promoCode=${promocode}`, { replace: true })
			} else {
				navigate(fromPage, { replace: true })
			}
		}else{
			setLoading(false)
			setErrorMessage('File size is too large. Please upload a file less than 1MB.');
		}
		
	}
	useEffect(() => {
		setValue('restaurant_document', null)
	}, []);
	const fileUpload = (e) => {
		console.log(e.target.files[0],'e.target.files[0]')
        const {size , type, name} = e.target.files[0]
		if (size > maxSize  || type.startsWith('video') ) {
			setErrorMessage(type.startsWith('video')? 'Video is  Not a Valid Type': 'Image size should be less than 500kb');
			setImageType(false);
			return;

		}else{
		setFileName(name)
		setValue('restaurant_document', e.target.files[0])
	}
    //    return e.target.files[0].size > maxSize
	}

	return (
		<div>
			<div id="register-page">
				<div className="login-form">
					<div className="login-form-inner">
						<div className="login-logo">
							<img src={logo} alt="Whatson" />
							<p>Alternate Verification Process of your Business</p>
						</div>
						<div className="login-form-box">
							<form onSubmit={handleSubmit(submitForm)}>
								<div className="mb-4">
									<label>Restaurant name</label>
									<input disabled value={restaurant?.restaurant_name} type="text" id="username" className="form-control" />
									<input {...register('restaurant_id', { required: 'Restaurant Id is required', value: restaurant?.id })} type="hidden" className="form-control" />
									<input {...register('location.restaurant_id', { required: 'Restaurant Id is required', value: restaurant?.id })} type="hidden" className="form-control" />
								</div>
								<div className="mb-4">
									<label>Address</label>
									<input {...register('location.restaurant_address_line1', { required: 'Restaurant Address is required' })} maxLength={500} type="text" id="address" className="form-control" />
									{errors?.location?.restaurant_address_line1 && <span className="error email-error">{errors?.location?.restaurant_address_line1?.message}</span>}
								</div>
								<div className="mb-4">

									<label>Upload Document</label>
									{
										 (restaurant_document?.size ||0) > 0  ?
											<div className='document_upload' onClick={() => setValue('restaurant_document', null)}>
												<span className="d-inline-block" tabindex="0" data-bs-toggle="popover" data-bs-trigger="hover focus" data-bs-content="remove">
													<button className="btn btn-success" type="button" >{fileName} <span className='fw-bold text-danger mx-1'>x</span></button>
												</span>
											</div>
											:
											<div className="restaurant-photo-box">
												<div className="restaurant-photo-input">
													<FontAwesomeIcon icon={faUpload} />
												</div>
												<input {...register('restaurant_document', { required: true })} onChange={fileUpload} type="file" className="photo-input" accept='image/png, image/jpeg , image/jpg , text/docx ,text/txt,text/csv'/>
												{(!restaurant_document && errors?.restaurant_document) && <span className="error email-error">{'Document is required'}</span>}
												{!imageType && <span className="error password-error">{errorMessage}</span>}

											</div>
									}
								</div>
								<div className="mt-4    graph-set">

									<p>Note: <b> Upload minimum 500 kb than or 500 kb document size </b> </p>
								</div>
								<div className="mb-3">
									<button disabled={loading} className="btn btn-dark full-btn btn-lg signin-btn">
										{
											loading ? <FontAwesomeIcon icon={faCircleNotch} spin /> : 'Next'
										}
									</button>
								</div>
							</form>
						</div>
						<div className="form-bottom-link">If you are  registered with Google My Business Please <Link to="/verify-business"><b> Click here!</b></Link> </div>
					</div>
				</div>
				<div className="login-img">
					<div className="login-img-inner">
						<div className="dashboard-title">
							<h3>WELCOME TO</h3>
							<h1>Restaurant Panel</h1>
							<p>Register to Access Dashboard</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default BusinessVerify;

import React, { useEffect, useState } from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import apiService from '../../core/apiService';
import { useSelector } from 'react-redux';
import logo from '../../assets/images/logo.png';
import sucessTick from '../../assets/images/sucess-tick.png';
import { Spinner } from 'react-bootstrap';
import Subscription from '../../components/Subscription';
import { toast } from 'react-toastify';
import CustomError from '../../components/CustomError';

const Membership = () => {
	const navigate = useNavigate();
	const [loading, setLoading] = useState(false);
	const {search} = useLocation();
	const promocode = new URLSearchParams(search).get('promoCode');
	const error = new URLSearchParams(search).get('error');
	const sessionId = new URLSearchParams(search).get('session_id');
	const restaurant = useSelector(state => state.restaurant);
	const location = useLocation();
	const subscribe = useSelector(state => state.subscribe);
	const fromPage = location.state?.from?.pathname || '/dashboard';
	const [isPayment, setisPayment] = useState(false);
	const [isCheckout, setisCheckout] = useState(false);
	const [plan, setPlan] = useState('');
	const [isDisabled, setDisabled] = useState(false);
	const [customerId, setCustomerID] = useState("");
	const [productId, setProductId] = useState("");
	const [subscriptionList, setSubscriptionList] = useState([]);

	const handleSubmit = ({ selectedplan }) => {
		setLoading(true);
		console.log(customerId.length,"customerId.length")
		if(customerId.length<=0)
		{
			try {
				const data = {
					'name': restaurant?.restaurant_name,
					'email': restaurant?.restaurant_email,
					'phone': restaurant?.restaurant_phone_number,
					'restaurant_id': restaurant?.id,
				}
				
			     apiService.SubscriptionPost('/stripe/create-customer', data, ({ err, data }) => {
					if (err) return console.log("Error: " + err);
					setCustomerID(data.data.customer);
                    // customerId = data.data.customer;
					console.log(data.data.customer,"custom")
					
					
					apiService.post('/stripe/create-session', {
						priceId: selectedplan?.subscription_product_id,
						customerId:data.data.customer,
						promocode,
						metaData: {
							restaurant_id: restaurant?.id,
							restaurant_name: restaurant?.restaurant_name,
							restaurant_email: restaurant?.restaurant_email,
							subscription_id: selectedplan?.id,
						}
					}, ({ err, data }) => {
						if (err) return console.log(err,'err1');
						if (data.success) {
							window.location.href = data.data.url;
						}
					})
				});
			} catch (e) {		 
			}
		}
		else
		{
			apiService.SubscriptionPosts('/stripe/create-session', {
				priceId: selectedplan?.subscription_product_id,
				customerId,
				promocode,
				metaData: {
					restaurant_id: restaurant?.id,
					restaurant_name: restaurant?.restaurant_name,
					restaurant_email: restaurant?.restaurant_email,
					subscription_id: selectedplan?.id,
				}
			}, ({ err, data }) => {
				if (err) return console.log(err,'err2');
				if (data.success) {
					window.location.href = data.data.url;
				}
			})
		}
		   
		
	
		// apiService.post('/restaurants/subscription/update', {
		// 	restaurant_id: restaurant?.id,
		// 	susbcription_id: plan,
		// }, ({ err, datas }) => {
		// 	if (err) return console.log(err);
		// 	if (customerId) {
		// 		apiService.SubscriptionPost('/stripe/create-subscription', { subscription_customer_id: customerId, priceId: productId },
		// 			({ err, data }) => {
		// 				if (err) {
		// 					if (err.success === false || plan === '1') {
		// 						setisPayment(isPayment === false ? true : false);
		// 						setTimeout(() => {
		// 							navigate(fromPage, { replace: true })
		// 						}, 2000);
		// 					}
		// 				}

		// 				setTimeout(() => {
		// 					navigate("/checkout", {
		// 						state: {
		// 							subscriptionId: data.data.subscriptionId,
		// 							clientSecret: data.data.clientSecret
		// 						}
		// 					})
		// 				}, 2000);

		// 			});
		// 	}
		// })
	}

	useEffect(() => {
		if(subscribe?.subscription) return;
		if(error){
			CustomError('Payment Declined!! Please try again','')
			// toast.error('Payment Declined!! Please try again', {
			// 	position: "top-center",
			// 	autoClose: 5500,
			// 	hideProgressBar: false,
			// 	closeOnClick: true,
			// 	pauseOnHover: false,
			// 	theme: "light",
			// });
		}
		const data = {
			'name': restaurant?.restaurant_name,
			'email': restaurant?.restaurant_email,
			'phone': restaurant?.restaurant_phone_number,
			'restaurant_id': restaurant?.id,
		}
		console.log(data);
		apiService.SubscriptionPost('/stripe/create-customer', data, ({ err, data }) => {
			if (err) return console.log("Error: " + err);
			console.log("Custmoer:" + data.data.customer);
			setCustomerID(data.data.customer);
		});
		
	}, []);

	const SetStripeCustomer = async () => {
		try {
			const data = {
				'name': restaurant?.restaurant_name,
				'email': restaurant?.restaurant_email,
				'phone': restaurant?.restaurant_phone_number,
				'restaurant_id': restaurant?.id,
			}
			
		await apiService.SubscriptionPost('/stripe/create-customer', data, ({ err, data }) => {
				if (err) return console.log("Error: " + err);				
				setCustomerID(data.data.customer);
			});
		} catch (e) {		 
		}
	  };


	const changePlan = (id, product) => {
		setPlan(id);
		setProductId(product);
		setDisabled(true);
	};

	if(subscribe?.subscription) return (
		<Navigate to="/dashboard" replace={true} />
	)

	return (
		<div style={{ position: 'relative' }}>
			<div id="verification-page">
				<div className="container">
					<div className="verification-logo">
						<img alt="" src={logo} />
						<p>Select Your Membership</p>
					</div>
					<Subscription changePlan={handleSubmit} />
					{/* <div className="row">
						<div className="col-md-2"></div>
						{subscriptionList.length ? (
							subscriptionList.map((subscription, i) => (
								<div className="col-md-4">
									<div className={plan === subscription.id ? "plan-box selected" : "plan-box"}>
										<h4>{subscription.subscription_name}</h4>
										<div className="plan-price">
											<span className="price-con">${subscription.subscription_price}</span>
											<span>/month</span>
										</div>
										<ul className="plan-point">
											{subscription.subscriptionfeatures.map((subscriptionf, i) => (
												<li>{subscriptionf.feature.feature_name}</li>
											))
											}
										</ul>
										<button className="btn gray-transparent-btn choose-plan-btn" onClick={() => changePlan(subscription.id, subscription.subscription_product_id)}>Choose Plan</button>
									</div>
								</div>
							))
						) : (
							<></>
						)}
					</div>
					<div className="continue-btn">
						<button onClick={handleSubmit} className="btn btn-dark payment-btn" disabled={!isDisabled || loading}>{
							loading ? <Spinner /> : 'Continue to Pay'
						}</button>
					</div> */}
				</div>
			</div>
			{isPayment === true ?
				<>
					<div className="common-message-pop show" id="payment-success-pop">
						<div className="common-pop-inner">
							<div className="common-pop-box">
								<div className="popup-logo">
									<img alt="" src={logo} />
								</div>
								<div className="message-icon">
									<img alt="" src={sucessTick} />
								</div>
								<p className="message-txt">Payment Successful!!</p>
							</div>
						</div>
					</div>
				</> : <></>
			}
		</div>
	);
};

export default Membership;

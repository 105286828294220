import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPencilAlt, faTimes, faCalendarAlt, faSearch, faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import Modal from 'react-bootstrap/Modal';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import apiService, { REACT_APP_LIMIT } from '../../core/apiService';
import moment from 'moment/moment';
import Filter from '../../assets/images/filter-ico.svg';
import CustomSuccess from '../../components/CustomSuccess';

const DailySpecial = () => {
	const { register, handleSubmit, watch, reset, formState: { errors }, setValue } = useForm();
	const [modal, setModal] = useState({
		type: '',
		data: null,
		title: ''
	});
	const restaurant = useSelector(state => state.restaurant);
	const [isFilter, setisFilter] = useState(false);
	const [messagesList, setMessagesList] = useState([]);
	const [appliedFilters, setAppliedFilters] = useState({
		search: '',
		start_date: '',
		end_date: ''
	});
	const [dataLoading, setDataLoading] = useState(false);
	const [currentPage, setCurrentPage] = useState(1)

	const [total, setTotal] = useState([])
	const [imageType, setImageType] = useState(true);
	const [errorMessage, setErrorMessage] = useState('');
	const [noListError, setNoListError] = useState('');
	const [isLoading, setIsLoading] = useState(false);
	const showFilter = () => {
		setisFilter(isFilter === false ? true : false);
		if (isFilter === true) return;

		setCurrentPage(1)
	};
	const [show, setShow] = useState(false);

	const onImageUpload = (e) => {
		const maxSize = 102400;
		if (e.target.files[0].size > maxSize) {
			setErrorMessage('Image size should be less than 100kb');
			setImageType(false);
			setValue('image', null)
			return;

		} else
		{
			setImageType(true);
			setErrorMessage('');
		setValue('image', e.target.files[0])
	}
	};

	const getData = (args) => {
		setDataLoading(true);
		let params = {
			restaurant_id: restaurant?.id,
			pageno: currentPage, search: appliedFilters.search,
			start_date: appliedFilters.start_date,
			end_date: appliedFilters.end_date
		};

		if (args?.filters) {
			params = { ...params, ...args?.filters };
		}
		apiService.get(`/restaurantCommunications`, params, ({ err, data }) => {
			if (err) {
				setNoListError(err.error);
				console.log(err);
				setisFilter(false);
				setDataLoading(false);
				setMessagesList([]);
				return;
			};
			setMessagesList(data.data.rows);
			setTotal(data.data.count)
			setDataLoading(false);
			setisFilter(false);
		})
	};

	const resetFilters = () => {
		setAppliedFilters({
			search: '',
			start_date: '',
			end_date: ''
		});
	};

	useEffect(() => {
		setDataLoading(true);
		getData()
	}, [currentPage]);
	const updateData = (page) => {
		setCurrentPage(page)
	}

	let lastPage = Math.ceil(total / REACT_APP_LIMIT)
	const nextPage = () => {
		if (currentPage < lastPage) {
			updateData(currentPage + 1)
		}
	}

	const previousPage = () => {
		updateData(currentPage - 1)
	}

	const handleClose = () => {
		setShow(false);
		setErrorMessage('');
		reset();
		setModal({
			type: '',
			data: null,
			title: ''
		});
	};
	const handleShow = (args) => {
		console.log(args, "argsargs")
		setModal({
			data: args?.data || '',
			type: args?.type || '',
			title: args?.title || ''
		});
		if (args?.type === 'update') {

			setValue('title', args?.data?.title);
			setValue('message', args?.data?.message);

			//setValue('image', args?.data?.image);

		}
		setShow(true);
	};

	const sendMessage = (values) => {
		if(!errorMessage.length){
			console.log(values); let api = '/communication/create';
			if (modal?.type === 'update') {
	
				api = `/communication/update?id=${modal?.data?.id}`;
			};
	
			setIsLoading(true)
			apiService.post(api, values, ({ err, data }) => {
				console.log(err, "values")
				if (err) return setIsLoading(false)
				setIsLoading(false);
				setValue('title', values.title);
				setValue('message', values.message);
				CustomSuccess('Saved successfully', 'Success')
				if (modal?.type === 'update') {
					let index = messagesList?.findIndex(message => message.id === modal?.data?.id);
					let updatedMessagesList = [...messagesList];
					updatedMessagesList[index] = data.data[0];
					setMessagesList(updatedMessagesList);
					CustomSuccess('Updated successfully', 'Success')
					setIsLoading(false)
				} else {
					handleClose();
					setMessagesList([data.data, ...messagesList])
					setIsLoading(false)
				}
				setIsLoading(false)
	
				handleClose();
				getData()
			}, { isFormData: true });

		}
	
	};

	const communicationForm = modal?.type !== 'delete' && (
		<div id="add-user-form">
			<form onSubmit={handleSubmit(sendMessage)}>
				<div className="custom-pop-body">
					<h4 className="pop-title text-center">{modal.title || ''}</h4>
					<div className="pop-form">
						<div className="mb-3">
							<label>Title</label>
							<input maxLength={225}{...register('title', { required: 'This field is required' })} type="text" id="name" className="form-control" />
							{errors?.title && <span className="error name-error">{errors?.title?.message}</span>}
						</div>
						<div className="mb-5">
							<label>Message(255 characters)</label>
							<textarea maxLength={255} {...register('message', { required: 'This field is required' })} id="email" className="form-control"></textarea>
							<div className="msgarea-msg"></div>
							{errors?.message && <span className="error name-error">{errors?.message?.message}</span>}
						</div>
						<div className="mb-3">
							<label>Image</label>
							<input onChange={onImageUpload} type="file" className="form-control" accept='image/png, image/gif, image/jpeg, image/jpg' />
							{!imageType && <span className="error password-error">{errorMessage}</span>}

						</div>

						<div className="mt-4    graph-set">

							<p>Note: <b>Upload less than 100 kb or 100 kb image size </b> </p>
						</div>

						<input {...register('restaurant_id', { required: 'This field is required', value: restaurant?.id })} type="hidden" className="form-control" />
					</div>
				</div>
				<div className="custom-pop-footer">
					<div className="text-center">

						<button type="submit" className="btn btn-dark btn-long" disabled={isLoading} >
							{
								isLoading && imageType ? <FontAwesomeIcon icon={faCircleNotch} spin /> : modal.type

							}</button>
					</div>
				</div>
			</form>
		</div>
	);

	const deleteNotification = () => {
		apiService.remove(`/communication/delete?id=${modal?.data?.id}`, ({ err, data }) => {
			if (err) return console.log(err);
			setMessagesList(messagesList.filter(message => message.id !== modal?.data?.id));
			handleClose();
		})
	}

	const deleteCommunicationForm = modal?.type === 'delete' && (
		<div className="custom-pop-body">
			<h4 className="pop-title text-center">{modal.title || ''}</h4>
			<div>
				<p className='text-center'>Are you sure you want to delete this notification?</p>
				<div className="custom-pop-footer">
					<div className="text-center">
						<button onClick={deleteNotification} type="submit" className="btn btn-danger btn-long">Delete</button>
					</div>
				</div>
			</div>
		</div>
	)

	return (
		<>
			<div className="dashboard-top-btn">
				<div className="table-filter">
					<button onClick={showFilter} className="btn btn-default filter-btn"><img alt="" src={Filter} />Filters</button>
				</div>
				<button className="btn btn-dark" onClick={() => handleShow({
					type: 'create',
					title: 'Send a message directly to your followers'
				})}  ><FontAwesomeIcon icon={faPencilAlt} /> Create</button>
			</div>
			<div className="table-sec common-table margin-t30">
				<div className="table-responsive">
					<table className="table white-td-table">
						<thead>
							<tr>
								<th>Title</th>
								<th>Message</th>
								<th>Date & Time</th>
								<th>Image</th>
								<th>Action</th>
							</tr>
						</thead>
						<tbody className={messagesList.length > 0 ? '' : 'empty_row'}>
							{
								dataLoading ?
									<tr>
										<td colSpan={5}>
											<div className='table-inner-loader'>
												<FontAwesomeIcon style={{ fontSize: 25 }} icon={faCircleNotch} spin />
											</div>
										</td>
									</tr> :
									messagesList?.length ? messagesList?.map((message, index) => (
										<tr key={index}>
											<td>{message?.title}</td>
											<td>{message?.message}</td>
											<td>{moment(message?.created_at).format('YYYY-MM-DD, hh:mm')}</td>
											<td>
												{
													message?.image ?
														<img height={"100"} width={"100"} src={`${process.env.REACT_APP_URL}/public/${message.image}`} alt="" className="img-thumbnail" />
														: ''
												}
											</td>
											<td className='btn-aside table-action'>
												<button className="btn btn-success" onClick={() => handleShow({
													type: 'update',
													data: message,
													title: 'Send a message directly to your followers'
												})}>Update</button>
												<button className="btn btn-danger" onClick={() => handleShow({
													type: 'delete',
													data: message,
													title: 'Delete Notification	'
												})}>Delete</button>
											</td>
										</tr>
									)) :
										<tr>
											<td colspan="10">
												No records found!
											</td>
										</tr>
							}
						</tbody>
					</table>
				</div>
			</div>
			{isFilter === true ?
				<>
					<div className="popup-overlay show"></div>
					<div id="filter-pop" className='show'>
						<div className="filter-pop-inner">
							<div className="filter-head">
								<div className='filter-title'>
									<h4>Filters</h4>
									<button className='btn btn-link' onClick={() => {
										resetFilters();
										getData()
									}}>All Records</button>
								</div>
								<button className="times-btn filter-close" onClick={showFilter}><FontAwesomeIcon icon={faTimes} /></button>
							</div>
							<div className="filter-body">
								<div className="mb-4">
									<div className="input-icon">
										<span className='input-icon-alt'><FontAwesomeIcon icon={faSearch} /></span>
										<input
											value={appliedFilters.search}
											onChange={(e) => setAppliedFilters({ ...appliedFilters, search: e.target.value })}
											type="text"
											className="form-control"
											placeholder="Type here..."
										/>
									</div>
								</div>
								<div className="row mb-4">
									<div className="col-md-6">
										<div className="input-icon">
											<label className="form-label">Start Date</label>
											{/* <span className='input-icon-alt'><FontAwesomeIcon icon={faCalendarAlt} /></span> */}
											<input type="date" value={appliedFilters.start_date}
												onChange={(e) => setAppliedFilters({ ...appliedFilters, start_date: e.target.value })}
												className="form-control" placeholder="Start Date" />
										</div>
									</div>
									<div className="col-md-6">
										<div className="input-icon">
											<label className="form-label">End Date</label>

											{/* <span className='input-icon-alt'><FontAwesomeIcon icon={faCalendarAlt} /></span> */}
											<input
												onChange={(e) => setAppliedFilters({ ...appliedFilters, end_date: e.target.value })}
												value={appliedFilters.end_date} type="date" className="form-control" placeholder="End Date" />
										</div>
									</div>
								</div>
							</div>
							<div className="filter-form-btns">
								<button className="btn btn-dark full-width-btn add-couple" onClick={() => getData({
									filters: appliedFilters
								})} disabled={dataLoading}>
									{
										dataLoading ? <FontAwesomeIcon icon={faCircleNotch} spin /> : 'Apply Filter'

									}</button>
							</div>
						</div>
					</div>
				</> : <>
					<Modal show={show} className="common-pop popup-medium" onHide={handleClose}>
						<button type="button" className="btn-close" onClick={handleClose}  ></button>
						<div className="custom-pop-inner">
							<div className="custom-pop-box">
								{communicationForm}
								{deleteCommunicationForm}
							</div>
						</div>
					</Modal>
					{
						lastPage > 1 ?
							<>

								{<ul className="pagination justify-content-center">
									<li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
										<button className="page-link" onClick={previousPage}>
											Previous
										</button>
									</li>
									{Array.from({ length: lastPage }).map((_, index) => (
										<li key={index} className={`page-item ${currentPage === index + 1 ? 'active' : ''}`}>
											<button className="page-link" onClick={() => updateData(index + 1)}>
												{index + 1}
											</button>
										</li>
									))}
									<li className={`page-item ${currentPage === lastPage ? 'disabled' : ''}`}>
										<button className="page-link" onClick={nextPage}>
											Next
										</button>
									</li>
								</ul>
								}
							</> : <></>
					}

				</>
			}
		</>
	);
};

export default DailySpecial;
import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPencilAlt, faTimes, faCalendarAlt, faSearch, faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import { Link, useParams } from 'react-router-dom';
import SpecialLists from '../../components/SpecialsList';
import apiService from '../../core/apiService';
import Filter from '../../assets/images/filter-ico.svg';
import DrinkSpecialLists from '../../components/DrinkSpecialList';

const Specials = () => {
	const [dataLoading, setDataLoading] = useState(true);
	const { type, special } = useParams();
	const [urlParams, setUrlParams] = useState({
		type_id: '',
		special_id: ''
	});
	const [isFilter, setisFilter] = useState(false);
	const showFilter = () => {
		setisFilter(isFilter === false ? true : false);
	};

	const specials = {
		'on-tap': 'On Tap',
		'happy-hour': 'Happy Hours',
		'game-day-special': 'Game day Specials',
		'daily-special': 'Daily Specials'
	}

	const gameSpecialColumns = [
		{ name: 'Sport', dataIndex: 'sport?.sports_name' },
		{ name: 'Team', dataIndex: 'sportsteam?.team_name' },
		{ name: 'Food', dataIndex: 'type?.type_name' },
		{ name: 'Price', dataIndex: 'discount_amount' },
		{ name: 'Item Description', dataIndex: 'item_description' },
		{ name: 'Action', dataIndex: 'action' },
	];

	const dailySpecialColumns = [
		{ name: 'Day', dataIndex: 'day?.day_name' },
		{ name: 'Food Category', dataIndex: 'foodcategory?.foodcategory_name' },
		{ name: 'Price', dataIndex: 'discount_amount' },
		{ name: 'Item Description', dataIndex: 'item_description' },
		{ name: 'Action', dataIndex: 'action' },
	];
	const happyhoursColumns = [
		{ name: 'Day', dataIndex: 'day?.day_name' },
		{ name: 'Start Time', dataIndex: 'restaurantspecialshour?.start_time' },
		{ name: 'End Time', dataIndex: 'restaurantspecialshour?.end_time' },
		{ name: 'Food Category', dataIndex: 'foodcategory?.foodcategory_name' },
		{ name: 'Price', dataIndex: 'discount_amount' },
		{ name: 'Item Description', dataIndex: 'item_description' },
		{ name: 'Action', dataIndex: 'action' },];


	const renderColumns = () => {
		if (special === 'happy-hour') {
			return happyhoursColumns;
		};
		if (special === 'daily-special') {
			return dailySpecialColumns;
		};
		if (special === 'game-day-special') {
			return gameSpecialColumns;
		};
	}
	useEffect(() => {
		setDataLoading(true);
		apiService.get(`/restaurants/getTypeSpecialId`, { special_name: specials[special], type_name: type }, ({ err, data }) => {
			if (err) return console.log(err);
			
			setUrlParams(data.data);
			setDataLoading(false);
		})
	}, [type, special]);

	const renderListing =  (
		type === 'food' ? <SpecialLists columns={renderColumns()} urlParams={urlParams} /> :
		<DrinkSpecialLists urlParams={urlParams} selectedSpecial={special} />
	)

	return (
		<>
			<div className="dashboard-top-btn">
				<div className="table-filter">
					<button onClick={showFilter} className="btn btn-default filter-btn"><img alt="" src={Filter} />Filters</button>
				</div>
				<Link to="/create-special/game-special"><button className="btn btn-dark"><FontAwesomeIcon icon={faPencilAlt} /> Create</button></Link>
			</div>
			{
				(!dataLoading) ?
					renderListing :
					<div className='table-outer-loader'>
						<FontAwesomeIcon style={{fontSize: 25}} icon={faCircleNotch} spin />
					</div>
			}
			{isFilter === true ?
				<>
					<div className="popup-overlay show"></div>
					<div id="filter-pop" className='show'>
						<div className="filter-pop-inner">
							<div className="filter-head">
								<h4>Filters</h4>
								<button className="times-btn filter-close" onClick={showFilter}><FontAwesomeIcon icon={faTimes} /></button>
							</div>
							<div className="filter-body">
								<div className="mb-4">
									<div className="input-icon">
										<span className='input-icon-alt'><FontAwesomeIcon icon={faSearch} /></span>
										<input type="text" className="form-control" placeholder="Type here..." />
									</div>
								</div>
								<div className="row mb-4">
									<div className="col-md-6">
										<div className="input-icon">
											<span className='input-icon-alt'><FontAwesomeIcon icon={faCalendarAlt} /></span>
											<input type="text" className="form-control" placeholder="Start Date" />
										</div>
									</div>
									<div className="col-md-6">
										<div className="input-icon">
											<span className='input-icon-alt'><FontAwesomeIcon icon={faCalendarAlt} /></span>
											<input type="text" className="form-control" placeholder="End Date" />
										</div>
									</div>
								</div>
							</div>
							<div className="filter-form-btns">
								<button className="btn btn-dark full-width-btn add-couple" onClick={showFilter}disabled={dataLoading}> 
								{
								  dataLoading ? <FontAwesomeIcon icon={faCircleNotch} spin /> : 'Apply Filter'
			  
								}</button>
							</div>
						</div>
					</div>
				</> : <></>
			}
		</>
	);
};

export default Specials;
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import apiService from "../../core/apiService";
import { useSelector } from "react-redux";
import Select from 'react-select';
import {
  dropDownOption, perdropDownOption
} from "../../helpers";
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Multiselect from "multiselect-react-dropdown";



const DailyDrinkUpdate = () => {
  // const query = useQuery()
  const restaurant = useSelector((state) => state.restaurant);
  const [Step, setStep] = useState(0);
  const [createdSuccess, setcreatedSuccess] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const [isFormTouched, setIsFormTouched] = useState(false);
  const fromPage = location.state?.from?.pathname || "/daily-special/drink";
  console.log(fromPage, "oooo")

  const createPage = location.state?.from?.pathname || "/daily-special/drink";
  const [liquorType, setliquorType] = useState("1");
  const [dayList, setDayList] = useState([]);
  const [drinkList, setDrinkList] = useState([]);
  const [containerList, setContainerList] = useState([]);
  const [sizeList, setSizeList] = useState([]);
  const [regionList, setRegionList] = useState([]);
  const [wineTypeList, setwineTypeList] = useState([]);
  const [wineSubTypeList, setwineSubTypeList] = useState([]);
  const [days, setDay] = useState("");
  const [dayError, setDayError] = useState("");
  const [discountType, setDiscountType] = useState("");
  const [discountPrice, setDiscountPrice] = useState("");
  const [description, setDescription] = useState("");
  const [region, setRegion] = useState("1");
  const [winetype, setWineType] = useState("0");
  const [winesubtype, setWineSubType] = useState(0);
  const [container, setContainer] = useState("1");
  const [size, setSize] = useState(0);
  const [drinkname, setDrinkname] = useState("-1");
  const [sizeShow, setSizeShow] = useState(false);
  const [typeShow, setTypeShow] = useState(false);
  const [drinkNameText, setDrinkNameText] = useState('');
  const [drinknameError, setDrinkNameError] = useState("");
  const [standaradError, setstandandError] = useState("");
  const [winename, setWine] = useState("1")
  const [wineTypes, setMasterType] = useState([])
  const [isLoading, setIsLoading] = useState(false);
  const { id } = useParams();
  const [drinkData, setDrinkData] = useState([])
  const [drinkLists, setDrinkLists] = useState([]);
  const [drink, setDrink] = useState([])
  const [selectedTag, setselectedTag] = useState([])
  const [tags, setTags] = useState([]);
  const [standared, setSelectedStanderd] = useState([])
  const [tagid, setResTagid] = useState("[]")
  const [resTag, setResTags] = useState("[]")
  const [resWinetype, setResWineType] = useState("[]")
  const [resWineName, setResWineName] = useState("[]")
  const [wineError, setWineandError] = useState("");
  const [winenames, setWinetypes] = useState([])
  const [selectedtype, setSelectedWineType] = useState([])
  const [selectedWineName, setSelectedWineName] = useState([])
  const [winenamess, setWinename] = useState([])
  // const [drinknameError, setDrinkNameError] = useState("");

  const special_id = "1";
  const type_id = "2";
  const restaurantspecial_id = id;
  const perPriceoption = perdropDownOption()

  const Priceoption = dropDownOption();



  console.log(drinkname, 'drinkname')
  const nextStep = () => {
    setStep(Step + 1);
  };
  const backStep = () => {
    setStep(Step - 1);
  };

  const changeliquorType = (e) => {
    setliquorType(e.target.value);
  };

  const back = () => {
    navigate(createPage, { replace: true });
  };


  useEffect(() => {

    apiService.get(
      `/restaurants/getspecial`,
      { id: restaurantspecial_id },
      ({ err, data }) => {

        if (err) return console.log(err);
        setDay(data.data.day_id);
        setliquorType(data.data.drinktype_id);
        setDiscountType(data.data.discount_type);
        setDiscountPrice(data.data.discount_amount);
        setWine(data.data.masterwine_id)
        setDescription(data.data.item_description);
        setRegion(data.data.region_id);
        setWineType(data.data.wine_type_id);
        setWineSubType(data.data.wine_subtype_id);
        setContainer(data.data.container_type_id);
        setDrinkname(data.data.drink_name_id);
        setSize(data.data.size_id);
        setDrinkNameText(data.data.drink_name);
        setResTagid(data.data.restauranttag.tags_ids)
        setResTags(data.data.restauranttag.drink_ids)
        setResWineType(data.data.restauranttag.wine_type_ids)
        setResWineName(data.data.restauranttag.wine_ids)

      }
    );


    apiService.get(`/restaurants/get-days`, {}, ({ err, data }) => {
      if (err) return console.log(err);
      setDayList(data.data);
    });
    apiService.get(`/restaurants/drinkTypes`, {}, ({ err, data }) => {
      if (err) return console.log(err);
      setDrinkList(data.data);
    });
    apiService.get(`/restaurants/get-container`, {}, ({ err, data }) => {
      if (err) return console.log(err);
      setContainerList(data.data);
    });
    apiService.get(`/restaurants/get-size`, {}, ({ err, data }) => {
      if (err) return console.log(err);
      setSizeList(data.data);
    });
    apiService.get(`/restaurants/get-region`, {}, ({ err, data }) => {
      if (err) return console.log(err);
      setRegionList(data.data);
    });
    apiService.get(`/restaurants/get-winetype`, {}, ({ err, data }) => {
      if (err) return console.log(err);
      let res = [];
      (data.data)?.map((item) => {
        const temp = {};
        temp["label"] = `${item["wine_name"]}`;
        temp["value"] = item["id"];
        temp['masterwine_id'] = item['masterwine_id']
        res.push(temp);
      });

      // if (res.length !== 0) {
      //   res.unshift({ value: 0, label: "Multiple Wine Types" })
      // }
      // setDrink(res) 

      setwineTypeList(res);
      let res1 = [];
      data.data?.map((item) => {

        const temp = {};
        temp["label"] = `${item["wine_name"]}`;
        temp["value"] = item["id"];

        res1.push(temp);

      });

      setWinetypes(res1)
      // setwineTypeList(data.data);
    });

    apiService.get(`/restaurants/get-winesubtype`, {}, ({ err, data }) => {
      if (err) return console.log(err);
      let temp = data.data?.map((winetype) => ({
        value: winetype.id,
        label: winetype.winesubtype_name,
      }))
      setwineSubTypeList([{ value: 0, label: "Multiple Wine Names" }, ...temp]);
      // setwineSubTypeList(data.data);
      let res2 = [];
      data.data?.map((item) => {
        const temp = {};
        temp["label"] = `${item["winesubtype_name"]}`;
        temp["value"] = item["id"];
        res2.push(temp);
      });
      console.log(res2, 'res2')
      setWinename(res2)
    });
    apiService.get(`/listMasterWine`, {}, ({ err, data }) => {
      if (err) return console.log(err);

      setMasterType(data.data);
    });


  }, []);

  useEffect(() => {

    if (drinkname > 0) {
      setSelectedStanderd([])
    }
    if (winesubtype >= 0) {
      setSelectedWineName([])
    }


  }, [drinkname, winesubtype])


  useEffect(() => {
    // setWineType('0')
    if (winetype >= 0) {
      setSelectedWineType([])
    }
    setSelectedWineType([])
  }, [winename, winetype])
  const handleSubmit = () => {
    let sizing = sizeList?.filter(item => item.container_id == container && item.Drink_type_id == liquorType)
    setIsFormTouched(true);
    if (!description) return;
    if (!size && sizing.length && parseInt(liquorType) !== 4) return;
    if (liquorType == '1' && winetype == -1) return

    if (liquorType !== '1' && drinkname == 0 && standared.length < 1) return setstandandError('Standrad Tag is required!');
    if (liquorType == '1' && winetype == 0 && selectedtype.length == 0) return setstandandError('Wine Type Tag  is required!');
    if (liquorType == '1' && winesubtype == 0 && selectedWineName.length == 0) return setWineandError('Wine Name  Tag  is required!');
    let drink_ids = {}
    if (standared.length == 0) {
      drink_ids = { "drink_ids": JSON.stringify([drinkname]), tags_ids: JSON.stringify([...selectedTag.map(item => item.value)]) }
    } else
      drink_ids = { "drink_ids": JSON.stringify([...standared.map(item => item.value)]), tags_ids: JSON.stringify([...selectedTag.map(item => item.value)]) }
    console.log(drink_ids, "chekci")

    let winestag = {}
    if (selectedWineName.length == 0 && selectedtype.length == 0) {
      winestag = { "wine_ids": JSON.stringify([winesubtype]), "wine_type_ids": JSON.stringify([Number(winetype)]), tags_ids: JSON.stringify([...selectedTag.map(item => item.value)]) }
    }
    if (selectedWineName.length > 0 && selectedtype.length == 0) {
      winestag = { "wine_ids": JSON.stringify([...selectedWineName.map(item => item.value)]), "wine_type_ids": JSON.stringify([Number(winetype)]), tags_ids: JSON.stringify([...selectedTag.map(item => item.value)]) }
    }
    if (selectedWineName.length == 0 && selectedtype.length > 0) {
      winestag = { "wine_ids": JSON.stringify([winesubtype]), "wine_type_ids": JSON.stringify([...selectedtype.map(item => item.value)]), tags_ids: JSON.stringify([...selectedTag.map(item => item.value)]) }
    }
    if (selectedWineName.length > 0 && selectedtype.length > 0) {
      winestag = { "wine_ids": JSON.stringify([...selectedWineName.map(item => item.value)]), "wine_type_ids": JSON.stringify([...selectedtype.map(item => item.value)]), tags_ids: JSON.stringify([...selectedTag.map(item => item.value)]) }

    }
    let data = {};
    if (liquorType === 4 && drinkname < 0) return setDrinkNameError('Drink Name is required!');
    // if (discountType === '') {
    // 	return;
    // }
    // if (discountPrice === '') {
    // 	return;
    // }
    // if (description === '') {
    // 	return;
    // }
    let temp = null
    if (!sizing.length) {
      console.log("inside  if 2nd ")
      temp = 0
    }
    // if (liquorType !== '1' && drinkname == 0 &&  standared.length < 1) return setstandandError('Standrad Tag is required!');

    if (liquorType === 4 && drinkname < 0) return setDrinkNameError('Drink Name is required!');
    if (parseInt(liquorType) === 1) {
      data = {
        restaurant_id: restaurant?.id,
        special_id: special_id,
        type_id: type_id,
        day_id: days,
        drinktype_id: liquorType,
        region_id: region,
        wine_subtype_id: winesubtype,
        wine_type_id: winetype,
        container_type_id: container,
        size_id: temp == null ? size : temp,
        discount_type: discountType,
        discount_amount: discountPrice,
        item_description: description,
        masterwine_id: winename,
        data1: winestag
      };
    } else {
      data = {
        restaurant_id: restaurant?.id,
        special_id: special_id,
        type_id: type_id,
        drink_name: drinkNameText,
        day_id: days,
        drinktype_id: liquorType,
        drink_name_id: drinkname,
        container_type_id: container,
        size_id: temp == null ? size : temp,
        discount_type: discountType,
        discount_amount: discountPrice,
        item_description: description,
        data1: drink_ids
      };
    }
    setIsLoading(true)
    apiService.post(
      `/restaurants/specials/food-update/${restaurantspecial_id}`,
      data,
      ({ err, data }) => {
        if (err) {
          console.log(err);
          setIsLoading(false)
        }
        setIsLoading(false)
        setcreatedSuccess(createdSuccess === false ? true : false);
        setTimeout(() => {
          navigate(`/daily-special/drink?type=${liquorType}`, { replace: true });
        }, 5000);
      }
    );
  };

  useEffect(() => {
    apiService.get(`/listDrinkTags`, { drink_type: liquorType }, ({ err, data }) => {
      if (err) return console.log(err);

      let res = [];
      data.data?.map((item) => {
        const temp = {};
        temp["label"] = `${item["tag_name"]}`;
        temp["value"] = item["id"];
        res.push(temp);
      });


      setDrinkData(res);
    });
    apiService.get(`/listDrinkNames`, { drink_type: liquorType }, ({ err, data }) => {
      let Name = ""
      if (liquorType == 1) Name = "Multiple Wines"
      if (liquorType == 2) Name = "Multiple Beers"
      if (liquorType == 3) Name = "Multiple Cider"
      if (liquorType == 4) Name = "Multiple Others"
      if (err) return console.log(err);
      let temp = data.data?.map((drinkname) => ({
        value: drinkname.id,
        label: drinkname.drink_name,
      }))
      setDrinkLists([{ value: 0, label: Name }, ...temp]);
      let res = [];
      data.data?.map((item) => {

        const temp = {};
        temp["label"] = `${item["drink_name"]}`;
        temp["value"] = item["id"];
        res.push(temp);
      });
      setDrink(res)

    });
    apiService.get(`/listTagss`, { type: liquorType }, ({ err, data }) => {
      console.log(data.data, 'tags')
      if (err) return console.log(err);
      let res = [];
      data.data?.map((item) => {
        const temp = {};
        temp["label"] = `${item["tag_name"]}`;
        temp["value"] = item["id"];
        res.push(temp);
      });
      setTags(res);
    });
    let res1 = []

    JSON.parse(tagid).map(item => {

      res1.push(...tags?.filter(tagid => tagid.value == item))
    });

    setselectedTag(res1);
  }, [liquorType])
  useEffect(() => {
    let res1 = []
    JSON.parse(resTag)?.map(item => {
      res1.push(...drink?.filter(drinkid => drinkid.value == item))
    });

    setSelectedStanderd(res1);


    let res2 = []
    JSON.parse(resWinetype)?.map(item => {
      res2.push(...winenames?.filter(wine => wine.value == item))
    })

    setSelectedWineType(res2)



    let res3 = []
    JSON.parse(resWineName)?.map(item => {
      res3.push(...winenamess?.filter(wine => wine.value == item))
    })
    setSelectedWineName(res3)
    let res = []

    JSON.parse(tagid).map(item => {

      res.push(...tags?.filter(tagid => tagid.value == item))

    });

    setselectedTag(res);
  }, [tagid.length, tags, drink.length, resTag, resWinetype, winenames, resWineName, winenamess])
  const handleOnchangeSpecials = (val) => {
    setselectedTag(val);

  };
  const handleOnchangeStand = (val) => {

    setSelectedStanderd(val);

  };
  const handleOnchangeWineType = (val) => {

    setSelectedWineType(val);

  };
  const handleOnchangeWineName = (val) => {

    setSelectedWineName(val);

  };



  let filterWineType = (data) => {
    let obj = { value: 0, label: "Multiple Wine Types" }
    const result = data.filter(item => { if (item.masterwine_id == winename) { return item } else if (winename == 5) return item }
    )
    return result.length ? [obj, ...result] : result
  }
  return (
    <>
      <div className="dashboard-auto-wrapper">
        <div className="create-from">
          {Step === 0 && (
            <div className="create-from-steps active">
              {/* <h4>What day is your Special?
</h4> */}
              <h4>What day is your Special?
              </h4>
              <div className="create-form-inner mt-4">
                <div className="form-group mb-3">
                  <div className="d-flex flex-row justify-content-between">
                    {dayList.length ? (
                      dayList.map((day, i) => (
                        <label className="custom-check">
                          {day.day_name}
                          <input
                            type="radio"
                            name="day"
                            key={days === day.id ? days : ""}
                            defaultChecked={days === day.id ? days : ""}
                            onChange={(e) => setDay(day.id)}
                          />
                          <span className="checkmark"></span>
                        </label>
                      ))
                    ) : (
                      <></>
                    )}
                  </div>

                  <span className="error">{dayError}</span>
                </div>
              </div>
              <div className="create-from-btn">
                <button className="btn btn-transparent" onClick={back}>
                  Back
                </button>
                <button className="btn btn-dark" onClick={nextStep}>
                  Next
                </button>
              </div>
              <div className="mb-3 d-flex align-items-center  graph-sets mt-5">
              <p className="fs-6">
                  {" "}
                  <b>Note:</b> Choose the days your special is available by
                  checking the boxes above. You can select one or multiple days.
                </p>
              </div>
            </div>
          )}
          {Step === 1 && (
            <div className="create-from-steps active">
              <h4>Please Select Type</h4>
              <div className="create-form-inner mt-4">
                <div className="form-group mb-4">
                  <label className="form-label">Type</label>
                  <select
                    key={liquorType}
                    value={liquorType}
                    onChange={(e) => changeliquorType(e)}
                    className="form-control"
                    disabled="true"
                  >
                    {drinkList.length ? (
                      drinkList.map((drink, i) => (
                        <option value={drink.id}>{drink.drinktype_name}</option>
                      ))
                    ) : (
                      <></>
                    )}
                  </select>
                </div>
              </div>
              <div className="create-from-btn mt-5">
                <button className="btn btn-transparent" onClick={backStep}>
                  Back
                </button>
                <button className="btn btn-dark" onClick={nextStep}>
                  Next
                </button>
              </div>
            </div>
          )}
          {Step === 2 && liquorType === 1 && (
            <div className="create-from-steps active">
              <h4>Wine</h4>
              <div className="create-form-inner mt-4">
                <div className="form-group mb-4">
                  <label className="form-label">Region</label>
                  <select
                    className="form-control"
                    key={region}
                    value={region}
                    onChange={(e) => setRegion(e.target.value)}
                  >
                    {regionList.length ? (
                      regionList.map((region, i) => (
                        <option value={region.id}>{region.region_name}</option>
                      ))
                    ) : (
                      <></>
                    )}
                  </select>
                </div>
                <div className="form-group mb-4">
                  <label className="form-label">Red/White</label>
                  <select
                    className="form-control"
                    key={winename}
                    value={winename}
                    onChange={(e) => {
                      setWine(e.target.value)
                      setWineType(-1)


                      // {
                      //   e.target.value !== "10"
                      //     ? setTypeShow(true)
                      //     : setTypeShow(false); setWineSubType(0)
                      // }
                    }}
                  >

                    {wineTypes?.length ? (
                      wineTypes.map((wine, i) => (
                        <option value={wine.id}>{wine.wine_type_name}</option>
                      ))
                    ) : (
                      <></>
                    )}
                  </select>
                </div>

                <div className="form-group mb-4">

                  <label className="form-label">Type</label>
                  <select
                    className="form-control"
                    key={winetype}
                    value={winetype}
                    onChange={(e) => setWineType(e.target.value)}
                  >
                    <option value='-1'>Select </option>
                    {filterWineType(wineTypeList)?.length ? (

                      filterWineType(wineTypeList)?.map((wine, i) => (
                        <option value={wine.value}>{wine.label}</option>
                      ))
                    ) : (
                      <option disabled value='' >
                        No matching Winetype found
                      </option>
                    )}
                  </select>
                  {/* {
                    (isFormTouched && !filterWineType.length) && <span className="error">Type is required!</span>

                  } */}
                  {
                    (isFormTouched && liquorType == '1' && winetype == -1) && <span className="error">Type is required!</span>
                  }
                </div>
                <div className="col-md-12 mb-4">
                  <div className="form-group">
                    <div className="input-right-icon">
                      <label className="form-label">Tags for wine Type</label>
                      <Multiselect
                        placeholder="Select Wine type Tags"
                        options={winenames.filter(item => item.value == winename).length ? winenames.filter(item => item.value == winename) : filterWineType(wineTypeList)?.slice(1)}
                        onSelect={handleOnchangeWineType}
                        disablePreSelectedValues={!!winenames.filter(item => item.value == winetype).length}

                        displayValue="label"
                        selectedValues={winenames.filter(item => item.value == winetype).length ? winenames.filter(item => item.value == winetype) : selectedtype}
                        onRemove={(selectedList, removedItem) => {
                          setSelectedWineType(selectedList)
                        }}
                      />
                    </div>
                    <span className="error">{standaradError}</span>

                  </div>
                </div>
                <div className="form-group mb-4">
                  <label className="form-label">Name</label>

                  {/* <select
										className="form-control"
										key={winesubtype}
										value={winesubtype}
										onChange={(e) => setWineSubType(e.target.value)}
									>
									{wineSubTypeList.length ? (
											wineSubTypeList.map((winetype, i) => (
												<option value={winetype.id}>
													{winetype.winesubtype_name}
												</option>
											))
										) : (
											<></>
										)}
									</select> */}
                  <Select
                    classNamePrefix="form-control"
                    // key={winesubtype}

                    onChange={(selectedOption) => setWineSubType(selectedOption.value)}
                    options={wineSubTypeList}
                    value={wineSubTypeList.filter(function (option) {
                      return option.value === winesubtype;
                    })}
                  />

                </div>
                <div className="col-md-12 mb-4">
                  <div className="form-group">
                    <div className="input-right-icon">
                      <label className="form-label">Tags for wine Names</label>
                      <Multiselect
                        placeholder="Select Wine name Tags"

                        options={winenamess.filter(item => item.value == winesubtype).length ? winenamess.filter(item => item.value == winesubtype) : winenamess}
                        // options={food.filter((item => item.value == foodCat))?.length ? food.filter((item => item.value == foodCat)) : food}
                        onSelect={handleOnchangeWineName}
                        disablePreSelectedValues={!!winenamess.filter(item => item.value == winesubtype).length}
                        displayValue="label"
                        selectedValues={winenamess.filter(item => item.value == winesubtype).length ? winenamess.filter(item => item.value == winesubtype) : selectedWineName}
                        onRemove={(selectedList, removedItem) => {
                          setSelectedWineName(selectedList)
                        }}
                      />
                    </div>
                    <span className="error">{wineError}</span>

                  </div>
                </div>

                <div className="col-md-12 mb-4">
                  <div className="form-group">
                    <div className="input-right-icon">
                      <label className="form-label">Special Tags</label>
                      <Multiselect
                        placeholder="Select Special Tags"
                        options={drinkData}
                        displayValue="label"
                        selectedValues={selectedTag}
                        onSelect={handleOnchangeSpecials}
                        onRemove={(selectedList, removedItem) => {
                          setselectedTag(selectedList)
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="form-group mb-4">
                  <label className="form-label">Container Type</label>
                  <select
                    className="form-control"
                    key={container}
                    value={container}
                    onChange={(e) => {
                      setContainer(e.target.value);
                      // {
                      //   e.target.value === "6"
                      //     ? setSizeShow(true)
                      //     : setSizeShow(false); setSize(0)
                      // }
                    }}
                  >
                    {containerList.length ? (
                      containerList?.filter(item => item.Drink_type_id == liquorType)?.map((container, i) => (
                        <option value={container.id}>
                          {container.container_name}
                        </option>
                      ))
                    ) : (
                      <></>
                    )}
                  </select>
                </div>
                {((sizeList?.filter(item => item.container_id == container && item.Drink_type_id == liquorType)).length) ? (
                  <div className="form-group mb-4">

                    <label className="form-label">Size</label>
                    <select
                      className="form-control"
                      key={size}
                      value={size}
                      onChange={(e) => setSize(e.target.value)}
                    >
                      <option value='0'>Select </option>
                      {sizeList?.length ? (
                        sizeList?.filter(item => item.container_id == container && item.Drink_type_id == liquorType).map((size, i) => (
                          <option value={size.id}>{size.size_name}</option>
                        ))
                      ) : (
                        <></>
                      )}
                    </select>
                    {
                      (isFormTouched && !size && container) && <span className="error">Size is required!</span>
                    }
                  </div>
                ) : (
                  <></>
                )}
                <div className="form-group mb-4">
                  <label className="form-label">Discount</label>
                  <div className="form-group d-flex align-items-center flex-row mb-2">
                    <label className="custom-radio mb-0">
                      Percentage
                      <input
                        type="radio"
                        name="discountType"
                        key={discountType === "percentage" ? "percentage" : ""}
                        defaultChecked={
                          discountType === "percentage" ? "percentage" : ""
                        }
                        onChange={(e) => setDiscountType("percentage")}
                      />
                      <span className="checkmark"></span>
                    </label>
                    <label className="custom-radio ms-3 mb-0">
                      Price
                      <input
                        type="radio"
                        name="discountType"
                        key={discountType === "price" ? "price" : ""}
                        defaultChecked={discountType === "price" ? "price" : ""}
                        onChange={(e) => setDiscountType("price")}
                      />
                      <span className="checkmark"></span>
                    </label>
                    <label className="custom-radio ms-3 mb-0">
                      Amount Off
                      <input
                        type="radio"
                        name="discountType"
                        key={discountType === "amount" ? "amount" : ""}
                        defaultChecked={
                          discountType === "amount" ? "amount" : ""
                        }
                        onChange={(e) => setDiscountType("amount")}
                      />
                      <span className="checkmark"></span>
                    </label>
                  </div>
                  <Select
                    classNamePrefix="form-control"
                    name="discountPrice"
                    onChange={(e) => setDiscountPrice(e.value)}
                    options={discountType == "percentage" ? perPriceoption : Priceoption}
                    value={discountType == "percentage" ? perPriceoption.filter(function (option) {
                      console.log(option.value, 'value')
                      return option.value === discountPrice;
                    }) :
                      Priceoption.filter(function (option) {
                        return option.value === discountPrice;
                      })}
                  />
                </div>
                <div className="form-group mb-4">
                  <label className="form-label">Description(255 characters)</label>
                  <textarea maxLength={255}
                    className="form-control"
                    name="description"
                    onChange={(e) => setDescription(e.target.value)}
                    value={description}
                  />
                  {
                    (isFormTouched && !description) && <span className="error">Description is required!</span>
                  }
                </div>
              </div>
              <div className="create-from-btn mt-5">
                <button className="btn btn-transparent" onClick={backStep}>
                  Back
                </button>
                <button className="btn btn-dark" disabled={isLoading} onClick={handleSubmit}>
                  {
                    isLoading ? <FontAwesomeIcon icon={faCircleNotch} spin /> : 'Update'

                  }
                </button>
              </div>
            </div>
          )}
          {Step === 2 && liquorType === 2 && (
            <div className="create-from-steps active">
              <h4>Beer</h4>
              <div className="create-form-inner mt-4">
                <div className="form-group mb-4">
                  <label className="form-label">Name</label>

                  <Select


                    classNamePrefix="form-control"
                    onChange={(selectedOption) => setDrinkname(selectedOption.value)}
                    options={drinkLists}
                    value={drinkLists.filter(function (option) {

                      return option.value === drinkname;
                    })}

                  />

                  <div className="d-flex align-items-center  graph-sets mt-2">

                    <p> <b>Note:</b> Don’t see your option here? Submit a request to admin.</p>
                  </div>
                </div>
                <div className="form-group mb-4">
                  <label className="form-label">Container Type</label>
                  <select
                    className="form-control"
                    key={container}
                    value={container}
                    onChange={(e) => {
                      setContainer(e.target.value);
                      // {
                      //   e.target.value === "6"
                      //     ? setSizeShow(true)
                      //     : setSizeShow(false); setSize(0)
                      // }
                    }}
                  >
                    {containerList.length ? (
                      containerList?.filter(item => item.Drink_type_id == liquorType)?.map((container, i) => (
                        <option value={container.id}>
                          {container.container_name}
                        </option>
                      ))
                    ) : (
                      <></>
                    )}
                  </select>
                </div>
                {((sizeList?.filter(item => item.container_id == container && item.Drink_type_id == liquorType)).length) ? (
                  <div className="form-group mb-4">
                    <label className="form-label">Size</label>
                    <select
                      className="form-control"
                      key={size}
                      value={size}
                      onChange={(e) => setSize(e.target.value)}
                    >
                      <option value=''>Select </option>
                      {sizeList.length ? (
                        sizeList?.filter(item => item.container_id == container && item.Drink_type_id == liquorType).map((size, i) => (
                          <option value={size.id}>{size.size_name}</option>
                        ))
                      ) : (
                        <></>
                      )}
                    </select>
                    {
                      (isFormTouched && !size && container) && <span className="error">Size is required!</span>
                    }
                  </div>
                ) : (
                  <></>
                )}
                <div className="form-group mb-4">
                  <label className="form-label">Discount</label>
                  <div className="form-group d-flex align-items-center flex-row mb-2">
                    <label className="custom-radio mb-0">
                      Percentage
                      <input
                        type="radio"
                        name="discountType"
                        key={discountType === "percentage" ? "percentage" : ""}
                        defaultChecked={
                          discountType === "percentage" ? "percentage" : ""
                        }
                        onChange={(e) => setDiscountType("percentage")}
                      />
                      <span className="checkmark"></span>
                    </label>
                    <label className="custom-radio ms-3 mb-0">
                      Price
                      <input
                        type="radio"
                        name="discountType"
                        key={discountType === "price" ? "price" : ""}
                        defaultChecked={discountType === "price" ? "price" : ""}
                        onChange={(e) => setDiscountType("price")}
                      />
                      <span className="checkmark"></span>
                    </label>
                    <label className="custom-radio ms-3 mb-0">
                      Amount Off
                      <input
                        type="radio"
                        name="discountType"
                        key={discountType === "amount" ? "amount" : ""}
                        defaultChecked={
                          discountType === "amount" ? "amount" : ""
                        }
                        onChange={(e) => setDiscountType("amount")}
                      />
                      <span className="checkmark"></span>
                    </label>
                  </div>
                  <Select
                    classNamePrefix="form-control"
                    name="discountPrice"
                    onChange={(e) => setDiscountPrice(e.value)}
                    options={discountType == "percentage" ? perPriceoption : Priceoption}
                    value={discountType == "percentage" ? perPriceoption.filter(function (option) {
                      console.log(option.value, 'value')
                      return option.value === discountPrice;
                    }) :
                      Priceoption.filter(function (option) {
                        return option.value === discountPrice;
                      })}
                  />
                </div>
                <div className="form-group mb-4">
                  <label className="form-label">Description (255 characters)</label>
                  <textarea maxLength={255}
                    className="form-control"
                    name="description"
                    onChange={(e) => setDescription(e.target.value)}
                    value={description}
                  />
                  {
                    (isFormTouched && !description) && <span className="error">Description is required!</span>
                  }
                </div>
                <div className="col-md-12 mb-4">
                  <div className="form-group">
                    <div className="input-right-icon">
                      <label className="form-label">Special Tags</label>
                      <Multiselect
                        placeholder="Select Special Tags"
                        options={drinkData}
                        displayValue="label"
                        selectedValues={selectedTag}
                        onSelect={handleOnchangeSpecials}
                        onRemove={(selectedList, removedItem) => {
                          setselectedTag(selectedList)
                        }}
                      />
                    </div>
                  </div>
                </div>

                <div className="col-md-12">
                  <div className="form-group">
                    <div className="input-right-icon">
                      <label className="form-label">Standard Tags</label>
                      <Multiselect
                        placeholder="Select Beer Tags"
                        options={drink.filter((item => item.value == drinkname))?.length ? drink.filter((item => item.value == drinkname)) : drink}
                        // // options={food.filter((item => item.value == foodCat))?.length ? food.filter((item => item.value == foodCat)) : food}
                        onSelect={handleOnchangeStand}
                        disablePreSelectedValues={!!drink.filter((item => item.value == drinkname)).length}
                        displayValue="label"
                        selectedValues={drinkname !== 0 ? drink.filter((item => item.value == drinkname)) : standared}
                        onRemove={(selectedList, removedItem) => {
                          setSelectedStanderd(selectedList)
                        }}
                      />
                    </div>
                    <span className="error">{standaradError}</span>

                  </div>
                  <div className="d-flex align-items-center  graph-sets mt-2">

                    <p> <b>Note:</b> If ‘Multiple Items’ is selected for Name Category, select all tags that apply.</p>
                  </div>
                </div>
              </div>
              <div className="create-from-btn mt-5">
                <button className="btn btn-transparent" onClick={backStep}>
                  Back
                </button>
                <button className="btn btn-dark" disabled={isLoading} onClick={handleSubmit}>
                  {
                    isLoading ? <FontAwesomeIcon icon={faCircleNotch} spin /> : 'Update'

                  }
                </button>
              </div>
            </div>
          )}
          {Step === 2 && liquorType === 3 && (
            <div className="create-from-steps active">
              <h4>Cider</h4>
              <div className="create-form-inner mt-4">
                <div className="form-group mb-4">
                  <label className="form-label">Name</label>

                  <Select


                    classNamePrefix="form-control"
                    onChange={(selectedOption) => setDrinkname(selectedOption.value)}
                    options={drinkLists}
                    value={drinkLists.filter(function (option) {

                      return option.value === drinkname;
                    })}

                  />
                  <div className="d-flex align-items-center  graph-sets mt-2">

                    <p> <b>Note:</b> Don’t see your option here? Submit a request to admin.</p>
                  </div>
                </div>
                <div className="form-group mb-4">
                  <label className="form-label">Container Type</label>
                  <select
                    className="form-control"
                    key={container}
                    value={container}
                    onChange={(e) => {
                      setContainer(e.target.value);
                      // {
                      //   e.target.value === "6"
                      //     ? setSizeShow(true)
                      //     : setSizeShow(false); setSize(0)
                      // }
                    }}
                  >
                    {containerList.length ? (
                      containerList?.filter(item => item.Drink_type_id == liquorType)?.map((container, i) => (
                        <option value={container.id}>
                          {container.container_name}
                        </option>
                      ))
                    ) : (
                      <></>
                    )}
                  </select>
                </div>
                {((sizeList?.filter(item => item.container_id == container && item.Drink_type_id == liquorType)).length) ? (
                  <div className="form-group mb-4">
                    <label className="form-label">Size</label>
                    <select
                      className="form-control"
                      key={size}
                      value={size}
                      onChange={(e) => setSize(e.target.value)}
                    >
                      <option value=''>Select </option>
                      {sizeList.length ? (
                        sizeList?.filter(item => item.container_id == container && item.Drink_type_id == liquorType).map((size, i) => (
                          <option value={size.id}>{size.size_name}</option>
                        ))
                      ) : (
                        <></>
                      )}
                    </select>
                    {
                      (isFormTouched && !size && container) && <span className="error">Size is required!</span>
                    }
                  </div>
                ) : (
                  <></>
                )}
                <div className="form-group mb-4">
                  <label className="form-label">Discount</label>
                  <div className="form-group d-flex align-items-center flex-row mb-2">
                    <label className="custom-radio mb-0">
                      Percentage
                      <input
                        type="radio"
                        name="discountType"
                        key={discountType === "percentage" ? "percentage" : ""}
                        defaultChecked={
                          discountType === "percentage" ? "percentage" : ""
                        }
                        onChange={(e) => setDiscountType("percentage")}
                      />
                      <span className="checkmark"></span>
                    </label>
                    <label className="custom-radio ms-3 mb-0">
                      Price
                      <input
                        type="radio"
                        name="discountType"
                        key={discountType === "price" ? "price" : ""}
                        defaultChecked={discountType === "price" ? "price" : ""}
                        onChange={(e) => setDiscountType("price")}
                      />
                      <span className="checkmark"></span>
                    </label>
                    <label className="custom-radio ms-3 mb-0">
                      Amount Off
                      <input
                        type="radio"
                        name="discountType"
                        key={discountType === "amount" ? "amount" : ""}
                        defaultChecked={
                          discountType === "amount" ? "amount" : ""
                        }
                        onChange={(e) => setDiscountType("amount")}
                      />
                      <span className="checkmark"></span>
                    </label>
                  </div>
                  <Select
                    classNamePrefix="form-control"
                    name="discountPrice"
                    onChange={(e) => setDiscountPrice(e.value)}
                    options={discountType == "percentage" ? perPriceoption : Priceoption}
                    value={discountType == "percentage" ? perPriceoption.filter(function (option) {
                      console.log(option.value, 'value')
                      return option.value === discountPrice;
                    }) :
                      Priceoption.filter(function (option) {
                        return option.value === discountPrice;
                      })}
                  />
                </div>
                <div className="form-group mb-4">
                  <label className="form-label">Description(255 characters)</label>
                  <textarea maxLength={255}
                    className="form-control"
                    name="description"
                    onChange={(e) => setDescription(e.target.value)}
                    value={description}
                  />
                  {
                    (isFormTouched && !description) && <span className="error">Description is required!</span>
                  }
                </div>
                <div className="col-md-12 mb-4">
                  <div className="form-group">
                    <div className="input-right-icon">
                      <label className="form-label">Special Tags</label>
                      <Multiselect
                        placeholder="Select Special Tags"
                        options={drinkData}
                        displayValue="label"
                        selectedValues={selectedTag}
                        onSelect={handleOnchangeSpecials}
                        onRemove={(selectedList, removedItem) => {
                          setselectedTag(selectedList)
                        }}
                      />
                    </div>
                  </div>
                </div>

                <div className="col-md-12">
                  <div className="form-group">
                    <div className="input-right-icon">
                      <label className="form-label">Standard Tags</label>
                      <Multiselect
                        placeholder="Select Cider Tags"
                        options={drink.filter((item => item.value == drinkname))?.length ? drink.filter((item => item.value == drinkname)) : drink}
                        // // options={food.filter((item => item.value == foodCat))?.length ? food.filter((item => item.value == foodCat)) : food}
                        onSelect={handleOnchangeStand}
                        disablePreSelectedValues={!!drink.filter((item => item.value == drinkname)).length}
                        displayValue="label"
                        selectedValues={drinkname !== 0 ? drink.filter((item => item.value == drinkname)) : standared}
                        onRemove={(selectedList, removedItem) => {
                          setSelectedStanderd(selectedList)
                        }}
                      />
                    </div>
                    <span className="error">{standaradError}</span>

                  </div>
                  <div className="d-flex align-items-center  graph-sets mt-2">

                    <p> <b>Note:</b> If ‘Multiple Items’ is selected for Name Category, select all tags that apply.</p>
                  </div>
                </div>
              </div>
              <div className="create-from-btn mt-5">
                <button className="btn btn-transparent" onClick={backStep}>
                  Back
                </button>
                <button className="btn btn-dark" disabled={isLoading} onClick={handleSubmit}>
                  {
                    isLoading ? <FontAwesomeIcon icon={faCircleNotch} spin /> : 'Update'

                  }
                </button>
              </div>
            </div>
          )}
          {Step === 2 && liquorType === 4 && (
            <div className="create-from-steps active">
              <h4>Other</h4>
              <div className="create-form-inner mt-4">
                <div className="form-group mb-4">
                  <label className="form-label">Name</label>
                  {/* <input
                    className="form-control"
                    type="text" value={drinkNameText} onChange={(e) => setDrinkNameText(e.target.value)}
                  /> */}
                  <Select
                    classNamePrefix="form-control"
                    onChange={(selectedOption) => setDrinkname(selectedOption.value)}
                    options={drinkLists}
                    value={drinkLists.filter(function (option) {

                      return option.value === drinkname;
                    })}

                  />
                  <div className="d-flex align-items-center  graph-sets mt-2">

                    <p> <b>Note:</b>  Don’t see your option here? Submit a request to admin.</p>
                  </div>

                </div>
                <div className="form-group mb-4">
                  <label className="form-label">Discount</label>
                  <div className="form-group d-flex align-items-center flex-row mb-2">
                    <label className="custom-radio mb-0">
                      Percentage
                      <input
                        type="radio"
                        name="discountType"
                        key={discountType === "percentage" ? "percentage" : ""}
                        defaultChecked={
                          discountType === "percentage" ? "percentage" : ""
                        }
                        onChange={(e) => setDiscountType("percentage")}
                      />
                      <span className="checkmark"></span>
                    </label>
                    <label className="custom-radio ms-3 mb-0">
                      Price
                      <input
                        type="radio"
                        name="discountType"
                        key={discountType === "price" ? "price" : ""}
                        defaultChecked={discountType === "price" ? "price" : ""}
                        onChange={(e) => setDiscountType("price")}
                      />
                      <span className="checkmark"></span>
                    </label>
                    <label className="custom-radio ms-3 mb-0">
                      Amount Off
                      <input
                        type="radio"
                        name="discountType"
                        key={discountType === "amount" ? "amount" : ""}
                        defaultChecked={
                          discountType === "amount" ? "amount" : ""
                        }
                        onChange={(e) => setDiscountType("amount")}
                      />
                      <span className="checkmark"></span>
                    </label>
                  </div>
                  <Select
                    classNamePrefix="form-control"
                    name="discountPrice"
                    onChange={(e) => setDiscountPrice(e.value)}
                    options={discountType == "percentage" ? perPriceoption : Priceoption}
                    value={discountType == "percentage" ? perPriceoption.filter(function (option) {
                      console.log(option.value, 'value')
                      return option.value === discountPrice;
                    }) :
                      Priceoption.filter(function (option) {
                        return option.value === discountPrice;
                      })}
                  />
                </div>
                <div className="form-group mb-4">
                  <label className="form-label">Description(255 characters)</label>
                  <textarea maxLength={255}
                    className="form-control"
                    name="description"
                    onChange={(e) => setDescription(e.target.value)}
                    value={description}
                  />
                  {
                    (isFormTouched && !description) && <span className="error">Description is required!</span>
                  }
                </div>
                <div className="col-md-12 mb-4">
                  <div className="form-group">
                    <div className="input-right-icon">
                      <label className="form-label">Special Tags</label>
                      <Multiselect
                        placeholder="Select Special Tags"
                        options={drinkData}
                        displayValue="label"
                        selectedValues={selectedTag}
                        onSelect={handleOnchangeSpecials}
                        onRemove={(selectedList, removedItem) => {
                          setselectedTag(selectedList)
                        }}
                      />
                    </div>
                  </div>
                </div>

                <div className="col-md-12">
                  <div className="form-group">
                    <div className="input-right-icon">
                      <label className="form-label">Standard Tags</label>
                      <Multiselect
                        placeholder="Select Other Tags"
                        options={drink.filter((item => item.value == drinkname))?.length ? drink.filter((item => item.value == drinkname)) : drink}
                        // // options={food.filter((item => item.value == foodCat))?.length ? food.filter((item => item.value == foodCat)) : food}
                        onSelect={handleOnchangeStand}
                        disablePreSelectedValues={!!drink.filter((item => item.value == drinkname)).length}
                        displayValue="label"
                        selectedValues={drinkname !== 0 ? drink.filter((item => item.value == drinkname)) : standared}
                        onRemove={(selectedList, removedItem) => {
                          setSelectedStanderd(selectedList)
                        }}
                      />
                    </div>
                    <span className="error">{standaradError}</span>
                  </div>
                  <div className="d-flex align-items-center  graph-sets mt-2">

                    <p> <b>Note:</b>  If ‘Multiple Items’ is selected for Name Category, select all tags that apply.</p>
                  </div>
                </div>
              </div>
              <div className="create-from-btn mt-5">
                <button className="btn btn-transparent" onClick={backStep}>
                  Back
                </button>
                <button className="btn btn-dark" disabled={isLoading} onClick={handleSubmit}>
                  {
                    isLoading ? <FontAwesomeIcon icon={faCircleNotch} spin /> : 'Update'

                  }
                </button>
              </div>
            </div>
          )}
          {createdSuccess === true ? (
            <>
              <div className="common-message-pop show" id="created-success-pop">
                <div className="common-pop-inner">
                  <div className="common-pop-box created-message-box">
                    <h2>Cheers!</h2>
                    <p className="message-txt">
                      You just updated a Daily Special!!
                    </p>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <></>
          )}
        </div>
      </div>
    </>
  );
};

export default DailyDrinkUpdate;

import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPencilAlt, faTimes, faCalendarAlt, faSearch, faCircleNotch, faCircleDot } from "@fortawesome/free-solid-svg-icons";
import Modal from 'react-bootstrap/Modal';
import apiService, { REACT_APP_LIMIT } from '../../core/apiService';
import { useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import moment from 'moment';
import Filter from '../../assets/images/filter-ico.svg';
import { useLocation, useNavigate } from "react-router-dom";
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css';
import CustomSuccess from '../../components/CustomSuccess';

const RequestAdmin = () => {
	const { register, handleSubmit, formState: { errors }, setValue } = useForm();
	const [isFilter, setisFilter] = useState(false);
	const restuarant = useSelector(state => state.restaurant);
	const [requestsList, setRequestList] = useState([]);
	const [typeList, setTypeList] = useState([]);
	const showFilter = () => {
		setisFilter(isFilter === false ? true : false);
		if (isFilter === true) return;
		setCurrentPage(1)
	};
	const [loading, setLoading] = useState(false);
	const [disabledStatus, setdisabledStatus] = useState(false)
	const [show, setShow] = useState(false);
	const [updateshow, setUpdateShow] = useState(false);
	const [formSaving, setFormSaving] = useState(false);
	const [listError, setListError] = useState();
	const [requestid, setRequestid] = useState('');
	const [dataLoading, setDataLoading] = useState(false);
	const navigate = useNavigate();
	const [appliedFilters, setAppliedFilters] = useState({
		search: '',
		start_date: '',
		end_date: ''
	});
	const [currentPage, setCurrentPage] = useState(1)
	const limit = process.env.REACT_APP_LIMIT;
	const [total, setTotal] = useState([])

	const requestsListData = (args) => {
		setLoading(true);
		let params = {
			restaurant_id: restuarant?.id, pageno: currentPage,
			search: appliedFilters.search,
			start_date: appliedFilters.start_date,
			end_date: appliedFilters.end_date
		};
		if (args?.filters) {
			params = { ...params, ...args.filters }
		}
		apiService.get(`/restaurants/requests`, params, ({ err, data }) => {
			if (err) return console.log(err), setLoading(false), setisFilter(false);
			setRequestList(data.data.rows);
			setTotal(data.data.count)
			setisFilter(false);
			setLoading(false);
		})
	}

	useEffect(() => {
		setLoading(true);
		requestsListData();
		apiService.get('/types', {}, ({ err, data }) => {
			if (err) return console.log(err);
			setTypeList(data.data);
		});
	}, [currentPage]);

	const updateData = (page) => {
		setCurrentPage(page)
	}

	let lastPage = Math.ceil(total / REACT_APP_LIMIT)

	const nextPage = () => {
		if (currentPage < lastPage) {
			updateData(currentPage + 1)
		}
	}

	const previousPage = () => {
		updateData(currentPage - 1)
	}
	const handleClose = () => {
		setShow(false);
		setUpdateShow(false);
	}

	const raiseRequest = (values) => {
		if (requestid === '') {
			setFormSaving(true);
			apiService.post('/restaurants/requests', values, ({ err, data }) => {
				if (err) {
					setFormSaving(false);
					console.log(err);
				};
				setFormSaving(false);
				requestsListData();
				setValue('subject', '');
				setValue('type_id', '');
				setValue('description', '');
				CustomSuccess('Saved successfully', 'Success')
				handleClose();
			})
		} else {
			setFormSaving(true);
			apiService.post(`/restaurants/requests/update/${requestid}`, values, ({ err, data }) => {
				if (err) {
					setFormSaving(false);
					console.log(err);
				};

				setFormSaving(false);
				requestsListData();
				setRequestid('');
				setValue('subject', '');
				setValue('type_id', '');
				setValue('description', '');
				CustomSuccess('Updated successfully', 'Success')
				handleClose();
			})
		}
	}


	const handleShow = () => {
		setValue('subject', '');
		setValue('type_id', '');
		setValue('description', '');
		setdisabledStatus(false)
		setShow(true);
	}

	const handleUpdateShow = (id, type, message = '') => {
		if (type == 'view') setdisabledStatus(true)
		if (type == 'update') setdisabledStatus(false)
		setShow(true);
		apiService.get(`/restaurants/requests/detail`, { id }, ({ err, data }) => {
			if (err) return console.log(err);
			setRequestid(data.data.id);
			setValue('type_id', data.data.type_id);
			setValue('subject', data.data.subject);
			setValue('description', data.data.description);
			setValue('message', data.data.message)
			
		})
	}
	const handleDelete = (id) => {
		apiService.remove(`/restaurants/requests/${id}`, ({ err, data }) => {
			if (err) return setDataLoading(false), setListError(err.error);
			setRequestList(requestsList.filter((i) => (i.id !== id)));
		});
	};

	const resetFilters = () => {
		setAppliedFilters({
			search: '',
			start_date: '',
			end_date: ''
		});
	};

	const submitDelete = (id) => {

		confirmAlert({
			title: 'confirm to delete',
			message: 'Are you sure you want to delete?.',
			buttons: [
				{
					label: 'Yes',
					onClick: () => handleDelete(id)
				},
				{
					label: 'No',
					//onClick: () => alert('Click No')
				}
			]
		});
	}
	console.log(requestsList,'requestsList')
	return (
		<>
			<div className="dashboard-top-btn">
				<div className="table-filter">
					<button onClick={showFilter} className="btn btn-default filter-btn"><img alt="" src={Filter} />Filters</button>
				</div>
				<button className="btn btn-dark" onClick={handleShow}><FontAwesomeIcon icon={faPencilAlt} /> Create</button>
			</div>
			<div className="table-sec common-table margin-t30">
				<div className="table-responsive">
					<table className="table white-td-table">
						<thead>
							<tr>
								<th>Type</th>
								<th>Subject</th>
								<th>Description</th>
								<th>Date & Time</th>
								<th>Status</th>
								<th>Action</th>
							</tr>
						</thead>
						<tbody className={requestsList.length > 0 ? '' : 'empty_row'}>
							{
								loading ?
									<tr>
										<td colSpan={5}>
											<div className='table-inner-loader'>
												<FontAwesomeIcon style={{ fontSize: 25 }} icon={faCircleNotch} spin />
											</div>
										</td>
									</tr> :
									requestsList.length ?
										requestsList.map((request, i) => {

											return <tr key={i}>
												<td>{request.type?.type_name}</td>
												<td>{request.subject}</td>
												<td>{request.description}</td>
												<td>{moment(request.created_at).format('YYYY-MM-DD, hh:mm')}</td>
												<td>{request.status == '0' ?
													<span className="pending_status"><FontAwesomeIcon icon={faCircleDot} /> Pending</span> :
													<> {request.status == '1' ? request.type_id == '3' ? <span className="active_status">Replied</span> : <span className="active_status">Approved</span> :
														<span className="pending_status"> Rejected </span>} </>}</td>
												<td className="table-action">
													{(request && request.status == 0) ?
														<>
															<button className="btn btn-success"
																onClick={() => {
																	handleUpdateShow(request.id, "update");
																}}
															>Update</button>
															<button className="btn btn-danger"
																onClick={() => {
																	submitDelete(request.id);
																}}
															>Delete</button>
														</> :
														<button className="btn btn-success"
															onClick={() => {
																handleUpdateShow(request.id, "view", request.message);
															}}
														>View</button>

													}

												</td>
											</tr>
										}) :
										<tr>
											<td colspan="10">
												No records found!
											</td>
										</tr>
							}
						</tbody>
					</table>
				</div>
			</div>
			{isFilter === true ?
				<>
					<div className="popup-overlay show"></div>
					<div id="filter-pop" className='show'>
						<div className="filter-pop-inner">
							<div className="filter-head">
								<div className='filter-title'>
									<h4>Filters</h4>
									<button className='btn btn-link' onClick={() => {
										resetFilters();
										requestsListData()
									}}>All Records</button>
								</div>
								<button className="times-btn filter-close" onClick={showFilter}><FontAwesomeIcon icon={faTimes} /></button>
							</div>
							<div className="filter-body">
								<div className="mb-4">
									<div className="input-icon">
										<span className='input-icon-alt'><FontAwesomeIcon icon={faSearch} /></span>
										<input
											value={appliedFilters.search}
											onChange={(e) => setAppliedFilters({ ...appliedFilters, search: e.target.value })}
											type="text"
											className="form-control"
											placeholder="Type here..."
										/>
									</div>
								</div>
								<div className="row mb-4">
									<div className="col-md-6">
										<div className="input-icon">
											<label className="form-label">Start Date</label>
											{/* <span className='input-icon-alt'><FontAwesomeIcon icon={faCalendarAlt} /></span> */}
											<input type="date" value={appliedFilters.start_date}
												onChange={(e) => setAppliedFilters({ ...appliedFilters, start_date: e.target.value })}
												className="form-control" placeholder="Start Date" />
										</div>
									</div>
									<div className="col-md-6">
										<div className="input-icon">
											<label className="form-label">End Date</label>

											{/* <span className='input-icon-alt'><FontAwesomeIcon icon={faCalendarAlt} /></span> */}
											<input
												onChange={(e) => setAppliedFilters({ ...appliedFilters, end_date: e.target.value })}
												value={appliedFilters.end_date} type="date" className="form-control" placeholder="End Date" />
										</div>
									</div>
								</div>
							</div>
							<div className="filter-form-btns">
								<button className="btn btn-dark full-width-btn add-couple" onClick={() => requestsListData({
									filters: appliedFilters
								})} disabled={loading}>
									{
										loading ? <FontAwesomeIcon icon={faCircleNotch} spin /> : 'Apply Filter'

									}</button>
							</div>
						</div>
					</div>
				</> : <>
					<Modal show={show} className="common-pop popup-medium" onHide={handleClose}>
						<button type="button" className="btn-close" onClick={handleClose}></button>
						<div className="custom-pop-inner">
							<div className="custom-pop-box">
								<div id="add-user-form">
									<form onSubmit={handleSubmit(raiseRequest)}>
										<input {...register('restaurant_id', { value: restuarant?.id })} type="hidden" className="form-control" disabled={disabledStatus} />
										<div className="custom-pop-body">
											<h4 className="pop-title text-center">Submit a Request to Admin</h4>
											<div className="pop-form">
												<div className="mb-3">
													<label>Type</label>
													{
														typeList.length ?
															<>
																<select {...register('type_id', { required: 'This field is required' })} className="form-control" disabled={disabledStatus}>
																	<option selected disabled value={""}>Please Select</option>
																	{
																		typeList.map((item, index) => (
																			<option key={index} value={item.id}>{item.type_name}</option>
																		))
																	}
																</select>
																{errors?.type_id && <span className="error name-error">{errors?.type_id?.message}</span>}
															</> : <p>No Types found</p>
													}
												</div>
												<div className="mb-3">
													<label>Subject(100 characters)</label>
													<input maxLength={100} {...register('subject', { required: ' This field is required' })} type="text" className="form-control" disabled={disabledStatus} />
													{errors?.subject && <span className="error name-error">{errors?.subject?.message}</span>}
												</div>
												<div className="mb-5">
													<label>Description(255 characters)</label>
													<textarea maxLength={255} {...register('description', { required: 'This field is required' })} id="email" className="form-control" disabled={disabledStatus}></textarea>
													{errors?.description && <span className="error name-error">{errors?.description?.message}</span>}
												</div>
												<input {...register('status', { required: 'status is required', value: "0" })} type="hidden" className="form-control" />
											</div>
										</div>



										{disabledStatus == true ? <>
											<div className="mb-5">
												<label>Admin Message</label>
												<textarea {...register('message', { required: 'This field is required' })} id="email" className="form-control" disabled={disabledStatus} ></textarea>
												{errors?.description && <span className="error name-error">{errors?.description?.message}</span>}
											</div>
										</> :
											<div className="custom-pop-footer"><div className="text-center"><button type="submit" className="btn btn-dark btn-long" disabled={formSaving}>{
												formSaving ? <FontAwesomeIcon icon={faCircleNotch} spin /> : 'Send'
											}</button></div></div>}



									</form>
								</div>
							</div>
						</div>
					</Modal>
					{
						lastPage > 1 ?
							<>

								{<ul className="pagination justify-content-center">
									<li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
										<button className="page-link" onClick={previousPage}>
											Previous
										</button>
									</li>
									{Array.from({ length: lastPage }).map((_, index) => (
										<li key={index} className={`page-item ${currentPage === index + 1 ? 'active' : ''}`}>
											<button className="page-link" onClick={() => updateData(index + 1)}>
												{index + 1}
											</button>
										</li>
									))}
									<li className={`page-item ${currentPage === lastPage ? 'disabled' : ''}`}>
										<button className="page-link" onClick={nextPage}>
											Next
										</button>
									</li>
								</ul>
								}
							</> : <></>
					}

				</>

			}

		</>

	);
};

export default RequestAdmin;
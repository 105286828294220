import { Routes, Route } from "react-router-dom";
import { Layout } from './layout/Layout';
import { LoginLayout } from './layout/LoginLayout';
import Loginpage from './pages/Login/login';
import ForgotPassword from './pages/ForgotPassword/index';
import Register from './pages/Register/index.js';
import Dashboard from './pages/Dashboard/index';
import DailySpacialDrink from './pages/DailySpecial/drink';
import DailySpacialFood from './pages/DailySpecial/food';
import Communication from './pages/Communication/index';
import BusinessVerify from './pages/Register/BusinessVerify';
import AlternateBusinessVerify from './pages/Register/AlternateBusinessVerify';
import Verification from './pages/Register/verification';
import Membership from './pages/Register/membership';
import CreateSpecial from './pages/CreateSpecial/index';
import CreateDailySpecial from './pages/CreateSpecial/dailySpecial';
import CreateGameSpecial from './pages/CreateSpecial/gameSpecial';
import CreatehappyHours from './pages/CreateSpecial/happyhours';
import CreateOnTap from './pages/CreateSpecial/onTap';
import ProfileSetting from './pages/profileSetting/index';
import DailyCreate from './pages/DailySpecial/create';
import FoodCreate from './pages/DailySpecial/foodCreate';
import FoodUpdate from './pages/DailySpecial/foodUpdate';
import DailyDrinkCreate from './pages/DailySpecial/drinkCreate';
import DailyDrinkUpdate from './pages/DailySpecial/drinkUpdate';
import HappyHourDrink from './pages/HappyHour/drink';
import HappyHourFood from './pages/HappyHour/food';
import HappyFoodCreate from './pages/HappyHour/foodCreate';
import HappyDrinkCreate from './pages/HappyHour/drinkCreate';
import HappyFoodUpdate from './pages/HappyHour/foodUpdate';
import HappyDrinkUpdate from './pages/HappyHour/drinkUpdate';
import GameSpacialDrink from './pages/GameDailySpecial/drink';
import GameSpacialFood from './pages/GameDailySpecial/food';
import GameFoodCreate from './pages/GameDailySpecial/foodCreate';
import GameDrinkCreate from './pages/GameDailySpecial/drinkCreate';
import GameFoodUpdate from './pages/GameDailySpecial/foodUpdate';
import GameDrinkUpdate from './pages/GameDailySpecial/drinkUpdate';
import OnTapDrink from './pages/OnTap/drink';
import OnTapCreate from './pages/OnTap/drinkCreate';
import OnTapUpdate from './pages/OnTap/drinkUpdate';
import RequestAdmin from './pages/RequestAdmin/index';
import UpgradeMembership from './pages/profileSetting/upgrade';
import { ProtectedRoute } from "./components/ProtectedRoute";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Specials from "./pages/Specials";
import SpecialCreate from "./components/CreateSpecial";
import Stripe from "./pages/Stripe";
import Completion from "./pages/Stripe/Completion";
import Success from "./pages/Stripe/Success";
import { useState } from "react";

function App() {
  // toast.success("Error Notification !", {
  //   position: toast.POSITION.TOP_CENTER
  // });
  const [refresh,setRefresh] =useState(false)
  return (
    <div className="App">
      <Routes>
        <Route path='/' element={<LoginLayout />}>
          <Route index element={<Loginpage />} />
          <Route path='/forgot-password' element={<ForgotPassword />} />
          <Route path='/register' element={<Register />} />
          <Route path='/verify-business' element={<ProtectedRoute><BusinessVerify /></ProtectedRoute>} />
          <Route path='/alternate-verify-business' element={<ProtectedRoute><AlternateBusinessVerify /></ProtectedRoute>} />
          <Route path='/Verification' element={<ProtectedRoute><Verification /></ProtectedRoute>} />
          <Route path='/membership' element={<ProtectedRoute><Membership /></ProtectedRoute>} />
          <Route path='/checkout' element={<Stripe />} />
          <Route path='/completion' element={<Completion />} />
          <Route path='/success' element={<Success />} />
        </Route>
        <Route path='' element={<ProtectedRoute><Layout refresh={refresh}/></ProtectedRoute>}>
          <Route path='/dashboard' element={<Dashboard />} />
          <Route path='/create-special' element={<CreateSpecial />} />
          <Route path='/create-special/daily-special' element={<CreateDailySpecial />} />
          <Route path='/create-special/game-special' element={<CreateGameSpecial />} />
          <Route path='/create-special/happy-hours' element={<CreatehappyHours />} />
          <Route path='/create-special/on-tap' element={<CreateOnTap />} />
          <Route path='/daily-special/drink' element={<DailySpacialDrink />} />
          <Route path='/daily-special/food' element={<DailySpacialFood />} />
          <Route path='/daily-special/create' element={<FoodCreate />} />
          <Route path='/daily-special/drink/create' element={<DailyDrinkCreate />} />
          <Route path='/daily-special/food/update/:id' element={<FoodUpdate />} />
          <Route path='/daily-special/drink/update/:id' element={<DailyDrinkUpdate />} />
          <Route path='/happy-hour/drink' element={<HappyHourDrink />} />
          <Route path='/happy-hour/food' element={<HappyHourFood />} />
          <Route path='/happy-hour/food/create' element={<HappyFoodCreate />} />
          <Route path='/happy-hour/drink/create' element={<HappyDrinkCreate />} />
          <Route path='/happy-hour/food/update/:id' element={<HappyFoodUpdate />} />
          <Route path='/happy-hour/drink/update/:id' element={<HappyDrinkUpdate />} />
          <Route path='/game-day-special/drink/create' element={<GameDrinkCreate />} />
          <Route path='/game-day-special/drink' element={<GameSpacialDrink />} />
          <Route path='/game-day-special/food' element={<GameSpacialFood />} />
          <Route path='/game-day-special/food/create' element={<GameFoodCreate />} />
          <Route path='/game-day-special/food/update/:id' element={<GameFoodUpdate />} />
          <Route path='/game-day-special/drink/update/:id' element={<GameDrinkUpdate />} />
          <Route path='/special/create' element={<SpecialCreate />} />
          <Route path='/specials/:special/:type/' element={<Specials />} />
          <Route path='/communication' element={<Communication />} />
          <Route path='/profile-setting' element={<ProfileSetting  setRefresh={setRefresh}/>} />
          <Route path='/profile-membership' element={<UpgradeMembership />} />
          <Route path='/on-tap/drink' element={<OnTapDrink />} />
          <Route path='/on-tap/create' element={<OnTapCreate />} />
          <Route path='/on-tap/update/:liqType/:id' element={<OnTapUpdate />} />
          <Route path='/request-admin' element={<RequestAdmin />} />
        </Route>
      </Routes>
      <ToastContainer/>
    </div>
  );
}

export default App;

import { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import apiService from "../core/apiService";
import { useDispatch, useSelector } from "react-redux";
import { useCookies } from 'react-cookie';
export const ProtectedRoute = ({ children }) => {
  const [cookies] = useCookies(['userId', 'restaurantId']);
  const userId = cookies?.userId;
  const restaurantId = cookies?.restaurantId;
  const dispatch = useDispatch();
  const userData = useSelector(state => state.userData);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    if (!userData && userId) {
      apiService.get(`/get-user`, { id: userId }, ({ err, data }) => {
        if (err) return console.log(err);
        let userData = { ...data.data };
        apiService.get(`/get-restaurant`, { id: restaurantId }, ({ err, data }) => {
          if (err) return console.log(err);
          userData.restaurant = { ...data.data };
          dispatch({
            type: 'onLogin',
            payload: userData
          });
          apiService.get(`/restaurants/subscription/getpackage`, { restaurant_id: userData.restaurant?.id }, ({ err, data }) => {
            if (err) return console.log(err);
            dispatch({
              type: 'SET_SUBSCRIBE',
              payload: data.data
            })
            setLoading(false);
          })
        });
      })
    } else {
      setLoading(false);
    }
  }, []);


  if (!userId) {
    // user is not authenticated
    return <Navigate to="/" />;
  }
  return (
    loading ?
      <></> :
      children
  );
};
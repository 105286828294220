import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPencilAlt, faCircleNotch, faTimes, faCalendarAlt, faSearch } from "@fortawesome/free-solid-svg-icons";
import { Link } from 'react-router-dom';
import Filter from '../../assets/images/filter-ico.svg';
import apiService, { REACT_APP_LIMIT } from '../../core/apiService';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { useLocation, useNavigate } from "react-router-dom";
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css';
import { dropDownOption, getDays, getFoodCategory } from '../../helpers';

import Select from 'react-select';
import { Button } from 'react-bootstrap';


const HappyHourFood = () => {
	const [foodCategory, setFoodCategory] = useState([]);
	const [days, setDays] = useState([]);
	const restaurant = useSelector(state => state.restaurant);
	const [isFilter, setisFilter] = useState(false);
	const [dataLoading, setDataLoading] = useState(false);
	const [specialsLists, setSpecialsList] = useState(null);
	const [appliedFilters, setAppliedFilters] = useState({
		day_id: '',
		search: '',
		food_category_id: '',
		discount_type: '',
		discount_amount: '',
		item_description: '',
		start_time: '',
		end_time: ''
	});
	const [standard, setStandardTag] = useState('')
	const [listError, setListError] = useState();
	const navigate = useNavigate();
	const [currentPage, setCurrentPage] = useState(1)
	const limit = process.env.REACT_APP_LIMIT;
	const [total, setTotal] = useState([])


	console.log(specialsLists,'specialsListsspecialsLists')
	const showFilter = () => {
		setisFilter(prevState => !prevState);

		// If the filter is being opened, fetch data based on the current page
		if (!isFilter) {
			setCurrentPage(currentPage);
		getFoodCategory({
			callback: ({ err, data }) => {
				if (err) return console.log(err);
				setFoodCategory(data.data);
			}
		});
		getDays({
			callback: ({ err, data }) => {
				if (err) return console.log(err);
				setDays(data.data);
			}
		});
	}
	};

	const foodList = (args) => {
		setDataLoading(true);
		let params = {
			type_id: 1, special_id: 3, restaurant_id: restaurant?.id, pageno: currentPage,
			start_time: appliedFilters.start_time,
			end_time: appliedFilters.end_time,
			day_id: appliedFilters.day_id,
			discount_amount: appliedFilters.discount_amount,
			discount_type: appliedFilters.discount_type,

		};
		if (args?.filters) {
			params = { ...params, ...args.filters };
		}
		apiService.get(`/restaurants/specials`, params, async ({ err, data }) => {
			if (err) return setDataLoading(false), setListError(err.error), setSpecialsList([]), setisFilter(false);
			let temp = data
			await apiService.get(`/restaurants/get-foodcategory`, {}, ({ err, data }) => {
				if (err) return console.log(err);
				let temp1 = data
				apiService.get(`/listTags`, {}, ({ err, data }) => {

					if (err) return console.log(err);


					let filteredFoodCategories = []
					let filterTags = []
					temp.data.rows.map(item => {
						if (item.restauranttag?.foodcategory_ids == null) return

						let foodId = item.restauranttag?.foodcategory_ids
						console.log(foodId, 'foods')
						filteredFoodCategories = temp1.data.filter(food => eval(foodId).includes(food.id))
							.map(filteredFood => filteredFood.foodcategory_name);

							let tagId = item.restauranttag?.tags_ids
							filterTags = data.data.filter(tag => eval(tagId).includes(tag.id))
							.map(filteredTag => filteredTag.tag_name);

						item.Standard_Tags = filteredFoodCategories.join(',')
                        item.Special_tag = filterTags.join(',')
						console.log(filterTags, 'filetrs')

					});
					setSpecialsList(temp.data.rows);
				})

			});



			setTotal(data.data.count)
			setDataLoading(false);
			setisFilter(false);

		})
	}


	useEffect(() => {
		setDataLoading(true);
		foodList()
	}, [currentPage])	

	const updateData = (page) => {
		setCurrentPage(page)
	}
	const lastPage = Math.ceil(total / REACT_APP_LIMIT)

	const nextPage = () => {
		if (currentPage < lastPage) {
			updateData(currentPage + 1)
		}
	}

	const previousPage = () => {
		updateData(currentPage - 1)
	}
	const handleUpdate = (id) => {
		navigate(`/happy-hour/food/update/${id}`, { replace: true });
	}

	const handleDelete = (id) => {
		apiService.remove(`/restaurants/specials/food/${id}`, ({ err, data }) => {
			if (err) return setDataLoading(false), setListError(err.error);
			console.log("jdfd");
			setSpecialsList(specialsLists?.filter((i) => (i.id !== id)));
		});
	}

	const submitDelete = (id) => {

		confirmAlert({
			title: 'confirm to delete',
			message: 'Are you sure you want to delete?.',
			buttons: [
				{
					label: 'Yes',
					onClick: () => handleDelete(id)
				},
				{
					label: 'No',
					//onClick: () => alert('Click No')
				}
			]
		});
	};

	const resetFilters = () => {
		setAppliedFilters({
			day_id: '',
			search: '',
			food_category_id: '',
			discount_type: '',
			discount_amount: '',
			item_description: '',
			start_time: '',
			end_time: ''
		})
		foodList({
			filters: {
				day_id: '',
				search: '',
				start_date: '',
				end_date: '',
				food_category_id: '',
				discount_type: '',
				discount_amount: '',
				item_description: ''
			},
		});
	}


	return (
		<>
			<div className="dashboard-top-btn">
				<div className="table-filter">
					<button onClick={showFilter} className="btn btn-default filter-btn"><img alt="" src={Filter} />Filters</button>
				</div>
				<Link to="/happy-hour/food/create"><button className="btn btn-dark"><FontAwesomeIcon icon={faPencilAlt} /> Create</button></Link>
			</div>
			<div className="table-sec common-table margin-t30">
				<div className="table-responsive">
					<table className="table white-td-table">
						<thead>
							<tr>
								<th>Day</th>
								<th>Start Time</th>
								<th>End Time</th>
								<th>Food Category</th>
								<th>Discount Type</th>
								<th>Price</th>
								<th>Item Description</th>
								<th>Special Tags</th>
								<th>Standard Tags</th>
								<th>Action</th>
							</tr>
						</thead>
						<tbody className={specialsLists?.length > 0 ? '' : 'empty_row'}>
							{
								specialsLists == null  ?
									<tr>
										<td colSpan={10}>
											<div className='table-inner-loader'>
												<FontAwesomeIcon style={{ fontSize: 25 }} icon={faCircleNotch} spin />
											</div>
										</td>
									</tr> :
									specialsLists?.length ?
										specialsLists.map((item, i) =>
										(
											<tr>
												<td>{item.day?.day_name}</td>
												<td>{item.restaurantspecialshour?.start_time === "00:00:00" ? "00:00" : moment(item.restaurantspecialshour?.start_time, "HH:mm:ss").format("HH:mm")}</td>
												<td>{item.restaurantspecialshour?.end_time === "00:00:00" ? "23:59" : moment(item.restaurantspecialshour?.end_time, "HH:mm:ss").format("HH:mm")}</td>
												<td>{item.foodcategory?.foodcategory_name || 'Multiple Items'}</td>
												<td className='discount_type'>{item.discount_type}</td>
												<td>{item.discount_type === "percentage" ? item.discount_amount : '$' + item.discount_amount}</td>
												<td>{item.item_description}</td>
												<td>{item.Special_tag}</td>
												<td>{item.Standard_Tags}</td>
												<td className="table-action">
													<button className="btn btn-success"
														onClick={() => {
															handleUpdate(item.id);
														}}
													>Update</button>
													<button className="btn btn-danger"
														onClick={() => {
															submitDelete(item.id);
														}}
													>Delete</button>
												</td>
											</tr>
										)) :
										<tr>
											<td colspan="10">
												No records found!
											</td>
										</tr>
							}

						</tbody>
					</table>
				</div>
			</div>
			{isFilter === true ?
				<>
					<div className="popup-overlay show"></div>
					<div id="filter-pop" className='show'>
						<div className="filter-pop-inner">
							<div className="filter-head">
								<div className='filter-title'>
									<h4>Filters</h4>
									<button className='btn btn-link' onClick={() => {
										resetFilters();
										foodList();
									}}>All Records</button>
								</div>
								<button className="times-btn filter-close" onClick={showFilter}><FontAwesomeIcon icon={faTimes} /></button>
							</div>
							<div className="filter-body">
								<div className="mb-4">
									<div className="input-icon">
										<span className='input-icon-alt'><FontAwesomeIcon icon={faSearch} /></span>
										<input
											value={appliedFilters.search}
											onChange={(e) => setAppliedFilters({ ...appliedFilters, search: e.target.value })}
											type="text" className="form-control" placeholder="Type here..." />
									</div>
								</div>
								<div className="row mb-4">
									<div className="col-md-6">
										<div className="input-icon">
											<label className="form-label">Start Time</label>
											{/* <span className='input-icon-alt'><FontAwesomeIcon icon={faCalendarAlt} /></span> */}
											<input value={appliedFilters.start_time} onChange={(e) => setAppliedFilters({
												...appliedFilters,
												start_time: e.target.value
											})} type="time" className="form-control" placeholder="Start Time" />
										</div>
									</div>
									<div className="col-md-6">
										<div className="input-icon">
											<label className="form-label">End Time</label>
											{/* <span className='input-icon-alt'><FontAwesomeIcon icon={faCalendarAlt} /></span> */}
											<input value={appliedFilters.end_time} onChange={(e) => setAppliedFilters({
												...appliedFilters,
												end_time: e.target.value
											})} type="time" className="form-control" placeholder="Start Time" />
										</div>
									</div>
								</div>
								<div className='row mb-4'>
									<div className="col-md-6">
										<div className="form-group mb-4">
											<label className="form-label">Day</label>
											<select
												className="form-control"
												name="foodCat"
												value={appliedFilters.day_id}
												onChange={(e) => setAppliedFilters({ ...appliedFilters, day_id: e.target.value })}

											>
												<option disabled value=''>Select</option>
												{
													days?.length ?
														days?.map((day, i) => (
															<option value={day.id}>
																{day.day_name}
															</option>
														)) :
														<></>
												}
											</select>
										</div>
									</div>
									<div className='col-md-6'>
										<div className="form-group mb-4">
											<label className="form-label">Food Category</label>
											<select
												className="form-control"
												name="foodCat"
												value={appliedFilters.food_category_id}
												onChange={(e) => setAppliedFilters({ ...appliedFilters, food_category_id: e.target.value })}
											>
												<option disabled value=''>Select</option>
												{
													foodCategory?.length ?
														foodCategory?.map((food, i) => (
															<option value={food.id}>
																{food.foodcategory_name}
															</option>
														)) :
														<></>
												}
											</select>
										</div>
									</div>
								</div>
								<div className='row mb-4'>
									<div className='col-md-6'>
										<div className="form-group mb-4">
											<label className="form-label">Discount Type</label>
											<select
												className="form-control"
												name="foodCat"
												value={appliedFilters.discount_type}
												onChange={(e) => setAppliedFilters({ ...appliedFilters, discount_type: e.target.value })}
											>
												<option value="" disabled>Select</option>
												<option value="percentage">Percentage</option>
												<option value="price">Price</option>
												<option value="amount">Amount Off</option>
											</select>
										</div>
									</div>

									<div className='col-md-6'>
										<div className='form-group mb-4'>
											<label className="form-label">Price</label>
											<select
												value={appliedFilters.discount_amount}
												onChange={(e) => setAppliedFilters({ ...appliedFilters, discount_amount: e.target.value })}
												className="form-control"
												name="foodCat"
											>
												<option disabled value=''>Select</option>
												{
													dropDownOption()?.length ?
														dropDownOption()?.map((price, i) => (
															<option value={price.value}>
																{price.value}
															</option>
														)) :
														<></>
												}
											</select>
										</div>
									</div>
								</div>
							</div>
							<div className="filter-form-btns">
								<button className="btn btn-dark full-width-btn add-couple" onClick={() => {
									appliedFilters.pageno = 1;
									foodList({
									filters: appliedFilters
								});setSpecialsList(null)}}disabled={dataLoading}> 
								{
								  dataLoading ? <FontAwesomeIcon icon={faCircleNotch} spin /> : 'Apply Filter'
			  
								}</button>
							</div>
						</div>
					</div>
				</> :
				lastPage > 1 ?
					<>

						{<ul className="pagination justify-content-center">
							<li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
								<button className="page-link" onClick={previousPage}>
									Previous
								</button>
							</li>
							{Array.from({ length: lastPage }).map((_, index) => (
								<li key={index} className={`page-item ${currentPage === index + 1 ? 'active' : ''}`}>
									<button className="page-link" onClick={() => updateData(index + 1)}>
										{index + 1}
									</button>
								</li>
							))}
							<li className={`page-item ${currentPage === lastPage ? 'disabled' : ''}`}>
								<button className="page-link" onClick={nextPage}>
									Next
								</button>
							</li>
						</ul>
						}
					</> : <></>
			}
		</>
	);
};

export default HappyHourFood;
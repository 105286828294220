import apiService from "../core/apiService";
export const dropDownOption = () => {
    let data = [];

    for (let i = 0.05; i <= 100; i += 0.05) {
        var values = { label: i.toFixed(2), value: i.toFixed(2) };
        data.push(values);
    }

    return data;
};

export const perdropDownOption = () => {
    let data = [];

    for (let i = 1; i <= 100; i += 1) {
        var values = { label: i.toFixed(2), value: i.toFixed(2) };
        data.push(values);
    }

    return data;
};

export const getFoodCategory = ({ callback }) => {
    apiService.get('/restaurants/get-foodcategory', {}, ({ err, data }) => {
        if (err) return callback({ err });
        callback({ data });
    })
};


export const getDays = ({ callback }) => {
    apiService.get('/restaurants/get-days', {}, ({ err, data }) => {
        if (err) return callback({ err });
        callback({ data })
    }
    )
};


export const getSports = ({ callback }) => {
    apiService.get('/restaurants/get-sports', {}, ({ err, data }) => {
        if (err) return callback({ err });
        callback({ data })
    }
    )
};

export const getTeams = ({ callback }) => {
    apiService.get('/restaurants/get-sportsteam', {}, ({ err, data }) => {
        if (err) return callback({ err });
        callback({ data });
    }
    )
}

export const getDiscountTypes = ({ callback }) => {
    apiService.get('/discount-types', {}, ({ err, data }) => {
        if (err) return callback({ err });
        callback({ data });
    }
    )
}

export const getRegions = ({ callback }) => {
    apiService.get(`/restaurants/get-region`, {}, ({ err, data }) => {
        if (err) return callback({ err });
        callback({ data })
    });
};

export const getWineType = ({ callback }) => {
    apiService.get(`/restaurants/get-winetype`, {}, ({ err, data }) => {
        if (err) return callback({ err });
        callback({ data });
    });
};

export const getWineSubType = ({ callback }) => {
    apiService.get(`/restaurants/get-winesubtype`, {}, ({ err, data }) => {
        if (err) return callback({ err });
        callback({ data });
    });
}

export const getContainer = ({ callback }) => {
    apiService.get(`/restaurants/get-container`, {}, ({ err, data }) => {
        if (err) return callback({ err });
        callback({ data });
    });
}

export const getSize = ({ callback }) => {
    apiService.get(`/restaurants/get-size`, {}, ({ err, data }) => {
        if (err) return callback({ err });
        callback({ data });
    });
}
import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt, faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import logo from '../../assets/images/logo.png';
import poweredByGoogle from '../../assets/images/powered-by-google.png';
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import apiService from '../../core/apiService';
import { useDispatch, useSelector } from 'react-redux';
import { OtpInput } from 'react-otp-input';
import { toast } from 'react-toastify';
import { confirmAlert } from 'react-confirm-alert';
import { Button } from 'react-bootstrap';
import CustomError from '../../components/CustomError';
import CustomSuccess from '../../components/CustomSuccess';


const BusinessVerify = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const { search } = useLocation();
  const promocode = new URLSearchParams(search).get('promoCode');
  const restaurant = useSelector(state => state.restaurant);
  const fromPage = location.state?.from?.pathname || '/membership';
  const [value, setValue] = useState(null);
  const [coordinates, setCoordinates] = useState(null);
  const [placeId, setPlaceId] = useState(null);
  // const step= useState(useSelector(state => state.step))
  // const dispatch = useDispatch();
  const [step, setStep] = useState(window.localStorage.getItem('step'))
  const apiKey = 'AIzaSyCnTxLr5HmtfFxwlFqiLuqnaUKRPhyTFZ8';

  // Replace with your actual API key
  const state = useSelector(state => state)

  const [otp, setOtp] = useState('');


  console.log(value, "valuevalue")
  const handleSubmit = (e) => {
    e.preventDefault(); // Always prevent default form submission

    confirmAlert({
      title: 'Confirm for Address',
      message: 'Are you sure you have entered the right address?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            setLoading(true);
            const data = {
              'restaurant_address_line1': value?.value?.description || '',
              'restaurant_id': restaurant?.id || '',
              ...coordinates
            };

            if (!data.restaurant_address_line1) {
              setLoading(false);
              return;
            }

            // Reset coordinates and placeId before the new search
            setCoordinates(null);
            setPlaceId(null);

            apiService.post('/restaurants/restaurant-location', data, ({ err, data }) => {
              if (err) return console.log(err);
              setLoading(false);
              setTimeout(() => {
                if (promocode) {
                  navigate(`${fromPage}?promoCode=${promocode}`, { replace: true });
                } else {
                  CustomSuccess('Hey great! you have been verified','Success')
                  navigate(fromPage, { replace: true });
                }
              }, 3000);
            });
          },
        },
        {
          label: 'No',
          // onClick: () => alert('Click No')
        }
      ]
    });
  };

  // ... (Your other components remain unchanged)


  useEffect(() => {
    // Load the Google Maps JavaScript API
    const script = document.createElement('script');
    script.src = `https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=places`;
    script.async = true;
    script.defer = true;
    document.body.appendChild(script);

    script.onload = () => {
      // Initialize the Places Service
      const placesService = new window.google.maps.places.PlacesService(document.createElement('div'));

      // Request details for the specified place ID
      placesService.getDetails({ placeId: placeId, fields: ['geometry'] }, (place, status) => {
        if (status === 'OK') {

          const restaurant_latitude = place.geometry.location.lat();
          const restaurant_logitude = place.geometry.location.lng();
          setCoordinates({ restaurant_latitude, restaurant_logitude });
        } else {
          console.error('Error fetching place details:', status);
        }
      });
    };

    // Cleanup the script element on unmount
    return () => {
      document.body.removeChild(script);
    };
  }, [apiKey, placeId]);

  useEffect(() => {
    setPlaceId(value?.value?.place_id);
  }, [value]);



  const inputs = document.querySelectorAll(".otp-field input");

  inputs.forEach((input, index) => {
    input.dataset.index = index;
    input.addEventListener("keyup", handleOtp);
    input.addEventListener("paste", handleOnPasteOtp);
  });

  function handleOtp(e) {
    /**
     * <input type="text" 👉 maxlength="1" />
     * 👉 NOTE: On mobile devices `maxlength` property isn't supported,
     * So we to write our own logic to make it work. 🙂
     */
    const input = e.target;
    let value = input.value;
    let isValidInput = value.match(/[0-9a-z]/gi);
    input.value = "";
    input.value = isValidInput ? value[0] : "";

    let fieldIndex = input.dataset.index;
    if (fieldIndex < inputs.length - 1 && isValidInput) {
      input.nextElementSibling.focus();
    }

    if (e.key === "Backspace" && fieldIndex > 0) {
      input.previousElementSibling.focus();
    }

    if (fieldIndex == inputs.length - 1 && isValidInput) {
      submit();
    }
  }

  function handleOnPasteOtp(e) {
    const data = e.clipboardData.getData("text");
    const value = data.split("");
    if (value.length === inputs.length) {
      inputs.forEach((input, index) => (input.value = value[index]));

    }
  }
  function onResend() {


    apiService.get(`/resendOtp/${restaurant?.id}`, {}, ({ err, data }) => {
    
      if (err) return console.log(err);
      CustomError('OTP sent to your mail','')
    })
 

  }
  function submit(e) {
    e.preventDefault()

    // 👇 Entered OTP
    let otp = "";
    inputs.forEach((input) => {
      otp += input.value;
      // input.disabled = true;
      // input.classList.add("disabled");
    });

    if (!otp) {
      // toast.error('Please enter your OTP', {
      //   position: "top-center",
      //   autoClose: 5500,
      //   hideProgressBar: false,
      //   closeOnClick: true,
      //   pauseOnHover: false,
      //   theme: "light",
      // });
      CustomError('Please enter your OTP','')
      return
    }
    setLoading(true);
    apiService.post(`/verifyOtp/${restaurant?.id}`, { otp: otp }, ({ err, data }) => {

      if (err) {
        console.log(err);
        setLoading(false);
        return;
      }

      console.log(data, "data");
      if (data.success == true) {
        CustomSuccess('Hey great! you have registered successfully','Success')
        console.log(data, "data inside if ");

        window.localStorage.setItem("step", 2);
        setStep(2)
        // dispatch({ type: "onStep", payload:2 });

      }
      setLoading(false)
      // setStep(2)


    });

    // 👉 Call API below

  }

  console.log(restaurant,"restaurant")


  return (
    <div>

      <div id="login-page">
        {step == 1 ? <>  <div className="login-form">
          <div className="login-form-inner">
            <div className="login-logo">
              <img src={logo} alt="Whatson" />

            </div>
            <div className="login-form-box">
              <div className="otp-field">
                <input type="text" maxlength="1" />
                <input type="text" maxlength="1" />
                <input type="text" maxlength="1" />
                <input type="text" maxlength="1" />
              </div>


              <form>
                <div className="mb-2 d-flex align-items-center justify-content-center">

                  <button disabled={loading} onClick={submit} className="btn btn-dark signin-btn send-otp w-70">
                    {
                      loading ? <FontAwesomeIcon icon={faCircleNotch} spin /> : 'Verify OTP'
                    }
                  </button>
                </div>
                <div className="mt-4  d-flex align-items-center justify-content-center graph-set">

                  <p>OTP sent to : <b> {restaurant.restaurant_email}</b> </p>   
                </div>
                <div className="mt-0 d-flex align-items-center justify-content-center graph-sets">

                  <p> Check your Junk/ spam mail folder for One Time Password</p>   
                </div>

                <div className="form-bottom-link  otp-verification-link"  >Didn't receive code? <Link to='#' onClick={onResend} >
                <b>
                  
                     Resend OTP
                  
                  
                  </b></Link></div>


              </form>
            </div>
          </div>
        </div></>

          : <div className="login-form">
            <div className="login-form-inner">
              <div className="login-logo">
                <img src={logo} alt="Whatson" />
                <p>Business Verification</p>
              </div>
              <div className="login-form-box">
                <form>
                  <div className="mb-4">
                    <label>Enter Your Business</label>
                    <div className="input-icon">
                      <span className='input-icon-alt'><FontAwesomeIcon icon={faMapMarkerAlt} /></span>
                      <GooglePlacesAutocomplete
                        debounce={800}
                        apiKey={apiKey}
                        autocompletionRequest={{
                          componentRestrictions: {
                            country: ['ca'],
                          }
                        }}
                        selectProps={{
                          value,
                          onChange: setValue,
                          placeholder: "Search",
                          styles: {
                            input: (provided) => ({
                              ...provided,
                              padding: '10 0',
                            }),
                          }
                        }}
                        onLoadFailed={(error) => (
                          console.error("Could not inject Google script", error)
                        )}
                      />

                      <img alt="" src={poweredByGoogle} />
                      <div className='clear-input'>
                        <button type='button' className="mt-3 btn btn-dark clear-btn " onClick={() => {
                          setValue(null)
                        }
                        }>Clear</button></div>
                    </div>
                  </div>

                  {/* <div className="mb-4 forgot-password">
                    <label className="custom-check check-without-input"> If you are not registered with Google My Business Please <Link to="/alternate-verify-business"><b> Click here!</b></Link>
                    </label>
                  </div> */}
                  <div className="mb-3">
                    <button onClick={handleSubmit} disabled={loading} className="btn btn-dark full-btn btn-lg signin-btn">
                      {
                        loading ? <FontAwesomeIcon icon={faCircleNotch} spin /> : 'Next'
                      }
                    </button>
                    {/* <div className="mb-4 forgot-passwords">
                    <label className="mt-3 custom-check check-without-input"> If you are having issues finding your address using google verification or if service is not working, please <Link to="/alternate-verify-business"><b> Click here!</b></Link> and proceed to next step. 
                    </label>
                    </div> */}
                     <div className="mb-4 forgot-passwords">
                    <label className="mt-3 custom-check check-without-input"> If you don’t have a Google My Business account or the Google verification fails, please <Link to="/alternate-verify-business"><b> Click here!</b></Link>  and proceed to the next step 
                    </label>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>}








        <div className="login-img">
          <div className="login-img-inner">
            <div className="dashboard-title">
              <h3>WELCOME TO</h3>
              <h1>Restaurant Panel</h1>
              <p>Register to Access Dashboard</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BusinessVerify;


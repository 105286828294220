import React from 'react';
import { Outlet } from "react-router-dom";
import '../assets/css/components.css';
import '../assets/css/login.css';
import 'bootstrap/dist/css/bootstrap.min.css';

const LoginLayout = () => {

    return (
        <>        
      <Outlet/>
      </>
    );
};

export {LoginLayout};

import React, { useEffect, useState, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import apiService from '../../core/apiService';
import { useSelector } from 'react-redux';
import Select from 'react-select';
import {
	dropDownOption
} from "../../helpers";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import Multiselect from "multiselect-react-dropdown";
import { confirmAlert } from 'react-confirm-alert';
import CustomError from '../../components/CustomError';
const OnTapCreate = () => {
	const restaurant = useSelector(state => state.restaurant);
	const [Step, setStep] = useState(0);
	const [createdSuccess, setcreatedSuccess] = useState(false);
	const [drinkNameText, setDrinkNameText] = useState('');
	const navigate = useNavigate();
	const location = useLocation();
	const [sizeList, setSizeList] = useState([]);
	const [isFormTouched, setIsFormTouched] = useState(false);
	const fromPage = location.state?.from?.pathname || '/on-tap/drink';
	const createPage = location.state?.from?.pathname || '/on-tap/drink';
	const [liquorType, setliquorType] = useState("1");
	const [drinkList, setDrinkList] = useState([]);
	const [containerList, setContainerList] = useState([]);
	const [regionList, setRegionList] = useState([]);
	const [wineTypeList, setwineTypeList] = useState([]);
	const [wineSubTypeList, setwineSubTypeList] = useState([]);
	// const [discountType, setDiscountType] = useState("");
	// const [discountPrice, setDiscountPrice] = useState("");
	const [description, setDescription] = useState("");
	const [winename, setWine] = useState(0)
	const [region, setRegion] = useState(0);
	const [winetype, setWineType] = useState(0);
	const [winesubtype, setWineSubType] = useState(0);
	const [container, setContainer] = useState(0);
	const [special, setSelectedSpecials] = useState([]);
	const [size, setSize] = useState(0);
	const [drinkname, setDrinkname] = useState("1");
	const [drinknameError, setDrinkNameError] = useState("");
	const [drinknameErrorr, setDrinkNameErrorr] = useState("");
	const [isLoading, setIsLoading] = useState(false);
	const [wineTypes, setMasterType] = useState([])
	const [typeShow, setTypeShow] = useState(true);
	const [drinkLists, setDrinkLists] = useState([]);
	const [drinkData, setDrinkData] = useState([])
	const [standared, setSelectedStanderd] = useState([])
	const special_id = '4';
	const type_id = '2';
	const Priceoption = dropDownOption();
	const [drink, setDrink] = useState([])

	console.log(drinkLists, 'drinkListsdrinkLists')
	const nextStep = () => {
		setStep(Step + 1);
	};
	const backStep = () => {
		setStep(Step - 1);
	};

	const changeliquorType = (e) => {
		setliquorType(e.target.value);
	};

	const back = () => {
		navigate(createPage, { replace: true })
	};



	useEffect(() => {
		// apiService.get(`/restaurants/drinkTypes`, {}, ({ err, data }) => {
		// 	if (err) return console.log(err);

		// 	setDrinkList(data.data);

		// 	{
		// 		data.data.length ?
		// 			data.data
		// 				.filter(drink => drink.id == `${liquorType}`)
		// 				.map((drink, i) => (
		// 					drink.drinknames.map((drinkname, i) => (
		// 						console.log(drinkname?.id, "drinkname?.id")
		// 						//setDrinkname(drinkname?.id);
		// 					))
		// 				)) : <></>
		// 	}
		// });
		apiService.get(`/restaurants/drinkTypes`, {}, ({ err, data }) => {
			console.log(data.data, 'dtadtadt')
			if (err) return console.log(err);
			setDrinkList(data.data);
		});
		apiService.get(`/restaurants/get-container`, {}, ({ err, data }) => {
			if (err) return console.log(err);
			setContainerList(data.data);
		});
		apiService.get(`/restaurants/get-region`, {}, ({ err, data }) => {
			if (err) return console.log(err);
			setRegionList(data.data);
		});
		apiService.get(`/restaurants/get-winetype`, {}, ({ err, data }) => {
			if (err) return console.log(err);
			setwineTypeList(data.data);
		});
		apiService.get(`/restaurants/get-winesubtype`, {}, ({ err, data }) => {
			if (err) return console.log(err);
			setwineSubTypeList(data.data);
		});

		apiService.get(`/listMasterWine`, {}, ({ err, data }) => {
			if (err) return console.log(err);
			data.data.splice(data.data.length - 1, 1)
			setMasterType(data.data);
		});
	}, []);

	useEffect(() => {
		// apiService.get(`/listDrinkTags`, { drink_type: liquorType }, ({ err, data }) => {
		// 	if (err) return console.log(err);

		// 	let res = [];
		// 	data.data?.map((item) => {
		// 		const temp = {};
		// 		temp["label"] = `${item["tag_name"]}`;
		// 		temp["value"] = item["id"];
		// 		// res.push(temp);
		// 		setDrinkData(temp);
		// 	});



		// });
		apiService.get(`/listDrinkNames`, { drink_type: liquorType }, ({ err, data }) => {
			// let Name = ""
			// if (liquorType == 1) Name = "Multiple Wines"
			// if (liquorType == 2) Name = "Multiple Beers"
			// if (liquorType == 3) Name = "Multiple Cider"
			// if (liquorType == 4) Name = "Multiple Others"
			if (err) return console.log(err);

			let temp = data.data?.map((drinkname) => ({
				value: drinkname.id,
				label: drinkname.drink_name,
			}))

			setDrinkLists(temp);
			let res = [];
			// data.data?.map((item) => {
			// 	console.log(item, 'itemmms1')
			// 	const temp = {};
			// 	temp["label"] = `${item["drink_name"]}`;
			// 	temp["value"] = item["id"];
			// 	res.push(temp);
			// });
			setDrink(res)

		});

	}, [liquorType])

	const handleOnchangeSpecials = (val) => {

		setSelectedSpecials(val);

	};
	const handleOnchangeStand = (val) => {

		setSelectedStanderd(val);

	};

	const handleSubmit = () => {
		setIsFormTouched(true);
		let sizing = sizeList?.filter(item => item.container_id == container && item.Drink_type_id == liquorType)
		if ((sizing.length > 0 && !size) && parseInt(liquorType) !== 4) {

			return
		};
		let temp = null
		if (!sizing.length) {
			temp = 0
		}

		console.log(standared.length, 'length')

		console.log(special.length, 'specialspecial')
		let drink_ids = {}
		if (standared.length == 0) {
			drink_ids = { "drink_ids": JSON.stringify([drinkname]), tags_ids: JSON.stringify([...special.map(item => item.value)]) }
		} else
			drink_ids = { "drink_ids": JSON.stringify([...standared.map(item => item.value)]), tags_ids: JSON.stringify([...special.map(item => item.value)]) }
		console.log(drink_ids, "chekci")
		if (liquorType === '4' && drinkname < 1) return setDrinkNameError('Drink Name is required!');

		let data = {}
		if (liquorType === "1") {
			data = {
				restaurant_id: restaurant?.id,
				special_id: special_id,
				type_id: type_id,
				drinktype_id: liquorType,
				drink_name: drinkNameText,
				region_id: region,
				wine_subtype_id: winesubtype,
				wine_type_id: winetype,
				container_type_id: container,
				// 'discount_type': discountType,
				// 'discount_amount': discountPrice,
				item_description: description,
				masterwine_id: winename
			};
		} else {
			data = {
				restaurant_id: restaurant?.id,
				special_id: special_id,
				type_id: type_id,
				drinktype_id: liquorType,
				drink_name: drinkNameText,
				region_id: region,
				container_type_id: container,
				// 'discount_type': discountType,
				// 'discount_amount': discountPrice,
				item_description: description,
				drink_name_id: drinkname,
				// data1: drink_ids
			}
		}
		console.log(data, "datdadatda")

		if (description && ((liquorType === "1" || liquorType === '4') || drinkname > 0)) {
			if (((liquorType === "2" || liquorType === "3") && container > 0) || liquorType === '1' || liquorType === "4") {
				if (liquorType == 1) {
					if (!(winetype > 0 && winename > 0 && winesubtype > 0)) {
						CustomError('Please fill all the required fields', '')
						// toast.error('Please fill all the required fields ', {
						// 	position: "top-center",
						// 	autoClose: 5500,
						// 	hideProgressBar: false,
						// 	closeOnClick: true,
						// 	pauseOnHover: false,
						// 	theme: "light",
						// });
						return
					}
				}
				setIsLoading(true)
				apiService.post("/restaurants/specials/create", data, ({ err, data }) => {
					if (err) {
						console.log(err);
						setIsLoading(false)
					}
					setIsLoading(false)
					setcreatedSuccess(createdSuccess === false ? true : false);
					setTimeout(() => {
						navigate(`/on-tap/drink?type=${liquorType}`, { replace: true });
					}, 5000);
				});
			}
			else
				CustomError('Please fill all the required fields', '')

		}
		else
			CustomError('Please fill all the required fields', '')
		// confirmAlert({
		// 	customUI: ({ onClose }) => {
		// 	  return (
		// 		<div className='custom-ui-error'>

		// 		  <h1>Error</h1>
		// 		  <p>Please fill all the required fields</p>				
		// 		  <div class="react-confirm-alert-button-group"
		// 			>

		// 			<button onClick={() => {

		// 			  onClose();
		// 			}} label="OK">OK</button>
		// 		  </div>
		// 		</div>
		// 	  );
		// 	}
		//   })
		// toast.error('Please fill all the required fields ', {
		// 	position: "top-center",
		// 	autoClose: 5500,
		// 	hideProgressBar: false,
		// 	closeOnClick: true,
		// 	pauseOnHover: false,
		// 	theme: "light",
		// });

	};


	return (
		<>
			<div className="dashboard-auto-wrapper">
				<div className="create-from">
					{Step === 0 &&
						<div className="create-from-steps active">
							<h4>Please Select Type</h4>
							<div className="create-form-inner mt-4">
								<div className="form-group mb-4">
									<label className="form-label">Type <span className="text-danger">*</span> </label>
									<select value={liquorType} onChange={changeliquorType} className="form-control">
										{drinkList.length ? (
											drinkList.map((drink, i) => (
												<option value={drink.id}>
													{drink.drinktype_name}
												</option>
											))
										) : (
											<></>
										)}
									</select>
								</div>
							</div>
							<div className="create-from-btn mt-5">
								<button className="btn btn-transparent" onClick={back}>Back</button>
								<button className="btn btn-dark" onClick={nextStep}>Next</button>
							</div>
						</div>
					}
					{Step === 1 && liquorType === '1' &&
						<div className="create-from-steps active">
							<h4>Wine</h4>
							<div className="create-form-inner mt-4">
								<div className="form-group mb-4">
									<label className="form-label">Region </label>
									<select
										className="form-control"
										onChange={(e) => setRegion(e.target.value)}
									>
										<option value='0'>Select </option>
										{regionList.length ? (
											regionList.map((region, i) => (
												<option value={region.id}>{region.region_name}</option>
											))
										) : (
											<></>
										)}
									</select>
								</div>
								<div className="form-group mb-4">
									<label className="form-label">Red/White<span className="text-danger">*</span></label>
									<select
										className="form-control"
										onChange={(e) => {
											setWine(e.target.value)
											// {
											// 	e.target.value !== "10"
											// 		? setTypeShow(true)
											// 		: setTypeShow(false); setWineSubType(0)
											// }
										}}

									>

										<option value=''>Select </option>
										{wineTypes.length ? (
											wineTypes.map((wine, i) => (
												<option value={wine.id}>{wine.wine_type_name}</option>
											))
										) : (
											<></>
										)}

									</select>

								</div>
								{typeShow === true ? (
									<div className="form-group mb-4">
										<label className="form-label">Type <span className="text-danger">*</span></label>

										<select
											className="form-control"
											onChange={(e) => setWineType(e.target.value)}
										>
											<option value=''>Select </option>

											{wineTypeList?.length ? (
												wineTypeList
													.filter(drink => drink.masterwine_id == winename).map((wine, i) => (
														<option value={wine.id}>{wine.wine_name}</option>
													))
											) : (
												<></>
											)}
										</select>
										{/* {wineSubTypeList.length ? (
												wineSubTypeList.map((winetype, i) => (
													<option value={winetype.id}>
														{winetype.winesubtype_name}
													</option>
												))
											) : (
												<></>
											)}
										</select> */}

									</div>
								) : (
									<></>
								)}
								<div className="form-group mb-4">
									<label className="form-label">Name <span className="text-danger">*</span></label>
									<Select
										classNamePrefix="form-control"

										onChange={(selectedOption) => setWineSubType(selectedOption.value)}
										options={wineSubTypeList.map((winetype) => ({
											value: winetype.id,
											label: winetype.winesubtype_name,
										}))}
									/>			{/* <select
										className="form-control"
										onChange={(e) => setWineSubType(e.target.value)}
									>
										<option value=''>Select </option>
										{wineSubTypeList.length ? (
											wineSubTypeList.map((winetype, i) => (
												<option value={winetype.id}>
													{winetype.winesubtype_name}
												</option>
											))
										) : (
											<></>
										)}
									</select> */}
									{/* {
											(isFormTouched && !winesubtype) && <span className="error">Name is required!</span>

										} */}
									{/* <span className="error">{drinknameErrorr}</span> */}
								</div>
								{/* <div className="form-group mb-4">
									<label className="form-label">Price <span className="text-danger">*</span></label>
									{/* <div className="form-group d-flex align-items-center flex-row mb-2">
										<label className="custom-radio mb-0">
											Percentage
											<input
												type="radio"
												name="discountType"
												onChange={(e) => setDiscountType("percentage")}
											/>
											<span className="checkmark"></span>
										</label>
										<label className="custom-radio ms-3 mb-0">
											Price
											<input
												type="radio"
												name="discountType"
												onChange={(e) => setDiscountType("price")}
											/>
											<span className="checkmark"></span>
										</label>
										<label className="custom-radio ms-3 mb-0">
											Amount Off
											<input
												type="radio"
												name="discountType"
												onChange={(e) => setDiscountType("amount")}
											/>
											<span className="checkmark"></span>
										</label>
									</div> */}
								{/* {
										(isFormTouched && !discountType) && <span className="error">Discount Type is required!</span>
									} */}
								{/* <Select
										classNamePrefix="form-control"

										name="discountType"
										// onChange={(e) => setDiscountType("percentage")}
										onChange={(e) => setDiscountPrice(e.value)}
										options={Priceoption}
										value={Priceoption.filter(function (option) {
											return option.value === discountPrice;
										})}
									/> */}
								{/* {
										(isFormTouched && !discountPrice) && <span className="error">Discount Amount is required!</span>
									} */}
								{/* </div> */}
								<div className="form-group mb-4">
									<label className="form-label">Description (255 characters)<span className="text-danger">*</span></label>
									<textarea maxLength={255}
										className="form-control"
										name="description"
										value={description}
										onChange={(e) => setDescription(e.target.value)}
									/>
									{/* {
										(isFormTouched && !description) && <span className="error">Description is required!</span>
									} */}
								</div>
							</div>
							<div className="create-from-btn mt-5">
								<button className="btn btn-transparent" onClick={backStep}>Back</button>
								<button className="btn btn-dark" onClick={handleSubmit} disabled={isLoading} >
									{
										isLoading ? <FontAwesomeIcon icon={faCircleNotch} spin /> : 'Save'

									}</button>
							</div>
						</div>
					}
					{Step === 1 && liquorType === '2' &&
						<div className="create-from-steps active">
							<h4>Beer</h4>
							<div className="create-form-inner mt-4">
								<div className="form-group mb-4">

									<label className="form-label">Name <span className="text-danger">*</span></label>
									{/* <Select
										classNamePrefix="form-control"

										onChange={(selectedOption) => setDrinkname(selectedOption.value)}
										options={drinkList?.length ? (drinkList
											.filter((drink) => drink.id == liquorType)
											.map((drink) =>
												drink.drinknames.map((drinkname) => ({
													value: drinkname.id,
													label: drinkname.drink_name,
												}))
											)
											.flat()) : (<></>)}

									/> */}
									{/* <select className="form-control" value={drinkname} onChange={(e) => setDrinkname(e.target.value)}>
										<option value=''>Select </option>
										{drinkList.length ? (
											drinkList
												.filter(drink => drink.id == liquorType)
												.map((drink, i) => (
													drink.drinknames.map((drinkname, i) =>
													(
														<option value={drinkname?.id}>
															{drinkname?.drink_name}
														</option>
													))
												))
										) : (
											<></>
										)}
									</select> */}
									<Select
										classNamePrefix="form-control"
										onChange={(selectedOption) => setDrinkname(selectedOption.value)}
										options={drinkLists}
									/>
									<span className="error">{drinknameErrorr}</span>
									<div className="d-flex align-items-center  graph-sets mt-2">

										<p> <b>Note:</b> Don’t see your option here? Submit a request to admin.</p>
									</div>
								</div>
								<div className="form-group mb-4">
									<label className="form-label">Container Type <span className="text-danger">*</span></label>
									<select
										className="form-control"
										onChange={(e) => {
											setContainer(e.target.value);
											// {
											//   e.target.value === "6"
											//     ? setSizeShow(true)
											//     : setSizeShow(false); setSize(0)
											// }
										}}
									>
										<option value=''>Select </option>
										{containerList.length ? (
											containerList?.filter(item => item.Drink_type_id == liquorType).map((container, i) => (
												<option value={container.id}>
													{container.container_name}
												</option>
											))
										) : (
											<></>
										)}
									</select>
								</div>
								{/* {/* <div className="form-group mb-4"> */}
								{/* <label className="form-label">Price <span className="text-danger">*</span></label> */}
								{/* <div className="form-group d-flex align-items-center flex-row mb-2">
										<label className="custom-radio mb-0">
											Percentage
											<input
												type="radio"
												name="discountType"
												onChange={(e) => setDiscountType("percentage")}
											/>
											<span className="checkmark"></span>
										</label>
										<label className="custom-radio ms-3 mb-0">
											Price
											<input
												type="radio"
												name="discountType"
												onChange={(e) => setDiscountType("price")}
											/>
											<span className="checkmark"></span>
										</label>
										<label className="custom-radio ms-3 mb-0">
											Amount Off
											<input
												type="radio"
												name="discountType"
												onChange={(e) => setDiscountType("amount")}
											/>
											<span className="checkmark"></span>
										</label>
									</div> */}
								{/* {
										(isFormTouched && !discountType) && <span className="error">Discount Type is required!</span>
									} */}
								{/* <Select
										classNamePrefix="form-control"

										name="discountType"
										// onChange={(e) => setDiscountType("percentage")}
										onChange={(e) => setDiscountPrice(e.value)}
										options={Priceoption}
										value={Priceoption.filter(function (option) {
											return option.value === discountPrice;
										})}
									/> */}
								{/* {
										(isFormTouched && !discountPrice) && <span className="error">Discount Amount is required!</span>
									} */}
								{/* </div> } */}
								<div className="form-group mb-4">
									<label className="form-label">Description (255 characters)<span className="text-danger">*</span></label>
									<textarea maxLength={255}
										className="form-control"
										name="description"
										value={description}
										onChange={(e) => setDescription(e.target.value)}
									/>
									{/* {
										(isFormTouched && !description) && <span className="error">Description is required!</span>
									} */}
								</div>
								{/* <div className="col-md-12 mb-4">
									<div className="form-group">
										<div className="input-right-icon">
											<label className="form-label">Special Tags</label>
											<Multiselect
												placeholder="Select Special Tags"
												options={drinkData}
												displayValue="label"
												onSelect={handleOnchangeSpecials}
												onRemove={(selectedList, removedItem) => {
													setSelectedSpecials(selectedList)
												}}
											/>
										</div>
									</div>
								</div> */}

								{/* <div className="col-md-12">
									<div className="form-group">
										<div className="input-right-icon">
											<label className="form-label">Standard Tags</label>
											<Multiselect
												placeholder="Select Beer Tags"
												options={drink.filter((item => item.value == drinkname))?.length ? drink.filter((item => item.value == drinkname)) : drink}
												// options={food.filter((item => item.value == foodCat))?.length ? food.filter((item => item.value == foodCat)) : food}
												onSelect={handleOnchangeStand}
												disablePreSelectedValues={!!drink.filter((item => item.value == drinkname)).length}
												displayValue="label"
												selectedValues={drink.filter((item => item.value == drinkname))}
												onRemove={(selectedList, removedItem) => {
													setSelectedStanderd(selectedList)
												}}
											/>
										</div>
									</div>
								</div> */}
							</div>
							<div className="create-from-btn mt-5">
								<button className="btn btn-transparent" onClick={backStep}>Back</button>
								<button className="btn btn-dark" onClick={handleSubmit} disabled={isLoading} >
									{
										isLoading ? <FontAwesomeIcon icon={faCircleNotch} spin /> : 'Save'

									}</button>
							</div>
						</div>
					}
					{Step === 1 && liquorType === '3' &&
						<div className="create-from-steps active">
							<h4>Cider</h4>
							<div className="create-form-inner mt-4">
								<div className="form-group mb-4">
									<label className="form-label">Name <span className="text-danger">*</span></label>
									{/* <Select
										classNamePrefix="form-control"

										onChange={(selectedOption) => setDrinkname(selectedOption.value)}
										options={drinkList?.length ? (drinkList
											.filter((drink) => drink.id == liquorType)
											.map((drink) =>
												drink.drinknames.map((drinkname) => ({
													value: drinkname.id,
													label: drinkname.drink_name,
												}))
											)
											.flat()) : (<></>)}

									/> */}




									{/* {drinkList.length ? (
											drinkList
												.filter(drink => drink.id == liquorType)
												.map((drink, i) => (
													drink.drinknames.map((drinkname, i) =>
													(
														<option value={drinkname?.id}>
															{drinkname?.drink_name}
														</option>
													))
												))
										) : (
											<></>
										)} */}
									<Select
										classNamePrefix="form-control"

										onChange={(selectedOption) => setDrinkname(selectedOption.value)}
										options={drinkLists}

									/>
									<span className="error">{drinknameErrorr}</span>
									<div className="d-flex align-items-center  graph-sets mt-2">

										<p> <b>Note:</b> Don’t see your option here? Submit a request to admin.</p>
									</div>

								</div>
								<div className="form-group mb-4">
									<label className="form-label">Container Type <span className="text-danger">*</span></label>
									<select className="form-control" onChange={(e) => setContainer(e.target.value)}>
										<option value=''>Select </option>
										{containerList.length ? (
											containerList?.filter(item => item.Drink_type_id == liquorType)?.map((container, i) => (
												<option value={container.id}>
													{container.container_name}
												</option>
											))
										) : (
											<></>
										)}
									</select>
								</div>
								{/* <div className="form-group mb-4">
									<label className="form-label">Price <span className="text-danger">*</span></label>
									{/* <div className="form-group d-flex align-items-center flex-row mb-2">
										<label className="custom-radio mb-0">
											Percentage
											<input
												type="radio"
												name="discountType"
												onChange={(e) => setDiscountType("percentage")}
											/>
											<span className="checkmark"></span>
										</label>
										<label className="custom-radio ms-3 mb-0">
											Price
											<input
												type="radio"
												name="discountType"
												onChange={(e) => setDiscountType("price")}
											/>
											<span className="checkmark"></span>
										</label>
										<label className="custom-radio ms-3 mb-0">
											Amount Off
											<input
												type="radio"
												name="discountType"
												onChange={(e) => setDiscountType("amount")}
											/>
											<span className="checkmark"></span>
										</label>
									</div> */}
								{/* {
										(isFormTouched && !discountType) && <span className="error">Discount Type is required!</span>
									} */}
								{/* <Select
										classNamePrefix="form-control"

										name="discountType"
										// onChange={(e) => setDiscountType("percentage")}
										onChange={(e) => setDiscountPrice(e.value)}
										options={Priceoption}
										value={Priceoption.filter(function (option) {
											return option.value === discountPrice;
										})}
									/> */}
								{/* {
										(isFormTouched && !discountPrice) && <span className="error">Discount Amount is required!</span>
									} */}
								{/* </div> } */}
								<div className="form-group mb-4">
									<label className="form-label">Description (255 characters)<span className="text-danger">*</span></label>
									<textarea maxLength={255}
										className="form-control"
										name="description"
										value={description}
										onChange={(e) => setDescription(e.target.value)}
									/>
									{/* {
										(isFormTouched && !description) && <span className="error">Description is required!</span>
									} */}
								</div>
								{/* <div className="col-md-12 mb-4">
									<div className="form-group">
										<div className="input-right-icon">
											<label className="form-label">Special Tags</label>
											<Multiselect
												placeholder="Select Special Tags"
												options={drinkData}
												displayValue="label"
												onSelect={handleOnchangeSpecials}
												onRemove={(selectedList, removedItem) => {
													setSelectedSpecials(selectedList)
												}}
											/>
										</div>
									</div>
								</div> */}

								{/* <div className="col-md-12">
									<div className="form-group">
										<div className="input-right-icon">
											<label className="form-label">Standard Tags</label>
											<Multiselect
												placeholder="Select Cider Tags"
												options={drink.filter((item => item.value == drinkname))?.length ? drink.filter((item => item.value == drinkname)) : drink}
												// options={food.filter((item => item.value == foodCat))?.length ? food.filter((item => item.value == foodCat)) : food}
												onSelect={handleOnchangeStand}
												disablePreSelectedValues={!!drink.filter((item => item.value == drinkname)).length}
												displayValue="label"
												selectedValues={drink.filter((item => item.value == drinkname))}
												onRemove={(selectedList, removedItem) => {
													setSelectedStanderd(selectedList)
												}}
											/>
										</div>
									</div>
								</div> */}
							</div>
							<div className="create-from-btn mt-5">
								<button className="btn btn-transparent" onClick={backStep}>Back</button>
								<button className="btn btn-dark" onClick={handleSubmit} disabled={isLoading} >
									{
										isLoading ? <FontAwesomeIcon icon={faCircleNotch} spin /> : 'Save'

									}</button>
							</div>
						</div>
					}
					{Step === 1 && liquorType === "4" && (
						<div className="create-from-steps active">
							<h4>Other</h4>
							<div className="create-form-inner mt-4">
								<div className="form-group mb-4">
									<label className="form-label">Name <span className="text-danger">*</span> </label>
									{/* <input
										className="form-control"
										type="text" value={drinkNameText} onChange={(e) => setDrinkNameText(e.target.value)}
									/> */}
									{/* <Select
										classNamePrefix="form-control"

										onChange={(selectedOption) => setDrinkname(selectedOption.value)}
										options={drinkList?.length ? (drinkList
											.filter((drink) => drink.id == liquorType)
											.map((drink) =>
												drink.drinknames.map((drinkname) => ({
													value: drinkname.id,
													label: drinkname.drink_name,
												}))
											)
											.flat()) : (<></>)}

									/> */}
									<Select
										classNamePrefix="form-control"

										onChange={(selectedOption) => setDrinkname(selectedOption.value)}
										options={drinkLists}

									/>
									<span className="error">{drinknameError}</span>
									<div className="d-flex align-items-center  graph-sets mt-2">

										<p> <b>Note:</b> Don’t see your option here? Submit a request to admin.</p>
									</div>
									{/* <select
                    className="form-control"
                    onChange={(e) => setDrinkname(e.target.value)}
                  >
                    {drinkList.length ? (
                      drinkList
                        .filter((drink) => drink.id == liquorType)
                        .map((drink, i) =>
                          drink.drinknames.map((drinkname, i) => (
                            <option value={drinkname?.id}>
                              {drinkname?.drink_name}
                            </option>
                          ))
                        )
                    ) : (
                      <></>
                    )}
                  </select> */}
								</div>
								{/* <div className="form-group mb-4"> */}
								{/* <label className="form-label">Price <span className="text-danger">*</span></label> */}
								{/* <div className="form-group d-flex align-items-center flex-row mb-2">
										<label className="custom-radio mb-0">
											Percentage
											<input
												type="radio"
												name="discountType"
												onChange={(e) => setDiscountType("percentage")}
											/>
											<span className="checkmark"></span>
										</label>
										<label className="custom-radio ms-3 mb-0">
											Price
											<input
												type="radio"
												name="discountType"
												onChange={(e) => setDiscountType("price")}
											/>
											<span className="checkmark"></span>
										</label>
										<label className="custom-radio ms-3 mb-0">
											Amount Off
											<input
												type="radio"
												name="discountType"
												onChange={(e) => setDiscountType("amount")}
											/>
											<span className="checkmark"></span>
										</label>
									</div> */}
								{/* {
										(isFormTouched && !discountType) && <span className="error">Discount Type is required!</span>
									} */}
								{/* <Select
										classNamePrefix="form-control"

										name="discountType"
										// onChange={(e) => setDiscountType("percentage")}
										onChange={(e) => setDiscountPrice(e.value)}
										options={Priceoption}
										value={Priceoption.filter(function (option) {
											return option.value === discountPrice;
										})}
									/> */}
								{/* {
										(isFormTouched && !discountPrice) && <span className="error">Discount Amount is required!</span>
									} */}
								{/* </div> */}
								<div className="form-group mb-4">
									<label className="form-label">Description (255 characters)<span className="text-danger">*</span></label>
									<textarea maxLength={255}
										className="form-control"
										name="description"
										value={description}
										onChange={(e) => setDescription(e.target.value)}
									/>
									{/* {
										(isFormTouched && !description) && <span className="error">Description is required!</span>
									} */}
								</div>
								{/* <div className="col-md-12 mb-4"> */}
								{/* <div className="form-group"> */}
								{/* <div className="input-right-icon">
                      <label className="form-label">Special Tags</label>
                      <Multiselect
                        placeholder="Select Special Tags"
                        options={drinkData}
                        displayValue="label"
                        onSelect={handleOnchangeSpecials}
                        onRemove={(selectedList, removedItem) => {
                          setSelectedSpecials(selectedList)
                        }}
                      />
                    </div> */}
								{/* </div> */}
								{/* </div> */}

								{/* <div className="col-md-12">
                  {/* <div className="form-group">
                    {/* <div className="input-right-icon">
                      <label className="form-label">Standard Tags</label>
                      <Multiselect
                        placeholder="Select Other Tags"
                        options={drink.filter((item => item.value == drinkname))?.length ? drink.filter((item => item.value == drinkname)) : drink}
                        // options={food.filter((item => item.value == foodCat))?.length ? food.filter((item => item.value == foodCat)) : food}
                        onSelect={handleOnchangeStand}
                        disablePreSelectedValues={!!drink.filter((item => item.value == drinkname)).length}
                        displayValue="label"
                        selectedValues={drink.filter((item => item.value == drinkname))}
                        onRemove={(selectedList, removedItem) => {
                          setSelectedStanderd(selectedList)
                        }}
                      />
                    </div> */}
								{/* </div> */}
								{/* </div> */}
							</div>
							<div className="create-from-btn mt-5">
								<button className="btn btn-transparent" onClick={backStep}>
									Back
								</button>
								<button className="btn btn-dark" onClick={handleSubmit} disabled={isLoading} >
									{
										isLoading ? <FontAwesomeIcon icon={faCircleNotch} spin /> : 'Save'

									}</button>
							</div>
						</div>
					)}
					{createdSuccess === true ?
						<>
							<div className="common-message-pop show" id="created-success-pop">
								<div className="common-pop-inner">
									<div className="common-pop-box created-message-box">
										<h2>Cheers!</h2>
										<p className="message-txt"> You just added to your inventory of drinks !!</p>
									</div>
								</div>
							</div>
						</> : <></>
					}
				</div>
			</div>
		</>
	);
};

export default OnTapCreate;
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import apiService from "../../core/apiService";
import { useSelector } from "react-redux";
import Select from 'react-select';
import {
  dropDownOption, perdropDownOption
} from "../../helpers";
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Multiselect from "multiselect-react-dropdown";
const FoodUpdate = (props) => {
  const restaurant = useSelector((state) => state.restaurant);
  const [Step, setStep] = useState(0);
  const [createdSuccess, setcreatedSuccess] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const fromPage = location.state?.from?.pathname || "/game-day-special/food";
  const createPage = location.state?.from?.pathname || "/game-day-special/food";
  const [gameList, setGameList] = useState([]);
  const [gameteamList, setGameTeamList] = useState([]);
  const [foodCatList, setFoodCatList] = useState([]);

  const [game, setGame] = useState("");
  const [gameError, setGameError] = useState("");
  const [gameteam, setGameTeam] = useState("");
  const [foodCat, setFoodCat] = useState("");
  const [foodCatError, setFoodCatError] = useState("");
  const [discountType, setDiscountType] = useState("");
  const [discountTypeError, setDiscountTypeError] = useState("");
  const [discountPrice, setDiscountPrice] = useState("");
  const [discountPriceError, setDiscountPriceError] = useState("");
  const [description, setDescription] = useState("");
  const [descriptionError, setDescriptionError] = useState("");
  const [isFormTouched, setIsFormTouched] = useState(false);
  const [tags, setTags] = useState([]);
  const { id } = useParams();
  const [selectedfood, setselectedFood] = useState([])
  const [selectedTag, setselectedTag] = useState([])
  const special_id = "2";
  const [food, setFood] = useState([]);
  const type_id = "1";
  const restaurantspecial_id = id;
  const [resTag, setResTags] = useState("[]")
  const [tagid, setResTagid] = useState("[]")
  const Priceoption = dropDownOption();
  const [isLoading, setIsLoading] = useState(false);
  const [standaradError, setstandandError] = useState("");
  const [restaurantspecialData, setrestaurantspecialData] = useState("");
  const perPriceoption = perdropDownOption()
  const nextStep = () => {
    setStep(Step + 1);
  };
  const backStep = () => {
    setStep(Step - 1);
  };
  console.log(selectedfood, 'selectedfood')



  useEffect(() => {
    if (foodCat >= 0) {
      setselectedFood([])
    }
  }, [foodCat])
  const handleUpdate = () => {
    setIsFormTouched(true);


    if (!description) return;
    if (foodCat == 0 && selectedfood.length < 1) return setstandandError('Standrad Tag is required!');

    let food_ids = {}
    if (selectedfood.length == 0) {
      food_ids = { "foodcategory_ids": JSON.stringify([foodCat]), tags_ids: JSON.stringify([...selectedTag.map(item => item.value)]) }
    } else
      food_ids = { "foodcategory_ids": JSON.stringify([...selectedfood.map(item => item.value)]), tags_ids: JSON.stringify([...selectedTag.map(item => item.value)]) }
    console.log(food_ids, "chekci")

    const data = {
      'restaurant_id': restaurant?.id,
      'special_id': special_id,
      'type_id': type_id,

      'food_category_id': foodCat,
      'discount_type': discountType,
      'discount_amount': discountPrice,
      'item_description': description,
      'data1': food_ids
    };
    setIsLoading(true)
    apiService.post(
      `/restaurants/specials/food-update/${restaurantspecial_id}`,
      data,
      ({ err, data }) => {
        if (err) {
          console.log(err);
          setIsLoading(false)
        }
        setIsLoading(false)
        setcreatedSuccess(createdSuccess === false ? true : false);
        setTimeout(() => {
          navigate(fromPage, { replace: true });
        }, 5000);
      }
    );
  };

  const back = () => {
    navigate(createPage, { replace: true });
  };

  useEffect(() => {
    apiService.get(
      `/restaurants/getspecial`,
      { id: restaurantspecial_id },
      ({ err, data }) => {
        if (err) return console.log(err);
        console.log(data.data.restauranttags, 'data.data')
        setrestaurantspecialData(data.data);
        setGame(data.data.sports_id);
        setGameTeam(data.data.team_id);
        setFoodCat(data.data.food_category_id);
        setDiscountType(data.data.discount_type);
        setDiscountPrice(data.data.discount_amount);
        setDescription(data.data.item_description);
        setResTags(data.data.restauranttag.foodcategory_ids)
        setResTagid(data.data.restauranttag.tags_ids)
      }
    );

    apiService.get(`/restaurants/get-sports`, {}, ({ err, data }) => {
      if (err) return console.log(err);
      setGameList(data.data);
    });
    apiService.get(`/restaurants/get-sportsteam`, {}, ({ err, data }) => {
      if (err) return console.log(err);
      setGameTeamList(data.data);
    });

    apiService.get(`/restaurants/get-foodcategory`, {}, ({ err, data }) => {
      if (err) return console.log(err);
      // setFoodCatList(data.data);

      let temp = data.data.map((food) => ({
        value: food.id,
        label: food.foodcategory_name,
      }))
      setFoodCatList([{ value: 0, label: "Multiple Items" }, ...temp,]);
      let res = []
      data.data?.map((item) => {

        const temp = {};
        temp["label"] = `${item["foodcategory_name"]}`;
        temp["value"] = item["id"];
        res.push(temp);
      });
      console.log(res, 'resss1we')
      setFood(res);
      console.log(food, 'foods')

    });

    apiService.get(`/listTags`, {}, ({ err, data }) => {
      console.log(data.data, 'tags')
      if (err) return console.log(err);
      let res = [];
      data.data?.map((item) => {
        const temp = {};
        temp["label"] = `${item["tag_name"]}`;
        temp["value"] = item["id"];
        res.push(temp);
      });
      setTags(res);
    });
  }, []);
  useEffect(() => {
    let res1 = []
    console.log(resTag, food)
    JSON.parse(resTag).map(item => {

      res1.push(...food?.filter(foodid => foodid.value == item))
    });

    setselectedFood(res1);

    let res = []
    console.log(tagid, 'tagid')
    JSON.parse(tagid).map(item => {
      console.log(tagid, item, 'in map')
      res.push(...tags?.filter(tagid => tagid.value == item))
    });
    console.log(res, "res1res1res1")
    setselectedTag(res);
  }, [resTag, food.length, tagid, tags.length])


  const handleOnchangeSpecial = (val) => {
    console.log(val, 'val1')
    setselectedFood(val);

  };

  const handleOnchangeSpecials = (val) => {
    console.log(val, 'valueses')
    setselectedTag(val);

  };

  console.log(
    game,
    gameteam,
    foodCat,
    discountType,
    discountPrice,
    description
  );
  let filter = gameteamList?.filter(items => items.sports_id == game)

  return (
    <>
      <div className="dashboard-auto-wrapper">
        <div className="create-from">
          {Step === 0 && (
            <div className="create-from-steps active">
              <h4>What sporting event is your special for?</h4>
              <div className="create-form-inner mt-4">
                <div className="row">
                  <div className="col-md-7">
                    <div className="form-group mb-4">
                      <label className="form-label">Sport <span className="text-danger">*</span></label>
                      <select
                        className="form-control"
                        name="sports"
                        key={game}
                        value={game}
                        onChange={(e) => setGame(e.target.value)}
                      >
                        <option>Select</option>
                        {gameList.length ? (
                          gameList.map((sport, i) => (
                            <option value={sport.id}>
                              {sport.sports_name}
                            </option>
                          ))
                        ) : (
                          <></>
                        )}
                      </select>
                    </div>
                  </div>
                  <div className="col-md-7">
                    <div className="form-group mb-4">
                      <label className="form-label">Team <span className="text-danger">*</span></label>
                      <select
                        className="form-control"
                        name="sportsteam"
                        key={gameteam}
                        value={gameteam}
                        onChange={(e) => setGameTeam(e.target.value)}
                      >
                        <option disabled value='' >Select</option>
                        {gameteamList.length ?
                          (
                            (
                              filter?.length > 0 ? (
                                filter?.map((team, i) => (
                                  <option key={i} value={team.id}>
                                    {team.team_name}
                                  </option>
                                ))
                              ) : (

                                <option disabled value='' >
                                  No matching teams found
                                </option>
                              )
                            )
                          ) : (
                            <></>
                          )}
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <div className="create-from-btn">
                <button className="btn btn-transparent" onClick={back}>
                  Back
                </button>
                <button className="btn btn-dark" disabled={!(filter?.length)} onClick={nextStep}>
                  Next
                </button>
              </div>
            </div>
          )}
          {Step === 1 && (
            <div className="create-from-steps active">
              <h4>Update your Food Special</h4>
              <div className="create-form-inner mt-4">
                <div className="form-group mb-4">
                  <label className="form-label">Food Category</label>
                  <Select
                    classNamePrefix="form-control"

                    onChange={(selectedOption) => setFoodCat(selectedOption.value)}
                    options={foodCatList}
                    value={foodCatList.filter(function (option) {
                      return option.value === foodCat
                    })}
                  />
                  <div className="d-flex align-items-center  graph-sets mt-2">

                    <p> <b>Note:</b> Don’t see your option here? Submit a request to admin.</p>
                  </div>
                </div>
                <div className="form-group mb-4">
                  <label className="form-label">Discount</label>
                  <div className="form-group d-flex align-items-center flex-row mb-2">
                    <label className="custom-radio mb-0">
                      Percentage
                      <input
                        type="radio"
                        name="discountType"
                        key={discountType === "percentage" ? "percentage" : ""}
                        defaultChecked={
                          discountType === "percentage" ? "percentage" : ""
                        }
                        onChange={(e) => setDiscountType("percentage")}
                      />
                      <span className="checkmark"></span>
                    </label>
                    <label className="custom-radio ms-3 mb-0">
                      Price
                      <input
                        type="radio"
                        name="discountType"
                        key={discountType === "price" ? "price" : ""}
                        defaultChecked={discountType === "price" ? "price" : ""}
                        onChange={(e) => setDiscountType("price")}
                      />
                      <span className="checkmark"></span>
                    </label>
                    <label className="custom-radio ms-3 mb-0">
                      Amount Off
                      <input
                        type="radio"
                        name="discountType"
                        key={discountType === "amount" ? "amount" : ""}
                        defaultChecked={
                          discountType === "amount" ? "amount" : ""
                        }
                        onChange={(e) => setDiscountType("amount")}
                      />
                      <span className="checkmark"></span>
                    </label>
                  </div>
                  <Select
                    classNamePrefix="form-control"
                    name="discountPrice"
                    onChange={(e) => setDiscountPrice(e.value)}
                    options={discountType == "percentage" ? perPriceoption : Priceoption}
                    value={discountType == "percentage" ? perPriceoption.filter(function (option) {

                      return option.value === discountPrice;
                    }) :
                      Priceoption.filter(function (option) {
                        return option.value === discountPrice;
                      })}
                  />
                </div>
                <div className="form-group mb-4">
                  <label className="form-label">Food item description(255 characters) </label>
                  <textarea maxLength={255}
                    className="form-control"
                    name="description"
                    onChange={(e) => setDescription(e.target.value)}
                    value={description}
                  ></textarea>
                  {
                    (isFormTouched && !description) && <span className="error">Description is required!</span>
                  }
                </div>
              </div>
              <div className="col-md-12 mb-4">
                <div className="form-group">
                  <div className="input-right-icon">
                    <label className="form-label">Special Tags</label>
                    <Multiselect
                      placeholder="Select Special Tags"
                      options={tags}
                      displayValue="label"
                      selectedValues={selectedTag}
                      onSelect={handleOnchangeSpecials}
                      onRemove={(selectedList, removedItem) => {
                        setselectedTag(selectedList)
                      }}
                    />
                  </div>
                </div>
                <div className="d-flex align-items-center  graph-sets mt-2">

                  <p> <b>Note:</b> Don’t see your option here? Submit a request to admin.</p>
                </div>
              </div>

              <div className="col-md-12">
                <div className="form-group">
                  <div className="input-right-icon">
                    <label className="form-label">Standard Tags</label>
                    <Multiselect
                      placeholder="Select Food Tags"
                      options={food.filter((item => item.value == foodCat))?.length ? food.filter((item => item.value == foodCat)) : food}
                      onSelect={handleOnchangeSpecial}
                      disablePreSelectedValues={!!food.filter((item => item.value == foodCat)).length}
                      displayValue="label"
                      selectedValues={foodCat !== 0 ? foodCatList.filter(function (option) {
                        return option.value === foodCat
                      }) : selectedfood}
                      onRemove={(selectedList, removedItem) => {
                        setselectedFood(selectedList)
                      }}
                    />
                  </div>
                  <span className="error">{standaradError}</span>
                  <div className="d-flex align-items-center  graph-sets mt-2">

                    <p> <b>Note:</b> If ‘Multiple Items’ is selected for Food Category, select all tags that apply.</p>
                  </div>

                </div>
              </div>
              <div className="create-from-btn mt-5">
                <button className="btn btn-transparent" onClick={backStep}>
                  Back
                </button>
                <button className="btn btn-dark" disabled={isLoading} onClick={handleUpdate}>
                  {
                    isLoading ? <FontAwesomeIcon icon={faCircleNotch} spin /> : 'Update'

                  }
                </button>
              </div>
            </div>
          )}

          {createdSuccess === true ? (
            <>
              <div className="common-message-pop show" id="created-success-pop">
                <div className="common-pop-inner">
                  <div className="common-pop-box created-message-box">
                    <h2>Cheers!</h2>
                    <p className="message-txt">
                      you just updated a Game Day Special!!
                    </p>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <></>
          )}
        </div>
      </div>
    </>
  );
};

export default FoodUpdate;

import { useEffect } from "react";
import { confirmAlert } from "react-confirm-alert";

function CustomError(title,heading) {  
    console.log(title,'title')
        confirmAlert({
            customUI: ({ onClose }) => {
              return (
                <div className='custom-ui-error' >				
                  <h1>{heading}</h1>
                  <p>{title}</p>				
                  <div class="react-confirm-alert-button-group"
                    >
                    <button onClick={() => {
                      onClose();
                    }} label="OK">OK</button>
                  </div>
                </div>
              );
            }
          })
        
        }


      
      
        export default CustomError ;
  
import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import logo from '../../assets/images/logo.png';
import verification from '../../assets/images/verification.jpg';

const Verification = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const fromPage = location.state ?.from ?.pathname || '/membership';

    useEffect(() => {
        const timer = setTimeout(() => {
            navigate(fromPage, { replace: true })
        }, 2000);
        return () => clearTimeout(timer);
    });

    return (
        <div>
            <div id="verification-page" >
                <div className="container" >
                    <div className="verification-logo" >
                        <img alt="" src={logo} /> </div>
                    <div className="verification-ing" >
                        <img alt="" src={verification} />
                    </div>
                    <p className="wait-txt" > Wait!!Admin Verify your accout </p>
                    <div className="also-contact" > You can also contact on <a href="mailto:admin@whatson.ca" > admin @whatson.ca </a></div >
                </div>
            </div>
        </div>
    );
};

export default Verification;
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import apiService from '../../core/apiService';
import logo from '../../assets/images/logo.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { confirmAlert } from 'react-confirm-alert';
import CustomError from '../../components/CustomError';


const Loginpage = () => {
	const { register, handleSubmit, watch, formState: { errors } } = useForm();
	const navigate = useNavigate();
	const location = useLocation();
	const [loading, setLoading] = useState(false);
	const fromPage = location.state?.from?.pathname || '/';

	const onForget = (values) => {
		setLoading(true);
		apiService.post('/forget-pwd', values, ({ err, data }) => {
			console.log(data, 'datdadatdatda')
			if (err) {
			setLoading(false);
				return;
			}
			setLoading(false);
			if (data.success == false) {
				
				navigate(fromPage, { replace: false })
			} else {
				// confirmAlert({
				// 	title: 'Success',
				// 	message: 'Mail Successfully Sent',
				// 	buttons: [
				// 		{
				// 			label: 'OK',
				// 			onClick: () => navigate(fromPage, { replace: true })
				// 		},

				// 	]
				// });
				confirmAlert({
					customUI: ({ onClose }) => {
						return (
							<div className='custom-ui'>

								<h1>Success</h1>
								<p>Your login information has been sent to your mail</p>
								<div class="react-confirm-alert-button-group"
								>
									<button onClick={() => {
										navigate(fromPage, { replace: true })
										onClose();
									}} label="OK">OK</button>
								</div>
							</div>
						);
					}
				})

			}
		})

		// navigate(fromPage, { replace: true })
	}

	return (
		<div>
			<div id="login-page">
				<div className="login-form">
					<div className="login-form-inner">
						<div className="login-logo">
							<img src={logo} alt="Whatson" />
							<p>Get your login information</p>
						</div>
						<div className="login-form-box">
							<form onSubmit={handleSubmit(onForget)}>
								<div className="mb-4">
									<label>Email</label>
									<input {...register('restaurant_email', {
										required: "Email is required",
										pattern: {
											value:
												/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
											message: "Invalid email address",
										}
									})}

										type="text" id="email" className="form-control" placeholder="Email" />
									{errors?.restaurant_email && <span className="error email-error">{errors?.restaurant_email?.message}</span>}

								</div>
								<div className="mb-3">
									<button className="btn btn-dark full-btn btn-lg signin-btn">{
										loading ? <FontAwesomeIcon icon={faCircleNotch} spin /> : 'Get Password'
									}</button>
								</div>


							</form>
						</div>
						<div className="form-bottom-link">Already have an account? <Link to='/'><b>Login Now</b></Link></div>
					</div>
				</div>
				<div className="login-img">
					<div className="login-img-inner">
						<div className="dashboard-title">
							<h3>WELCOME TO</h3>
							<h1>Restaurant Panel</h1>
							<p>Login to Access Dashboard</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Loginpage;

import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import apiService from "../../core/apiService";
import { useSelector } from 'react-redux';
import Select from 'react-select';
import { Multiselect } from "multiselect-react-dropdown";
import {
	dropDownOption, perdropDownOption
} from "../../helpers";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import CustomError from '../../components/CustomError';
const GameFoodCreate = () => {
	const restaurant = useSelector(state => state.restaurant);
	const [Step, setStep] = useState(0);
	const [createdSuccess, setcreatedSuccess] = useState(false);
	const navigate = useNavigate();
	const location = useLocation();
	const fromPage = location.state?.from?.pathname || '/game-day-special/food';
	const createPage = location.state?.from?.pathname || '/game-day-special/food';
	const [gameList, setGameList] = useState([]);
	const [gameteamList, setGameTeamList] = useState([]);
	const [foodCatList, setFoodCatList] = useState([]);
	const [game, setGame] = useState("");
	const [gameError, setGameError] = useState("");
	const [gameteam, setGameTeam] = useState("");
	const [foodCat, setFoodCat] = useState("");
	const [discountType, setDiscountType] = useState("");
	const [discountPrice, setDiscountPrice] = useState("");
	const [description, setDescription] = useState("");
	const [isLoading, setIsLoading] = useState(false);
	const [tags, setTags] = useState([]);
	const [food, setFood] = useState([]);
	const [standarad, setSelectedStandrad] = useState([]);
	const [special, setSelectedSpecials] = useState([]);
	const [foodCatError, setFoodCatError] = useState("");
	const [standaradError, setstandandError] = useState("");
	const special_id = '2';
	const type_id = '1';
	const Priceoption = dropDownOption();
	const perPriceoption = perdropDownOption()
	const nextStep = () => {
		if (Step === 0 && (game === '' || gameteam === '')) {
			setGameError("Select one option!")
			return;
		}
		setStep(Step + 1);
	};
	const backStep = () => {
		setStep(Step - 1);
	};

	useEffect(() => {
		if (foodCat > 0) {
			setSelectedStandrad([])
		}
	}, [foodCat])
	const handleSubmit = () => {

		console.log(standarad, 'length')
		if (foodCat == 0 && standarad.length < 1) return setstandandError('Standrad Tag is required!');
		console.log(special, 'specialspecial')

		// let food_ids = []
		// if (foodCat == 0) {
		//   food_ids = ((standarad.length >= special.length) ? standarad : special)?.map((item, i) => {
		// 	return { "foodcategory_id": standarad[i] ? standarad[i].value : 0,  tags_id: special[i] ? special[i].value : 0 }
		//   })
		// } else {
		//   food_ids = a Special?.map((item, i) => {
		// 	return {  tags_id: special[i] ? special[i].value : 0 }

		//   }
		//   )
		//   food_ids[0].foodcategory_id = foodCat
		//   console.log(food_ids, 'food')
		// }
		let food_ids = {}
		if (standarad.length == 0) {
			food_ids = { "foodcategory_ids": JSON.stringify([foodCat]), tags_ids: JSON.stringify([...special.map(item => item.value)]) }
		} else
			food_ids = { "foodcategory_ids": JSON.stringify([...standarad.map(item => item.value)]), tags_ids: JSON.stringify([...special.map(item => item.value)]) }
		console.log(food_ids, "chekci")

		// if (game === '') {
		// 	return;
		// }
		// if (gameteam === '') {
		// 	return;
		// }
		// if (foodCat === '') {
		// 	return;
		// }
		// if (discountType === '') {
		// 	return;
		// }
		// if (discountPrice === '') {
		// 	return;
		// }
		// if (description === '') {
		// 	return;
		// }
		const data = {
			'restaurant_id': restaurant?.id,
			'special_id': special_id,
			'type_id': type_id,
			'sports_id': game,
			'team_id': gameteam,
			'food_category_id': foodCat,
			'discount_type': discountType,
			'discount_amount': discountPrice,
			'item_description': description,
			'data1': food_ids
		}
		if (discountPrice && description && discountType && foodCat >= 0) {
			setIsLoading(true)
			apiService.post('/restaurants/specials/create', data, ({ err, data }) => {
				if (err) {
					console.log(err);
					setIsLoading(false)
				};
				setIsLoading(false)
				setcreatedSuccess(createdSuccess === false ? true : false);
				setTimeout(() => {
					navigate(fromPage, { replace: true });
				}, 5000);
			})
		} else {
			CustomError('Please fill all the required fields', '')
		}



	};
	const back = () => {
		navigate(createPage, { replace: true });
	};

	useEffect(() => {
		apiService.get(`/restaurants/get-sports`, {}, ({ err, data }) => {
			if (err) return console.log(err);
			setGameList(data.data);
		});
		apiService.get(`/restaurants/get-sportsteam`, {}, ({ err, data }) => {
			if (err) return console.log(err);
			setGameTeamList(data.data);
		});
		apiService.get(`/restaurants/get-foodcategory`, {}, ({ err, data }) => {
			if (err) return console.log(err);
			let temp = data?.data?.map((food) => ({
				value: food.id,
				label: food.foodcategory_name,
			}))
			setFoodCatList([{ value: 0, label: "Multiple Items" }, ...temp,]);
			let res = [];
			data.data?.map((item) => {
				console.log(item, 'itemmms1')
				const temp = {};
				temp["label"] = `${item["foodcategory_name"]}`;
				temp["value"] = item["id"];
				res.push(temp);
			});
			console.log(res, 'resss1')
			setFood(res);

		});
		apiService.get(`/listTags`, {}, ({ err, data }) => {
			console.log(data.data, 'tags')
			if (err) return console.log(err);
			let res = [];
			data.data?.map((item) => {
				const temp = {};
				temp["label"] = `${item["tag_name"]}`;
				temp["value"] = item["id"];
				res.push(temp);
			});

			console.log(res, 'resss')
			setTags(res);
		});
	}, []);
	console.log(foodCat, 'foodCat')


	const handleOnchangeSpecial = (val) => {
		setSelectedStandrad(val);

	};

	const handleOnchangeSpecials = (val) => {
		console.log(val, 'valueses')
		setSelectedSpecials(val);

	};
	console.log()


	let filter = gameteamList?.filter(items => items.sports_id == game)

	return (
		<>
			<div className="dashboard-auto-wrapper">
				<div className="create-from">
					{Step === 0 &&
						<div className="create-from-steps active">
							<h4>What sporting event is your special for?</h4>
							<div className="create-form-inner mt-4">
								<div className='row'>
									<div className='col-md-7'>
										<div className="form-group mb-4">
											<label className="form-label">Sport <span className="text-danger">*</span></label>
											<select className="form-control" name="sports"
												value={game}
												onChange={(e) => setGame(e.target.value)}>
												<option value=''>Select</option>
												{gameList.length ? (
													gameList.map((sport, i) => (
														<option value={sport.id}>
															{sport.sports_name}
														</option>
													))
												) : (
													<></>
												)}
											</select>
										</div>
									</div>
									<div className='col-md-7'>
										<div className="form-group mb-4">
											<label className="form-label">Team <span className="text-danger">*</span></label>
											<select className="form-control"
												name="sportsteam"
												onChange={(e) => setGameTeam(e.target.value)}
												value={gameteam}
											>
												<option disabled value=''>Select</option>
												{gameteamList.length ?
													(
														(
															filter?.length > 0 ? (
																filter?.map((team, i) => (
																	<option key={i} value={team.id}>
																		{team.team_name}
																	</option>
																))
															) : (

																<option disabled value='' >
																	No matching teams found
																</option>
															)
														)
													) : (
														<></>
													)}
											</select>
										</div>
									</div>
									<span className="error">{gameError}</span>
								</div>
							</div>
							<div className="create-from-btn">
								<button className="btn btn-transparent" onClick={back}>Back</button>
								<button className="btn btn-dark" disabled={!(filter?.length)} onClick={nextStep}>Next</button>
							</div>
						</div>
					}
					{Step === 1 && (
						<div className="create-from-steps active">
							<h4>Create your Food Special</h4>
							<div className="create-form-inner mt-4">
								<div className="form-group mb-4">
									<label className="form-label">Food Category <span className="text-danger">*</span></label>
									<Select
										classNamePrefix="form-control"

										onChange={(selectedOption) => setFoodCat(selectedOption.value)}
										options={foodCatList}

									/>

									{/* <span className="error">{foodCatError}</span> */}
									<div className="d-flex align-items-center  graph-sets mt-2">

										<p> <b>Note:</b> Don’t see your option here? Submit a request to admin.</p>
									</div>
								</div>
								<div className="form-group mb-4">
									<label className="form-label">Discount  <span className="text-danger">*</span></label>
									<div className="form-group d-flex align-items-center flex-row mb-2">
										<label className="custom-radio mb-0">
											Percentage
											<input
												type="radio"
												name="discountType"
												onChange={(e) => setDiscountType("percentage")}
											/>
											<span className="checkmark"></span>
										</label>
										<label className="custom-radio ms-3 mb-0">
											Price
											<input
												type="radio"
												name="discountType"
												onChange={(e) => setDiscountType("price")}
											/>
											<span className="checkmark"></span>
										</label>
										<label className="custom-radio ms-3 mb-0">
											Amount Off
											<input
												type="radio"
												name="discountType"
												onChange={(e) => setDiscountType("amount")}
											/>
											<span className="checkmark"></span>
										</label>
									</div>
									<Select
										classNamePrefix="form-control"
										name="discountPrice"
										onChange={(e) => setDiscountPrice(e.value)}
										options={discountType == "percentage" ? perPriceoption : Priceoption}
										value={discountType == "percentage" ? perPriceoption.filter(function (option) {
											console.log(option.value, 'value')
											return option.value === discountPrice;
										}) :
											Priceoption.filter(function (option) {
												return option.value === discountPrice;
											})}
									/>
								</div>
								<div className="form-group mb-4">
									<label className="form-label">Food item description(255 characters) <span className="text-danger">*</span></label>
									<textarea maxLength={255}
										className="form-control"
										name="description"
										onChange={(e) => setDescription(e.target.value)}
									/>
								</div>
							</div>

							<div className="col-md-12 mb-4">
								<div className="form-group">
									<div className="input-right-icon">
										<label className="form-label">Special Tags</label>
										<Multiselect
											placeholder="Select Special Tags"
											options={tags}
											displayValue="label"
											onSelect={handleOnchangeSpecials}
											onRemove={(selectedList, removedItem) => {
												setSelectedSpecials(selectedList)
											}}
										/>
									</div>
								</div>
								<div className="d-flex align-items-center  graph-sets mt-2">

									<p> <b>Note:</b> Don’t see your option here? Submit a request to admin.</p>
								</div>
							</div>

							<div className="col-md-12">
								<div className="form-group">
									<div className="input-right-icon">
										<label className="form-label">Standard Tags</label>
										<Multiselect
											placeholder="Select Food Tags"
											options={food.filter((item => item.value == foodCat))?.length ? food.filter((item => item.value == foodCat)) : food}
											onSelect={handleOnchangeSpecial}
											disablePreSelectedValues={!!food.filter((item => item.value == foodCat)).length}
											displayValue="label"
											selectedValues={food.filter((item => item.value == foodCat))}
											onRemove={(selectedList, removedItem) => {
												setSelectedStandrad(selectedList)
											}}
										/>
									</div>
									<span className="error">{standaradError}</span>
								</div>
								<div className="d-flex align-items-center  graph-sets mt-2">

									<p> <b>Note:</b> If ‘Multiple Items’ is selected for Food Category, select all tags that apply.</p>
								</div>
							</div>
							<div className="create-from-btn mt-5">
								<button className="btn btn-transparent" onClick={backStep}>
									Back
								</button>
								<button className="btn btn-dark" onClick={handleSubmit} disabled={isLoading} >
									{
										isLoading ? <FontAwesomeIcon icon={faCircleNotch} spin /> : 'Save'

									}</button>
							</div>
						</div>
					)}


					{createdSuccess === true ?
						<>
							<div className="common-message-pop show" id="created-success-pop">
								<div className="common-pop-inner">
									<div className="common-pop-box created-message-box">
										<h2>Cheers!</h2>
										<p className="message-txt">You just created a Game Day Special!!</p>
									</div>
								</div>
							</div>
						</> : <></>
					}
				</div>
			</div>
		</>
	);
};

export default GameFoodCreate;
import React,{useState} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClock } from "@fortawesome/free-solid-svg-icons";


const CreateSpecial = () => {
	const [Step, setStep] = useState(0);
	const [createdSuccess, setcreatedSuccess] = useState(false);
	
	const nextStep = () => {
		setStep(Step + 1);
	  };
	  const backStep = () => {
		setStep(Step - 1);
	  };
	const navigate = useNavigate();
	const location = useLocation();
	const fromPage = location.state?.from?.pathname || '/happy-hour/food';
	const createPage = location.state?.from?.pathname || '/create-special';

	const handleSubmit = () => {
		setcreatedSuccess(createdSuccess === false ? true : false);
		setTimeout(() => {
			navigate(fromPage, {replace: true})
		}, 3000);
	}

	const [foodType, setfoodType] = useState("food");
	const changeType = (e) => {
		setfoodType(e.target.value);
	  };
	  const [liquorType, setliquorType] = useState("wine");
	  const changeliquorType = (e) => {
		  setliquorType(e.target.value);
	  };

	const back = () => {
		navigate(createPage, {replace: true})
	  };
	
    return (
        <>	
		<div className="dashboard-auto-wrapper">
		<div className="create-from">
		{Step === 0 &&
			<div className="create-from-steps active">
				<h4>What day is your Special?
</h4>	
				<div className="create-form-inner mt-4">
					<div className="form-group mb-3">
						<div className="d-flex flex-row justify-content-between">
							<label className="custom-check">Monday
								<input type="radio" name="deals"/>
								<span className="checkmark"></span>
							</label>
							<label className="custom-check">Tuesday
								<input type="radio" name="deals"/>
								<span className="checkmark"></span>
							</label>
							<label className="custom-check">Wednesday
								<input type="radio" name="deals"/>
								<span className="checkmark"></span>
							</label>
							<label className="custom-check">Thursady
								<input type="radio" name="deals"/>
								<span className="checkmark"></span>
							</label>
							<label className="custom-check">Friday
								<input type="radio" name="deals"/>
								<span className="checkmark"></span>
							</label>
							<label className="custom-check">Saturday
								<input type="radio" name="deals"/>
								<span className="checkmark"></span>
							</label>
							<label className="custom-check">Sunday
								<input type="radio" name="deals"/>
								<span className="checkmark"></span>
							</label>
						</div>
					</div>
				</div>
				<div className="create-from-btn">
					<button className="btn btn-transparent" onClick={back}>Back</button>
					<button className="btn btn-dark" onClick={nextStep}>Next</button>
				</div>
			</div>
			}
			{Step === 1 &&
				<div className="create-from-steps active">
					<h4>Yoo-Hoo, lets have some fun! What is your happy hours?</h4>		
					<div className="create-form-inner mt-4">
						<div className="row">
							<div className="col-md-4">
								<div className="form-group mb-4">
									<label className="form-label">Start Time</label>
									<div className="input-right-icon">
										<input className="form-control"/>
										<FontAwesomeIcon icon={faClock} />
									</div>
								</div>
							</div>
							<div className="col-md-4">
								<div className="form-group mb-4">
									<label className="form-label">End Time</label>
									<div className="input-right-icon">
										<input className="form-control"/>
										<FontAwesomeIcon icon={faClock} />
									</div>
								</div>
							</div>
							<div className="col-md-4">
								<div className='time-add-more'>
									<button className='btn btn-dark'>Add More</button>
								</div>
							</div>
						</div>
						<div className="form-group d-flex align-items-center flex-row mb-4">
							<label className="custom-radio mb-3">All Day
								<input type="radio" name="allday"/>
								<span className="checkmark"></span>
							</label>
						</div>
					</div>
					<div className="create-from-btn">
						<button className="btn btn-transparent" onClick={backStep}>Back</button>
						<button className="btn btn-dark" onClick={nextStep}>Next</button>
					</div>
				</div>
			}
			{Step === 2 &&
				<div className="create-from-steps active">
					<h4>Hey, tell me what is this special for?</h4>		
					<div className="create-form-inner mt-4">
						<div className="form-group d-flex align-items-center flex-row mb-4">
							<label className="custom-radio mb-0">Food
								<input type="radio" checked={foodType === 'food'} onChange={changeType} value="food" name="deals"/>
								<span className="checkmark"></span>
							</label>
							<label className="custom-radio ms-3 mb-0">Drinks
								<input type="radio" checked={foodType === 'liquor'} onChange={changeType} value="liquor" name="deals"/>
								<span className="checkmark"></span>
							</label>
						</div>
					</div>
					<div className="create-from-btn">
						<button className="btn btn-transparent" onClick={backStep}>Back</button>
						<button className="btn btn-dark" onClick={nextStep}>Next</button>
					</div>
					
				</div>
			}
			{Step === 3 && foodType === 'food' &&
				<div className="create-from-steps active">
					<h4>Food!</h4>		
					<div className="create-form-inner mt-4">
						<div className="form-group mb-4">
							<label className="form-label">Food Category</label>
							<select className="form-control">
								<option></option>
							</select>
						</div>
						<div className="form-group mb-4">
							<label className="form-label">Discount</label>
							<div className="form-group d-flex align-items-center flex-row mb-2">
								<label className="custom-radio mb-0">Percentage
									<input type="radio" name="price" checked/>
									<span className="checkmark"></span>
								</label>
								<label className="custom-radio ms-3 mb-0">Price
									<input type="radio" name="price"/>
									<span className="checkmark"></span>
								</label>
								<label className="custom-radio ms-3 mb-0">Amount Off
									<input type="radio" name="price"/>
									<span className="checkmark"></span>
								</label>
							</div>
							<select className="form-control">
								<option></option>
							</select>
						</div>
						<div className="form-group mb-4">
							<label className="form-label">Food item description</label>
							<input className="form-control"/>
						</div>
					</div>
					<div className="create-from-btn mt-5">
						<button className="btn btn-transparent" onClick={backStep}>Back</button>
						<button className="btn btn-dark" onClick={handleSubmit}>Save</button>
					</div>
				</div>
			}
			
			{Step === 3 && foodType === 'liquor' &&
				<div className="create-from-steps active">
					<h4>Please Select Type</h4>		
					<div className="create-form-inner mt-4">
						<div className="form-group mb-4">
							<label className="form-label">Type</label>
							<select value={liquorType} onChange={changeliquorType} className="form-control">
								<option value="wine">Wine</option>
								<option value="beer">Beer</option>
								<option value="cider">Cider</option>
							</select>
						</div>
					</div>
					<div className="create-from-btn mt-5">
						<button className="btn btn-transparent" onClick={backStep}>Back</button>
						<button className="btn btn-dark" onClick={nextStep}>Next</button>
					</div>
				</div>
			}
			{Step === 4 && foodType === 'liquor' && liquorType === 'wine' &&
				<div className="create-from-steps active">
					<h4>Wine</h4>		
					<div className="create-form-inner mt-4">
						<div className="form-group mb-4">
							<label className="form-label">Region</label>
							<select className="form-control">
								<option></option>
							</select>
						</div>
						<div className="form-group mb-4">
							<label className="form-label">Red/White</label>
							<select className="form-control">
								<option></option>
							</select>
						</div>
						<div className="form-group mb-4">
							<label className="form-label">Type</label>
							<select className="form-control">
								<option></option>
							</select>
						</div>
						<div className="form-group mb-4">
							<label className="form-label">Container Type</label>
							<select className="form-control">
								<option></option>
							</select>
						</div>
						<div className="form-group mb-4">
							<label className="form-label">Size</label>
							<select className="form-control">
								<option></option>
							</select>
						</div>
						<div className="form-group mb-4">
							<label className="form-label">Discount</label>
							<div className="form-group d-flex align-items-center flex-row mb-2">
								<label className="custom-radio mb-0">Percentage
									<input type="radio" name="price" checked/>
									<span className="checkmark"></span>
								</label>
								<label className="custom-radio ms-3 mb-0">Price
									<input type="radio" name="price"/>
									<span className="checkmark"></span>
								</label>
								<label className="custom-radio ms-3 mb-0">Amount Off
									<input type="radio" name="price"/>
									<span className="checkmark"></span>
								</label>
							</div>
							<select className="form-control">
								<option></option>
							</select>
						</div>
						<div className="form-group mb-4">
							  <label className="form-label">Description(255 characters)</label>
							<input className="form-control"/>
						</div>
					</div>
					<div className="create-from-btn mt-5">
						<button className="btn btn-transparent" onClick={backStep}>Back</button>
						<button className="btn btn-dark" onClick={handleSubmit}>save</button>
					</div>
				</div>
			}
			{Step === 4 && foodType === 'liquor' && liquorType === 'beer' &&
				<div className="create-from-steps active">
				<h4>Beer</h4>		
				<div className="create-form-inner mt-4">
					<div className="form-group mb-4">
						<label className="form-label">Name</label>
						<select className="form-control">
							<option></option>
						</select>
					</div>
					<div className="form-group mb-4">
						<label className="form-label">Container Type</label>
						<select className="form-control">
							<option></option>
						</select>
					</div>
					<div className="form-group mb-4">
						<label className="form-label">Size</label>
						<select className="form-control">
							<option></option>
						</select>
					</div>
					<div className="form-group mb-4">
						<label className="form-label">Price</label>
						<select className="form-control">
							<option></option>
						</select>
					</div>
					<div className="form-group mb-4">
						  <label className="form-label">Description(255 characters)</label>
						<input className="form-control"/>
					</div>
				</div>
				<div className="create-from-btn mt-5">
					<button className="btn btn-transparent" onClick={backStep}>Back</button>
					<button className="btn btn-dark" onClick={handleSubmit}>save</button>
				</div>
			</div>
			}
			{Step === 4 && foodType === 'liquor' && liquorType === 'cider' && 
				<div className="create-from-steps active">
					<h4>Cider</h4>		
					<div className="create-form-inner mt-4">
						<div className="form-group mb-4">
							<label className="form-label">Name</label>
							<select className="form-control">
								<option></option>
							</select>
						</div>
						<div className="form-group mb-4">
							<label className="form-label">Container Type</label>
							<select className="form-control">
								<option></option>
							</select>
						</div>
						<div className="form-group mb-4">
							<label className="form-label">Size</label>
							<select className="form-control">
								<option></option>
							</select>
						</div>
						<div className="form-group mb-4">
							<label className="form-label">Price</label>
							<select className="form-control">
								<option></option>
							</select>
						</div>
						<div className="form-group mb-4">
							  <label className="form-label">Description(255 characters)</label>
							<input className="form-control"/>
						</div>
					</div>
					<div className="create-from-btn mt-5">
						<button className="btn btn-transparent" onClick={backStep}>Back</button>
						<button className="btn btn-dark" onClick={handleSubmit}>save</button>
					</div>
				</div>
			}
			{createdSuccess === true ?
			<>
			<div className="common-message-pop show" id="created-success-pop">
				<div className="common-pop-inner">
					<div className="common-pop-box created-message-box">
						<h2>Cheers!</h2>
						<p className="message-txt">You just Created Specials!!</p>
					</div>
				</div>
			</div>
			</> : <></>
			}
			</div>
			</div>
        </>
    );
};

export default CreateSpecial;
import React, { useEffect, useState } from 'react';
import apiService from '../core/apiService';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { useSelector } from 'react-redux';

const SpecialLists = ({ columns, urlParams }) => {
    const restaurant = useSelector(state => state.restaurant);
    const [dataLoading, setDataLoading] = useState(false);
    const [specialsLists, setSpecialsList] = useState([]);
    const [listError, setListError] = useState();

    useEffect(() => {
        // console.log(urlParams);
        setDataLoading(true);
        apiService.get(`/restaurants/specials`, {...urlParams, restaurant_id: restaurant?.id}, ({ err, data }) => {
            if (err) return setDataLoading(false), setListError(err.error);
            setSpecialsList(data.data);
            setDataLoading(false);
        })
    }, [urlParams]);

    const getDataIndex = (item, dataIndex) => {
        if (!dataIndex) return '';
        if (dataIndex === 'action') {
            return (
                <>
                    <button className="btn btn-success">Update</button>
                    <button className="btn btn-danger">Delete</button>
                </>
            )
        }
        if (dataIndex.includes('.')) {
            let dataValue = eval("item." + dataIndex)
            return dataValue || ''
        }
        return item[dataIndex];
    }

    return (
        <div className="table-sec common-table margin-t30">
            <div className="table-responsive">
                <table className="table white-td-table">
                    <thead>
                        <tr>
                            {
                                columns.map((column, index) => (
                                    <th key={index}>{column.name}</th>
                                ))
                            }
                        </tr>
                    </thead>
                    <tbody className={!specialsLists.length ? 'empty_row' : ''}>
                        {
                            dataLoading ?
                                <tr>
                                    <td colSpan={columns.length}>
                                        <div className='table-inner-loader'>
                                            <FontAwesomeIcon style={{ fontSize: 25 }} icon={faCircleNotch} spin />
                                        </div>
                                    </td>
                                </tr> :
                                specialsLists.length ?
                                    specialsLists.map((item, i) => (
                                        <tr key={i}>
                                            {
                                                columns.map((column, index) => (
                                                    <td className={column.dataIndex === 'action' ? 'table-action' : ''} key={index}>{getDataIndex(item, column.dataIndex)}</td>
                                                ))
                                            }
                                        </tr>
                                    )) :
                                    <tr>
                                        <td colSpan={columns.length}>{listError}</td>
                                    </tr>
                        }
                    </tbody>
                </table>
            </div>
        </div>
    )
};

export default SpecialLists;
import React,{useState} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';


const CreateSpecial = () => {

	const [deal, setDeal] = useState("dailyspecial");
	const navigate = useNavigate();
	const location = useLocation();
	const dailyPage = location.state?.from?.pathname || '/create-special/daily-special';
	const gamePage = location.state?.from?.pathname || '/create-special/game-special';
	const happyHours = location.state?.from?.pathname || '/create-special/happy-hours';
	const onTap = location.state?.from?.pathname || '/create-special/on-tap';
	const changeDeal = (e) => {
		setDeal(e.target.value);
	  };
	  const nextPage = () => {
		if(deal === 'dailyspecial') {
			navigate(dailyPage, {replace: true})
		}
		if(deal === 'gamespecial') {
			navigate(gamePage, {replace: true})
		}
		if(deal === 'happyhours') {
			navigate(happyHours, {replace: true})
		}
		if(deal === 'ontap') {
			navigate(onTap, {replace: true})
		}
	  };
	
    return (
        <>	
		<div className="dashboard-auto-wrapper">
			<div className="create-from">
				<div className="create-from-steps active" id="createstep1">
					<h4>Hey, What you want to create today?</h4>
					<div className="create-form-inner mt-3">
						<div className="form-group">
							<label className="custom-radio">Daily Specials
								<input type="radio" checked={deal === 'dailyspecial'} onChange={changeDeal} value='dailyspecial' name="deals"/>
								<span className="checkmark"></span>
							  </label>
						</div>
						<div className="form-group">
							<label className="custom-radio">Game day Specials
								<input type="radio" checked={deal === 'gamespecial'} onChange={changeDeal} value="gamespecial" name="deals"/>
								<span className="checkmark"></span>
							  </label>
						</div>
						<div className="form-group">
							<label className="custom-radio">Happy Hours
								<input type="radio" checked={deal === 'happyhours'} onChange={changeDeal} value="happyhours" name="deals"/>
								<span className="checkmark"></span>
							  </label>
						</div>
						<div className="form-group">
							<label className="custom-radio">On Tap
								<input type="radio" checked={deal === 'ontap'} onChange={changeDeal} value="ontap" name="deals"/>
								<span className="checkmark"></span>
							  </label>
						</div>
					</div>
					<div className="next-step-btn mt-5">
						<button className="btn btn-dark" onClick={nextPage}>Next</button>
					</div>
				</div>
			</div>
		</div>
        </>
    );
};

export default CreateSpecial;
import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import apiService from '../../core/apiService';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle, faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import logo from '../../assets/images/logo.png';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { useCookies } from 'react-cookie';
import moment from 'moment';
import CustomSuccess from '../../components/CustomSuccess';
const Loginpage = () => {
	const [_, setCookie, removeCookie] = useCookies(['userId', 'userToken', 'restaurantId']);
	const { register, handleSubmit, watch, formState: { errors } } = useForm();
	const dispatch = useDispatch();
	const selector = useSelector(state=>state)
	const navigate = useNavigate();
	const location = useLocation();
	const [rememberMe, setRememberMe] = useState(false);
	const fromPage = location.state?.from?.pathname || '/dashboard';
	const [loading, setLoading] = useState(false);
	const [isVisible, setVisible] = useState(true);
	const cookieAge = () => {
		let normalCookieAge = 24 * 60 * 60;
		return rememberMe ? normalCookieAge * 7 : normalCookieAge
	}
	const toggle = () => {
		setVisible(!isVisible);
	};
	console.log(selector,"selector1")
	const onSubmit = (values) => {
		setLoading(true);
		apiService.login('/login', values, ({ err, data }) => {
			if (err) {
				console.log(err.error);
				setLoading(false);
				return;
			};
		
			setCookie('userId', data.data.id, { maxAge: cookieAge(), path: '/' });
			setCookie('userToken', data.data.token, { maxAge: cookieAge(), path: '/' });
			setCookie('restaurantId', data.data.restaurant.id, { maxAge: cookieAge(), path: '/' });
			dispatch({ type: "onLogin", payload: data.data });
			

			if(data.data.subscribe?.susbcription_id){
				// CustomSuccess('Hey lets create  Specials','Success')
				if(data.data.timing.length)navigate('/dashboard',{replace:true} 
				);
		
			
				else navigate('/profile-setting',{replace:true} );
			}else{
				navigate('/membership', { replace: true })
			}
		})
	};


	return (
		<div>
			<div id="login-page">
				<div className="login-form">
					<div className="login-form-inner">
						<div className="login-logo">
							<img src={logo} alt="Whatson" />
						</div>
						<div className="login-form-box">
							<form onSubmit={handleSubmit(onSubmit)}>
								<div className="mb-4">
									<label>Username</label>
									<input {...register("username",
										{ required: "Username is required" }
									)} type="text" id="username" className="form-control" placeholder="Username" />
									{errors.username && <span className="error password-error">{errors.username?.message}</span>}
								</div>
								<div className="mb-4 password-input-field">
									<label>Password</label>
									<input {...register("password",
										{ required: "Password is required" },
									)} 
									type={isVisible ? "password" : "text"}
									 id="password" className="form-control" 
									 placeholder="Password" />
									<span className="icon Password-hide" onClick={toggle}>
												{isVisible ? <FontAwesomeIcon icon={faEyeSlash} /> : <FontAwesomeIcon icon={faEye} />}
											</span>
									{errors.password && <span className="error password-error">{errors.password?.message}</span>}
								</div>
								<div className="mb-4 forgot-password">
									<label className="custom-check"> Remember my preference
										<input checked={rememberMe} onChange={() => setRememberMe(!rememberMe)} type="checkbox" />
										<span className="checkmark"></span>
									</label>
									<Link to="/forgot-password">Forgot username or Password?</Link>
								</div>
								<div className="mb-3">
									<button className="btn btn-dark full-btn btn-lg signin-btn">{
										loading ? <FontAwesomeIcon icon={faCircleNotch} spin /> : 'Login'
									}</button>
								</div>
							</form>
						</div>
						<div className="form-bottom-link">Don’t have an account? <Link to='/register'><b>Register Now</b></Link></div>
					</div>
				</div>
				<div className="login-img">
					<div className="login-img-inner">
						<div className="dashboard-title">
							<h3>WELCOME TO</h3>
							<h1>Your Restaurant Dashboard</h1>
							<p>Login to Access Dashboard</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Loginpage;
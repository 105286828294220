import { toast } from "react-toastify";
import { Cookies } from "react-cookie";
import CustomError from "../components/CustomError";

const cookies = new Cookies();
export const REACT_APP_LIMIT = 15;

const getHeaders = (isFormData) => {
  const headers = new Headers();
  if (cookies.get("userToken")) {
    headers.append("Authorization", "Bearer " + cookies.get("userToken"));
  }
  if (!isFormData) {
    headers.append("Content-Type", "application/json");
  }
  return headers;
};

const baseUrl = process.env.REACT_APP_API_URL;

const get = (endpoint, queryString = {}, callback) => {
  let fetchData = {
    method: "GET",
    headers: getHeaders(),
  };
  let url = baseUrl + endpoint;
  if (Object.keys(queryString).length) {
    let queryParams = new URLSearchParams(queryString);
    url += `?${queryParams}`;
  }
  fetch(url, fetchData)
    .then((response) => response.json())
    .then((data) => {
      if (data.success) {
        callback({ data });
      } else {
        callback({ err: data });
      }
    })
    .catch((err) => console.error(err));
};

const post = (endpoint, body, callback, args) => {
  let fetchData = {
    method: "POST",
    headers: getHeaders(args?.isFormData),
    body: JSON.stringify(body),
  };

  if (args?.isFormData) {
    let formData = new FormData();
    Object.keys(body).forEach((x) => {
      if (x.includes("pic") || x.includes("doc") || x.includes("image")) {
        formData.append(x, body[x], body[x].name);
      } else {
        formData.append(
          x,
          typeof body[x] === "object" ? JSON.stringify(body[x]) : body[x]
        );
      }
    });
    fetchData.body = formData;
  }
  let url = baseUrl + endpoint;

  fetch(url, fetchData)
    .then((response) => response.json())
    .then((data) => {
      if (data.success) {
        callback({ data });
        // toast.success(data.message || 'Saved Successfully', {
        //     position: "top-center",
        //     autoClose: 4500,
        //     hideProgressBar: false,
        //     closeOnClick: true,
        //     pauseOnHover: false,
        //     draggable: false,
        //     theme: "light",
        // });
        // CustomError(data.error,'')
      } else {
        callback({ err: data });
        CustomError(data.error, "");
        // toast.error(data.error, {
        //     position: "top-center",
        //     autoClose: 4500,
        //     hideProgressBar: false,
        //     closeOnClick: true,
        //     pauseOnHover: false,
        //     theme: "light",
        // });
      }
    })
    .catch((err) => {
      console.error(err);
      toast.error("Server error", {
        position: "top-center",
        autoClose: 4500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
        theme: "light",
      });
    });
};
const SubscriptionPosts = (endpoint, body, callback, args) => {
  let fetchData = {
    method: "POST",
    headers: getHeaders(args?.isFormData),
    body: JSON.stringify(body),
  };

  if (args?.isFormData) {
    let formData = new FormData();
    Object.keys(body).forEach((x) => {
      if (x.includes("pic") || x.includes("doc") || x.includes("image")) {
        formData.append(x, body[x], body[x].name);
      } else {
        formData.append(
          x,
          typeof body[x] === "object" ? JSON.stringify(body[x]) : body[x]
        );
      }
    });
    fetchData.body = formData;
  }
  let url = baseUrl + endpoint;

  fetch(url, fetchData)
    .then((response) => response.json())
    .then((data) => {
      if (data.success) {
        callback({ data });
        // toast.success(data.message || 'Saved Successfully', {
        //     position: "top-center",
        //     autoClose: 4500,
        //     hideProgressBar: false,
        //     closeOnClick: true,
        //     pauseOnHover: false,
        //     draggable: false,
        //     theme: "light",
        // });
        // CustomError(data.error,'')
      } else {
        callback({ err: data });
        if ((data.error.type = "StripeInvalidRequestError")) {
          CustomError(
            "Oops, your promotional link is no more valid. Please contact WhatsOn team if problem persist.",
            ""
          );
        } else {
          CustomError(data.error, "");
        }

        // toast.error(data.error, {
        //     position: "top-center",
        //     autoClose: 4500,
        //     hideProgressBar: false,
        //     closeOnClick: true,
        //     pauseOnHover: false,
        //     theme: "light",
        // });
      }
    })
    .catch((err) => {
      console.error(err);
      toast.error("Server error", {
        position: "top-center",
        autoClose: 4500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
        theme: "light",
      });
    });
};
const login = (endpoint, body, callback, args) => {
  let fetchData = {
    method: "POST",
    headers: getHeaders(args?.isFormData),
    body: JSON.stringify(body),
  };

  if (args?.isFormData) {
    let formData = new FormData();
    Object.keys(body).forEach((x) => {
      if (x.includes("pic") || x.includes("doc") || x.includes("image")) {
        formData.append(x, body[x], body[x].name);
      } else {
        formData.append(
          x,
          typeof body[x] === "object" ? JSON.stringify(body[x]) : body[x]
        );
      }
    });
    fetchData.body = formData;
  }
  let url = baseUrl + endpoint;

  fetch(url, fetchData)
    .then((response) => response.json())
    .then((data) => {
      if (data.success) {
        callback({ data });
      } else {
        callback({ err: data });
        CustomError(data.error, "");
        // toast.error(data.error, {
        //     position: "top-center",
        //     autoClose: 4500,
        //     hideProgressBar: false,
        //     closeOnClick: true,
        //     pauseOnHover: false,
        //     theme: "light",
        // });
      }
    })
    .catch((err) => {
      console.error(err);
    });
};
const update = (endpoint, body, callback) => {
  let fetchData = {
    method: "POST",
    headers: getHeaders(),
    body: JSON.stringify(body),
  };
  let url = baseUrl + endpoint;
  fetch(url, fetchData)
    .then((response) => response.json())
    .then((data) => callback({ data }))
    .catch((err) => console.error(err));
};

const remove = (endpoint, callback) => {
  let fetchData = {
    method: "DELETE",
    headers: getHeaders(),
  };
  let url = baseUrl + endpoint;

  fetch(url, fetchData)
    .then((response) => response.json())
    .then((data) => {
      if (data.success) {
        callback({ data });

        // toast.success(data.message || 'Deleted Successfully', {
        //     position: "top-right",
        //     autoClose: 7000,
        //     hideProgressBar: false,
        //     closeOnClick: true,
        //     pauseOnHover: false,
        //     draggable: false,
        //     theme: "light",
        // });
      } else {
        callback({ err: data });
        CustomError(data.error, "");
        // toast.error(data.error, {
        //     position: "top-right",
        //     autoClose: 1500,
        //     hideProgressBar: false,
        //     closeOnClick: true,
        //     pauseOnHover: false,
        //     theme: "light",
        // });
      }
    })
    .catch((err) => {
      console.error(err);
      CustomError("Server error", "");
      toast.error("Server error", {
        position: "top-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
        theme: "light",
      });
    });
};

const SubscriptionPost = (endpoint, body, callback, args) => {
  let fetchData = {
    method: "POST",
    headers: getHeaders(args?.isFormData),
    body: JSON.stringify(body),
  };

  if (args?.isFormData) {
    let formData = new FormData();
    Object.keys(body).forEach((x) => {
      if (x.includes("pic") || x.includes("doc")) {
        formData.append(x, body[x], body[x].name);
      } else {
        formData.append(
          x,
          typeof body[x] === "object" ? JSON.stringify(body[x]) : body[x]
        );
      }
    });
    fetchData.body = formData;
  }
  let url = baseUrl + endpoint;

  fetch(url, fetchData)
    .then((response) => response.json())
    .then((data) => {
      if (data.success) {
        callback({ data });
      } else {
        callback({ err: data });
      }
    })
    .catch((err) => {
      console.error(err);
    });
};

export default {
  get,
  login,
  post,
  update,
  remove,
  SubscriptionPost,
  SubscriptionPosts,
};

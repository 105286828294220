import { confirmAlert } from "react-confirm-alert";

function CustomSuccess(title,heading) {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
        <div className='custom-ui'>
      
          <h1>{heading}</h1>
          <p>{title}</p>				
          <div class="react-confirm-alert-button-group"
          >
          
          <button onClick={() => {
            // setLoading(false)
            onClose();
          }} label="OK">OK</button>
          </div>
        </div>
        );
      }
      })
  }
  export default CustomSuccess
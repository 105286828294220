import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import apiService from "../../core/apiService";
import { useSelector } from "react-redux";
import Select from 'react-select';
import {
	dropDownOption
} from "../../helpers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import Multiselect from "multiselect-react-dropdown";

const OnTapUpdate = () => {
	const restaurant = useSelector((state) => state.restaurant);
	const [Step, setStep] = useState(0);
	const [createdSuccess, setcreatedSuccess] = useState(false);
	const navigate = useNavigate();
	const location = useLocation();
	const fromPage = location.state?.from?.pathname || "/on-tap/drink";
	const [isFormTouched, setIsFormTouched] = useState(false);
	const createPage = location.state?.from?.pathname || "/on-tap/drink";
	const [liquorType, setliquorType] = useState("1");
	const [drinkList, setDrinkList] = useState([]);
	const [containerList, setContainerList] = useState([]);
	const [regionList, setRegionList] = useState([]);
	const [wineTypeList, setwineTypeList] = useState([]);
	const [wineSubTypeList, setwineSubTypeList] = useState([]);
	// const [discountType, setDiscountType] = useState("");
	// const [discountPrice, setDiscountPrice] = useState("");
	const [description, setDescription] = useState("");
	const [region, setRegion] = useState("1");
	const [winetype, setWineType] = useState("1");
	const [winename, setWine] = useState("1")
	const [winesubtype, setWineSubType] = useState(0);
	const [container, setContainer] = useState("1");
	const [drinkname, setDrinkname] = useState("1");
	const [typeShow, setTypeShow] = useState(false);
	const [drinkNameText, setDrinkNameText] = useState('');
	const [drinknameError, setDrinkNameError] = useState("");
	const [wineTypes, setMasterType] = useState([])
	const { id, liqType } = useParams();
	const [isLoading, setIsLoading] = useState(false);
	const [drinkData, setDrinkData] = useState([])
	const [drinkLists, setDrinkLists] = useState([]);
	const [drink, setDrink] = useState([])
	const [selectedTag, setselectedTag] = useState([])
	const [tags, setTags] = useState([]);
	const [standared, setSelectedStanderd] = useState([])
	const [tagid, setResTagid] = useState("[]")
	const [resTag, setResTags] = useState("[]")
	const special_id = "4";
	const type_id = "2";
	const restaurantspecial_id = id;
	const Priceoption = dropDownOption();

	const nextStep = () => {
		setStep(Step + 1);
	};
	const backStep = () => {
		setStep(Step - 1);
	};

	const back = () => {
		navigate(createPage, { replace: true });
	};

	useEffect(() => {
		apiService.get(
			`/restaurants/getspecial`,
			{ id: restaurantspecial_id },
			({ err, data }) => {
				if (err) return console.log(err);
				setliquorType(data.data.drinktype_id);
				// setDiscountType(data.data.discount_type);
				// setDiscountPrice(data.data.discount_amount);
				setDescription(data.data.item_description);
				setWine(data.data.masterwine_id)
				setRegion(data.data.region_id);
				setWineType(data.data.wine_type_id);
				setWineSubType(data.data.wine_subtype_id);
				setContainer(data.data.container_type_id);
				setDrinkname(data.data.drink_name_id);
				setDrinkNameText(data.data.drink_name);
				// setResTagid(data.data.restauranttag.tags_ids)
				// setResTags(data.data.restauranttag.drink_ids)
				// if (data.data.wine_type_id !== 10) setTypeShow(true);
			}
		);
		apiService.get(`/restaurants/drinkTypes`, {}, ({ err, data }) => {
			if (err) return console.log(err);
			setDrinkList(data.data);
		});
		apiService.get(`/restaurants/get-container`, {}, ({ err, data }) => {
			if (err) return console.log(err);
			setContainerList(data.data);
		});
		apiService.get(`/restaurants/get-region`, {}, ({ err, data }) => {
			if (err) return console.log(err);
			setRegionList(data.data);
		});
		apiService.get(`/restaurants/get-winetype`, {}, ({ err, data }) => {
			if (err) return console.log(err);
			setwineTypeList(data.data);
		});
		apiService.get(`/restaurants/get-winesubtype`, {}, ({ err, data }) => {
			if (err) return console.log(err);
			setwineSubTypeList(data.data);
		});
		apiService.get(`/listMasterWine`, {}, ({ err, data }) => {
			if (err) return console.log(err);
			data.data.splice(data.data.length - 1, 1)
			console.log(data.data, "datdatda")
			setMasterType(data.data);
		});
	}, []);

	const handleSubmit = () => {
		setIsFormTouched(true);
		if (!description) return;
		if (liquorType === 4 && drinkname <= 0) return setDrinkNameError('Drink Name is required!');
		if (parseInt(liquorType) === 1 && !filterWineType.length) return;
		if (liquorType == '1' && winetype == 0) return

		// let drink_ids = {}
		// if (standared.length == 0) {
		// 	drink_ids = { "drink_ids": JSON.stringify([drinkname]), tags_ids: JSON.stringify([...selectedTag.map(item => item.value)]) }
		// } else
		// 	drink_ids = { "drink_ids": JSON.stringify([...standared.map(item => item.value)]), tags_ids: JSON.stringify([...selectedTag.map(item => item.value)]) }

		let data = {
			'restaurant_id': restaurant?.id,
			'special_id': special_id,
			'type_id': type_id,
			'drinktype_id': liquorType,
			'region_id': region,
			'wine_subtype_id': winesubtype,
			'wine_type_id': winetype,
			'container_type_id': container,
			// 'discount_type': discountType,
			// 'discount_amount': discountPrice,
			'item_description': description,
			'drink_name_id': drinkname,
			'drink_name': drinkNameText,
			'masterwine_id': winename
		};

		console.log(data, 'datdatdaa')



		setIsLoading(true)
		apiService.post(`/restaurants/specials/food-update/${restaurantspecial_id}`, data, ({ err, data }) => {
			if (err) {
				console.log(err);
				setIsLoading(false)
			};
			setIsLoading(false)
			setcreatedSuccess(createdSuccess === false ? true : false);
			setTimeout(() => {
				navigate(`/on-tap/drink?type=${liquorType}`, { replace: true });
			}, 5000);
		})
	}; useEffect(() => {
		apiService.get(`/listDrinkTags`, { drink_type: liquorType }, ({ err, data }) => {
			if (err) return console.log(err);

			let res = [];
			data.data?.map((item) => {
				const temp = {};
				temp["label"] = `${item["tag_name"]}`;
				temp["value"] = item["id"];
				res.push(temp);
			});


			setDrinkData(res);
		});
		apiService.get(`/listDrinkNames`, { drink_type: liquorType }, ({ err, data }) => {
			// let Name = ""
			// if (liquorType == 1) Name = "Multiple Wines"
			// if (liquorType == 2) Name = "Multiple Beers"
			// if (liquorType == 3) Name = "Multiple Cider"
			// if (liquorType == 4) Name = "Multiple Others"
			if (err) return console.log(err);
			let temp = data.data?.map((drinkname) => ({
				value: drinkname.id,
				label: drinkname.drink_name,
			}))
			setDrinkLists(temp);
			let res = [];
			data.data?.map((item) => {

				const temp = {};
				temp["label"] = `${item["drink_name"]}`;
				temp["value"] = item["id"];
				res.push(temp);
			});
			setDrink(res)

		});
		// apiService.get(`/listTagss`, {type:liquorType}, ({ err, data }) => {
		// 	console.log(data.data, 'tags')
		// 	if (err) return console.log(err);
		// 	let res = [];
		// 	data.data?.map((item) => {
		// 		const temp = {};
		// 		temp["label"] = `${item["tag_name"]}`;
		// 		temp["value"] = item["id"];
		// 		res.push(temp);
		// 	});
		// 	setTags(res);
		// });

	}, [liquorType])

	// useEffect(() => {
	// 	let res1 = []

	// 	JSON.parse(resTag).map(item => {

	// 		res1.push(...drink?.filter(drinkid => drinkid.value == item))
	// 	});

	// 	setSelectedStanderd(res1);

	// 	let res = []

	// 	JSON.parse(tagid).map(item => {
	// 		console.log(tagid, item, 'in map')
	// 		res.push(...tags?.filter(tagid => tagid.value == item))

	// 	});

	// 	setselectedTag(res);
	// }, [tagid.length, tags, drink.length, resTag])

	let filterWineType = wineTypeList?.filter(drink => drink.masterwine_id == winename)




	return (
		<>
			<div className="dashboard-auto-wrapper">
				<div className="create-from">
					{Step === 0 && liqType === "1" && (
						<div className="create-from-steps active">
							<h4>Wine</h4>
							<div className="create-form-inner mt-4">
								<div className="form-group mb-4">
									<label className="form-label">Region</label>
									<select
										className="form-control"
										key={region}
										value={region}
										onChange={(e) => setRegion(e.target.value)}
									>
										{regionList.length ? (
											regionList.map((region, i) => (
												<option value={region.id}>{region.region_name}</option>
											))
										) : (
											<></>
										)}
									</select>
								</div>
								<div className="form-group mb-4">
									<label className="form-label">Red/White</label>
									<select
										className="form-control"
										key={winename}
										value={winename}
										onChange={(e) => {
											setWine(e.target.value)
											setWineType(0)
											// {
											// 	e.target.value !== "10"
											// 		? setTypeShow(true)
											// 		: setTypeShow(false); setWineSubType(0)
											// }
										}}
									>
										{wineTypes?.length ? (
											wineTypes.map((wine, i) => (
												<option value={wine.id}>{wine.wine_type_name}</option>
											))
										) : (
											<></>
										)}
									</select>
								</div>

								<div className="form-group mb-4">
									<label className="form-label">Type</label>
									<select
										className="form-control"
										key={winetype}
										value={winetype}
										onChange={(e) => setWineType(e.target.value)}
									>
										<option value='0'>Select </option>
										{wineTypeList?.length ?
											(
												(
													filterWineType?.length ? (
														filterWineType.map((wine, i) => (
															<option value={wine.id}>{wine.wine_name}</option>
														))
													) : (
														<option disabled value='' >
															No matching Winetype found
														</option>
													)

												)

											) : (
												<></>
											)}
									</select>
									{
										(isFormTouched && liquorType == '1' && winetype == 0) && <span className="error">Type is required!</span>
									}
								</div>

								<div className="form-group mb-4">
									<label className="form-label">Name</label>
									<Select
										classNamePrefix="form-control"
										// key={winesubtype}

										onChange={(selectedOption) => setWineSubType(selectedOption.value)}
										options={wineSubTypeList.map((winetype) => ({
											value: winetype.id,
											label: winetype.winesubtype_name,
										}))}
										value={wineSubTypeList.map((winetype) => ({
											value: winetype.id,
											label: winetype.winesubtype_name,
										})).filter(function (option) {
											return option.value === winesubtype;
										})}
									/>
								</div>
								{/* <div className="form-group mb-4"> 
									{/* <label className="form-label">Price</label> */}
								{/* <div className="form-group d-flex align-items-center flex-row mb-2">
										{/* <label className="custom-radio mb-0">
											Percentage
											<input
												type="radio"
												name="discountType"
												key={discountType === 'percentage' ? 'percentage' : ''}
												defaultChecked={discountType === 'percentage' ? 'percentage' : ''}
												onChange={(e) => setDiscountType("percentage")}
											/>
											<span className="checkmark"></span>
										</label>
										<label className="custom-radio ms-3 mb-0">
											Price
											<input
												type="radio"
												name="discountType"
												key={discountType === 'price' ? 'price' : ''}
												defaultChecked={discountType === 'price' ? 'price' : ''}
												onChange={(e) => setDiscountType("price")}
											/>
											<span className="checkmark"></span>
										</label>
										<label className="custom-radio ms-3 mb-0">
											Amount Off
											<input
												type="radio"
												name="discountType"
												key={discountType === 'amount' ? 'amount' : ''}
												defaultChecked={discountType === 'amount' ? 'amount' : ''}
												onChange={(e) => setDiscountType("amount")}
											/>
											<span className="checkmark"></span>
										</label> */}
								{/* </div>} */}
								{/* <Select
										classNamePrefix="form-control"
										name="discountPrice"
										onChange={(e) => setDiscountPrice(e.value)}
										options={Priceoption}
										value={Priceoption.filter(function (option) {
											return option.value === discountPrice;
										})}
									/> */}
								{/* </div> */}
								<div className="form-group mb-4">
									<label className="form-label">Description(255 characters)</label>
									<textarea maxLength={255}
										className="form-control"
										name="description"
										onChange={(e) => setDescription(e.target.value)}
										value={description}
									/>
									{
										(isFormTouched && !description) && <span className="error">Description is required!</span>
									}
								</div>
							</div>
							<div className="create-from-btn mt-5">
								<button className="btn btn-transparent" onClick={back}>
									Back
								</button>
								<button className="btn btn-dark" onClick={handleSubmit}>
									{
										isLoading ? <FontAwesomeIcon icon={faCircleNotch} spin /> : 'Update'

									}
								</button>
							</div>
						</div>
					)}
					{Step === 0 && liqType === "2" && (
						<div className="create-from-steps active">
							<h4>Beer</h4>
							<div className="create-form-inner mt-4">
								<div className="form-group mb-4">
									<label className="form-label">Name</label>
									<Select
										classNamePrefix="form-control"
										onChange={(selectedOption) => setDrinkname(selectedOption.value)}
										options={drinkLists}
										value={drinkLists.filter(function (option) {

											return option.value === drinkname;
										})}

									/>
									<div className="d-flex align-items-center  graph-sets mt-2">

										<p> <b>Note:</b> Don’t see your option here? Submit a request to admin.</p>
									</div>
								</div>
								<div className="form-group mb-4">
									<label className="form-label">Container Type</label>
									<select
										className="form-control"
										key={container}
										value={container}
										onChange={(e) => setContainer(e.target.value)}
									>
										{containerList.length ? (
											containerList?.filter(item => item.Drink_type_id == liquorType)?.map((container, i) => (
												<option value={container.id}>
													{container.container_name}
												</option>
											))
										) : (
											<></>
										)}
									</select>
								</div>

								<div className="form-group mb-4">
									<label className="form-label">Description(255 characters)</label>
									<textarea maxLength={255}
										className="form-control"
										name="description"
										onChange={(e) => setDescription(e.target.value)}
										value={description}
									/>
									{
										(isFormTouched && !description) && <span className="error">Description is required!</span>
									}
								</div>


							</div>
							<div className="create-from-btn mt-5">
								<button className="btn btn-transparent" onClick={back}>
									Back
								</button>
								<button className="btn btn-dark" disabled={isLoading} onClick={handleSubmit}>
									{
										isLoading ? <FontAwesomeIcon icon={faCircleNotch} spin /> : 'Update'

									}
								</button>
							</div>
						</div>
					)}
					{Step === 0 && liqType === "3" && (
						<div className="create-from-steps active">
							<h4>Cider</h4>
							<div className="create-form-inner mt-4">
								<div className="form-group mb-4">
									<label className="form-label">Name</label>
									<Select
										classNamePrefix="form-control"
										onChange={(selectedOption) => setDrinkname(selectedOption.value)}
										options={drinkLists}
										value={drinkLists.filter(function (option) {

											return option.value === drinkname;
										})}

									/>
									<div className="d-flex align-items-center  graph-sets mt-2">

										<p> <b>Note:</b> Don’t see your option here? Submit a request to admin.</p>
									</div>
								</div>
								<div className="form-group mb-4">
									<label className="form-label">Container Type</label>
									<select
										className="form-control"
										key={container}
										value={container}
										onChange={(e) => setContainer(e.target.value)}
									>
										{containerList.length ? (
											containerList?.filter(item => item.Drink_type_id == liquorType)?.map((container, i) => (
												<option value={container.id}>
													{container.container_name}
												</option>
											))
										) : (
											<></>
										)}
									</select>
								</div>

								<div className="form-group mb-4">
									<label className="form-label">Description(255 characters)</label>
									<textarea maxLength={255}
										className="form-control"
										name="description"
										onChange={(e) => setDescription(e.target.value)}
										value={description}
									/>
									{
										(isFormTouched && !description) && <span className="error">Description is required!</span>
									}
								</div>
								{/* <div className="col-md-12 mb-4">
									<div className="form-group">
										<div className="input-right-icon">
											<label className="form-label">Special Tags</label>
											<Multiselect
												placeholder="Select Special Tags"
												options={drinkData}
												displayValue="label"
												selectedValues={selectedTag}
												onSelect={handleOnchangeSpecials}
												onRemove={(selectedList, removedItem) => {
													setselectedTag(selectedList)
												}}
											/>
										</div>
									</div>
								</div>

								<div className="col-md-12">
									<div className="form-group">
										<div className="input-right-icon">
											<label className="form-label">Standard Tags</label>
											<Multiselect
												placeholder="Select Cider Tags"
												options={drink.filter((item => item.value == drinkname))?.length ? drink.filter((item => item.value == drinkname)) : drink}
												// // options={food.filter((item => item.value == foodCat))?.length ? food.filter((item => item.value == foodCat)) : food}
												onSelect={handleOnchangeStand}
												// disablePreSelectedValues={!!drink.filter((item => item.value == drinkname)).length}
												displayValue="label"
												selectedValues={drinkname !== 0 ? drink.filter((item => item.value == drinkname)) : standared}
												onRemove={(selectedList, removedItem) => {
													setSelectedStanderd(selectedList)
												}}
											/>
										</div>
									</div>
								</div> */}
							</div>
							<div className="create-from-btn mt-5">
								<button className="btn btn-transparent" onClick={back}>
									Back
								</button>
								<button className="btn btn-dark" onClick={handleSubmit}>
									{
										isLoading ? <FontAwesomeIcon icon={faCircleNotch} spin /> : 'Update'

									}
								</button>
							</div>
						</div>
					)}
					{Step === 0 && liqType === "4" && (
						<div className="create-from-steps active">
							<h4>Other</h4>
							<div className="create-form-inner mt-4">
								<div className="form-group mb-4">
									<label className="form-label">Name</label>
									<Select
										classNamePrefix="form-control"
										onChange={(selectedOption) => setDrinkname(selectedOption.value)}
										options={drinkLists}
										value={drinkLists.filter(function (option) {

											return option.value === drinkname;
										})}

									/>
									<span className="error">{drinknameError}</span>
									<div className="d-flex align-items-center  graph-sets mt-2">

										<p> <b>Note:</b> Don’t see your option here? Submit a request to admin.</p>
									</div>
								</div>

								<div className="form-group mb-4">
									<label className="form-label">Description(255 characters)</label>
									<textarea maxLength={255}
										className="form-control"
										name="description"
										onChange={(e) => setDescription(e.target.value)}
										value={description}
									/>
									{
										(isFormTouched && !description) && <span className="error">Description is required!</span>
									}
								</div>



							</div>
							<div className="create-from-btn mt-5">
								<button className="btn btn-transparent" onClick={backStep}>
									Back
								</button>
								<button className="btn btn-dark" onClick={handleSubmit}>
									{
										isLoading ? <FontAwesomeIcon icon={faCircleNotch} spin /> : 'Update'

									}
								</button>
							</div>
						</div>
					)}
					{createdSuccess === true ? (
						<>
							<div className="common-message-pop show" id="created-success-pop">
								<div className="common-pop-inner">
									<div className="common-pop-box created-message-box">
										<h2>Cheers!</h2>
										<p className="message-txt">
											You just updated to your inventory of drinks !!
										</p>
									</div>
								</div>
							</div>
						</>
					) : (
						<></>
					)}
				</div>
			</div>
		</>
	);
};

export default OnTapUpdate;

import React, { useEffect, useState } from 'react';
import apiService from '../core/apiService';
import SpecialLists from './SpecialsList';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

const DrinkSpecialLists = ({ urlParams, selectedSpecial }) => {
    const [drinkList, setDrinkList] = useState([]);
    const [loading, setLoading] = useState(true);
    const [selectedDrink, setSelectdDrink] = useState();
    const drinkColumns = {
        Wine: [
            { name: 'Sport', dataIndex: 'sport?.sports_name' },
            { name: 'Team', dataIndex: 'sportsteam?.team_name' },
            { name: 'Day', dataIndex: 'day?.day_name' },
            { name: 'Start Time', dataIndex: 'restaurantspecialshour?.start_time' },
            { name: 'End Time', dataIndex: 'restaurantspecialshour?.end_time' },
            { name: 'Region', dataIndex: 'region?.region_name' },
            { name: 'Red/White', dataIndex: 'winetype?.winetype_name' },
            { name: 'Wine Type', dataIndex: 'winetype?.winetype_name' },
            { name: 'Container Type', dataIndex: 'container?.container_name' },
            { name: 'Size', dataIndex: 'size?.size_name' },
            { name: 'Wine Name', dataIndex: 'drinkname?.drink_name' },
            { name: 'Price', dataIndex: 'discount_amount' },
            { name: 'Description', dataIndex: 'item_description' },
            { name: 'Action', dataIndex: 'action' },
        ],
        Beer: [
            { name: 'Sport', dataIndex: 'sport?.sports_name' },
            { name: 'Team', dataIndex: 'sportsteam?.team_name' },
            { name: 'Day', dataIndex: 'day?.day_name' },
            { name: 'Start Time', dataIndex: 'restaurantspecialshour?.start_time' },
            { name: 'End Time', dataIndex: 'restaurantspecialshour?.end_time' },
            { name: 'Name', dataIndex: 'drinkname?.drink_name' },
            { name: 'Container Type', dataIndex: 'container?.container_name' },
            { name: 'Size', dataIndex: 'size?.size_name' },
            { name: 'Price', dataIndex: 'discount_amount' },
            { name: 'Description', dataIndex: 'item_description' },
            { name: 'Action', dataIndex: 'action' },
        ],
        Cider: [
            { name: 'Sport', dataIndex: 'sport?.sports_name' },
            { name: 'Team', dataIndex: 'sportsteam?.team_name' },
            { name: 'Day', dataIndex: 'day?.day_name' },
            { name: 'Start Time', dataIndex: 'restaurantspecialshour?.start_time' },
            { name: 'End Time', dataIndex: 'restaurantspecialshour?.end_time' },
            { name: 'Name', dataIndex: 'drinkname?.drink_name' },
            { name: 'Container Type', dataIndex: 'container?.container_name' },
            { name: 'Price', dataIndex: 'discount_amount' },
            { name: 'Description', dataIndex: 'item_description' },
            { name: 'Action', dataIndex: 'action' },
        ]
    };

    const getDailySpecialColumn = () => {
        let notUseColumns = ['Sport', 'Team', 'Start Time', 'End Time'];
        let columnArray = drinkColumns[selectedDrink.drinktype_name];
        return columnArray.filter(x => !notUseColumns.includes(x.name))
    }
    const getGameSpecialColumn = () => {
        let notUseColumns = ['Sport', 'Team', 'Start Time', 'End Time'];
        let columnArray = drinkColumns[selectedDrink.drinktype_name];
        return columnArray.filter(x => !notUseColumns.includes(x.name))
    }
    const getHappyHourColumn = () => {
        let notUseColumns = ['Sport', 'Team', 'Wine Name'];
        let columnArray = drinkColumns[selectedDrink.drinktype_name];
        return columnArray.filter(x => !notUseColumns.includes(x.name))
    }
    const getOnTapColumn = () => {
        let notUseColumns = ['Day', 'Sport', 'Team', 'Start Time', 'End Time'];
        let columnArray = drinkColumns[selectedDrink.drinktype_name];
        return columnArray.filter(x => !notUseColumns.includes(x.name))
    };

    const renderColumns = () => {
        if(selectedSpecial === 'on-tap') return getOnTapColumn();
        if(selectedSpecial === 'happy-hour') return getHappyHourColumn();
        if(selectedSpecial === 'game-day-special') return getGameSpecialColumn();
        if(selectedSpecial === 'daily-special') return getDailySpecialColumn();
    }


    useEffect(() => {
        setLoading(true);
        apiService.get('/restaurants/drinkTypes', {}, ({ err, data }) => {
            if (err) return console.log(err);
            setDrinkList(data.data);
            setSelectdDrink(data.data[0])
            setLoading(false);
        })
    }, []);

    const onTabSelect = (val) => {
        let selected = drinkList.find(x => x.drinktype_name === val);
        setSelectdDrink(selected);
    }

    return (
        <div className="tab-content" id="common-tabs-section">
            <Tabs onSelect={onTabSelect} id="daily-special-tabs" className="mb-3">
                {
                    !loading &&

                    drinkList.map((drink, index) => (
                        <Tab key={index} eventKey={drink.drinktype_name} title={drink.drinktype_name}>
                            {
                                selectedDrink?.id === drink.id ?
                                    <SpecialLists
                                        columns={renderColumns()}
                                        urlParams={{ ...urlParams, drinktype_id: selectedDrink?.id }}
                                    />
                                    : <></>
                            }
                        </Tab>
                    ))
                }

            </Tabs>
        </div>
    )
};

export default DrinkSpecialLists;
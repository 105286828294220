import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';


const FoodForm = ({
    onBack
}) => {
    const navigate = useNavigate();
    const location = useLocation();
    const fromPage = location.state?.from?.pathname || '/game-day-special/food';

    const handleSubmit = (event) => {
        event.preventDefault();
        navigate(fromPage, { replace: true })
    };


    const onBackClick = () => {
        onBack();
    }

    return (
        <div className="create-from-steps active">
            <h4>Food!</h4>
            <div className="create-form-inner mt-4">
                <div className="form-group mb-4">
                    <label className="form-label">Food Category</label>
                    <select className="form-control">
                        <option></option>
                    </select>
                </div>
                <div className="form-group mb-4">
                    <label className="form-label">Discount</label>
                    <div className="form-group d-flex align-items-center flex-row mb-2">
                        <label className="custom-radio mb-0">Percentage
                            <input type="radio" name="price" checked />
                            <span className="checkmark"></span>
                        </label>
                        <label className="custom-radio ms-3 mb-0">Price
                            <input type="radio" name="price" />
                            <span className="checkmark"></span>
                        </label>
                        <label className="custom-radio ms-3 mb-0">Amount Off
                            <input type="radio" name="price" />
                            <span className="checkmark"></span>
                        </label>
                    </div>
                    <select className="form-control">
                        <option></option>
                    </select>
                </div>
                <div className="form-group mb-4">
                    <label className="form-label">Food item description</label>
                    <input className="form-control" />
                </div>
            </div>
            <div className="create-from-btn mt-5">
                <button onClick={onBackClick} className="btn btn-transparent">Back</button>
                <button className="btn btn-dark" onClick={handleSubmit}>Save</button>
            </div>
        </div>
    );
};

export default FoodForm;
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import apiService from '../../core/apiService';


const CreateSpecial = () => {
	const [Step, setStep] = useState(0);
	const [createdSuccess, setcreatedSuccess] = useState(false);
	const navigate = useNavigate();
	const location = useLocation();
	const fromPage = location.state?.from?.pathname || '/daily-special/food';
	const createPage = location.state?.from?.pathname || '/create-special';
	const [foodType, setfoodType] = useState("food");
	const [liquorType, setliquorType] = useState("wine");
	const [dayList, setDayList] = useState([]);

	const nextStep = () => {
		setStep(Step + 1);
	};
	const backStep = () => {
		setStep(Step - 1);
	};

	const handleSubmit = () => {
		setcreatedSuccess(createdSuccess === false ? true : false);
		setTimeout(() => {
			navigate(fromPage, { replace: true })
		}, 3000);
	}

	const changeType = (e) => {
		setfoodType(e.target.value);
	};

	const changeliquorType = (e) => {
		setliquorType(e.target.value);
	};

	const back = () => {
		navigate(createPage, { replace: true })
	};

	useEffect(() => {
		apiService.get(`/restaurants/get-days`, {}, ({ err, data }) => {
			if (err) return console.log(err);
			setDayList(data.data);
		  });
	}, []);


	return (
		<>
			<div className="dashboard-auto-wrapper">
				<div className="create-from">
					{Step === 0 &&
						<div className="create-from-steps active">
							<h4>What day is your Special?
</h4>
							<div className="create-form-inner mt-4">
								<div className="form-group mb-3">
									<div className="d-flex flex-row justify-content-between">
										{
											dayList.length ?
												dayList.map((day, i) => (
													<label className="custom-check">{day.day_name}
														<input type="radio" name="day" value={day.id}/>
														<span className="checkmark"></span>
													</label>
												)) :
												<></>
										}
									</div>
								</div>
							</div>
							<div className="create-from-btn">
								<button className="btn btn-transparent" onClick={back}>Back</button>
								<button className="btn btn-dark" onClick={nextStep}>Next</button>
							</div>
						</div>
					}
					{Step === 1 &&
						<div className="create-from-steps active">
							<h4>Hey, tell me what is this special for?</h4>
							<div className="create-form-inner mt-4">
								<div className="form-group d-flex align-items-center flex-row mb-4">
									<label className="custom-radio mb-0">Food
										<input type="radio" checked={foodType === 'food'} onChange={changeType} value="food" name="deals" />
										<span className="checkmark"></span>
									</label>
									<label className="custom-radio ms-3 mb-0">Drinks
										<input type="radio" checked={foodType === 'liquor'} onChange={changeType} value="liquor" name="deals" />
										<span className="checkmark"></span>
									</label>
								</div>
							</div>
							<div className="create-from-btn">
								<button className="btn btn-transparent" onClick={backStep}>Back</button>
								<button className="btn btn-dark" onClick={nextStep}>Next</button>
							</div>
						</div>
					}
					{Step === 2 && foodType === 'food' &&
						<div className="create-from-steps active">
							<h4>Food!</h4>
							<div className="create-form-inner mt-4">
								<div className="form-group mb-4">
									<label className="form-label">Food Category</label>
									<select className="form-control">
										<option></option>
									</select>
								</div>
								<div className="form-group mb-4">
									<label className="form-label">Discount</label>
									<div className="form-group d-flex align-items-center flex-row mb-2">
										<label className="custom-radio mb-0">Percentage
											<input type="radio" name="price" checked />
											<span className="checkmark"></span>
										</label>
										<label className="custom-radio ms-3 mb-0">Price
											<input type="radio" name="price" />
											<span className="checkmark"></span>
										</label>
										<label className="custom-radio ms-3 mb-0">Amount Off
											<input type="radio" name="price" />
											<span className="checkmark"></span>
										</label>
									</div>
									<select className="form-control">
										<option></option>
									</select>
								</div>
								<div className="form-group mb-4">
									<label className="form-label">Food item description</label>
									<input className="form-control" />
								</div>
							</div>
							<div className="create-from-btn mt-5">
								<button className="btn btn-transparent" onClick={backStep}>Back</button>
								<button className="btn btn-dark" onClick={handleSubmit}>Save</button>
							</div>
						</div>
					}

					{Step === 2 && foodType === 'liquor' &&
						<div className="create-from-steps active">
							<h4>Please Select Type</h4>
							<div className="create-form-inner mt-4">
								<div className="form-group mb-4">
									<label className="form-label">Type</label>
									<select value={liquorType} onChange={changeliquorType} className="form-control">
										<option value="wine">Wine</option>
										<option value="beer">Beer</option>
										<option value="cider">Cider</option>
									</select>
								</div>
							</div>
							<div className="create-from-btn mt-5">
								<button className="btn btn-transparent" onClick={backStep}>Back</button>
								<button className="btn btn-dark" onClick={nextStep}>Next</button>
							</div>
						</div>
					}
					{Step === 3 && foodType === 'liquor' && liquorType === 'wine' &&
						<div className="create-from-steps active">
							<h4>Wine</h4>
							<div className="create-form-inner mt-4">
								<div className="form-group mb-4">
									<label className="form-label">Region</label>
									<select className="form-control">
										<option></option>
									</select>
								</div>
								<div className="form-group mb-4">
									<label className="form-label"> Red/White</label>
									<select className="form-control">
										<option></option>
									</select>
								</div>
								<div className="form-group mb-4">
									<label className="form-label">Type</label>
									<select className="form-control">
										<option></option>
									</select>
								</div>
								<div className="form-group mb-4">
									<label className="form-label">Container Type</label>
									<select className="form-control">
										<option></option>
									</select>
								</div>
								<div className="form-group mb-4">
									<label className="form-label">Size</label>
									<select className="form-control">
										<option></option>
									</select>
								</div>
								<div className="form-group mb-4">
									<label className="form-label">Discount</label>
									<div className="form-group d-flex align-items-center flex-row mb-2">
										<label className="custom-radio mb-0">Percentage
											<input type="radio" name="price" checked />
											<span className="checkmark"></span>
										</label>
										<label className="custom-radio ms-3 mb-0">Price
											<input type="radio" name="price" />
											<span className="checkmark"></span>
										</label>
										<label className="custom-radio ms-3 mb-0">Amount Off
											<input type="radio" name="price" />
											<span className="checkmark"></span>
										</label>
									</div>
									<select className="form-control">
										<option></option>
									</select>
								</div>
								<div className="form-group mb-4">
									  <label className="form-label">Description(255 characters)</label>
									<input className="form-control" />
								</div>
							</div>
							<div className="create-from-btn mt-5">
								<button className="btn btn-transparent" onClick={backStep}>Back</button>
								<button className="btn btn-dark" onClick={handleSubmit}>save</button>
							</div>
						</div>
					}
					{Step === 3 && foodType === 'liquor' && liquorType === 'beer' &&
						<div className="create-from-steps active">
							<h4>Beer</h4>
							<div className="create-form-inner mt-4">
								<div className="form-group mb-4">
									<label className="form-label">Name</label>
									<select className="form-control">
										<option></option>
									</select>
								</div>
								<div className="form-group mb-4">
									<label className="form-label">Container Type</label>
									<select className="form-control">
										<option></option>
									</select>
								</div>
								<div className="form-group mb-4">
									<label className="form-label">Size</label>
									<select className="form-control">
										<option></option>
									</select>
								</div>
								<div className="form-group mb-4">
									<label className="form-label">Price</label>
									<select className="form-control">
										<option></option>
									</select>
								</div>
								<div className="form-group mb-4">
									  <label className="form-label">Description(255 characters)</label>
									<input className="form-control" />
								</div>
							</div>
							<div className="create-from-btn mt-5">
								<button className="btn btn-transparent" onClick={backStep}>Back</button>
								<button className="btn btn-dark" onClick={handleSubmit}>save</button>
							</div>
						</div>
					}
					{Step === 3 && foodType === 'liquor' && liquorType === 'cider' &&
						<div className="create-from-steps active">
							<h4>Cider</h4>
							<div className="create-form-inner mt-4">
								<div className="form-group mb-4">
									<label className="form-label">Name</label>
									<select className="form-control">
										<option></option>
									</select>
								</div>
								<div className="form-group mb-4">
									<label className="form-label">Container Type</label>
									<select className="form-control">
										<option></option>
									</select>
								</div>
								<div className="form-group mb-4">
									<label className="form-label">Size</label>
									<select className="form-control">
										<option></option>
									</select>
								</div>
								<div className="form-group mb-4">
									<label className="form-label">Price</label>
									<select className="form-control">
										<option></option>
									</select>
								</div>
								<div className="form-group mb-4">
									  <label className="form-label">Description(255 characters)</label>
									<input className="form-control" />
								</div>
							</div>
							<div className="create-from-btn mt-5">
								<button className="btn btn-transparent" onClick={backStep}>Back</button>
								<button className="btn btn-dark" onClick={handleSubmit}>save</button>
							</div>
						</div>
					}
					{createdSuccess === true ?
						<>
							<div className="common-message-pop show" id="created-success-pop">
								<div className="common-pop-inner">
									<div className="common-pop-box created-message-box">
										<h2>Cheers!</h2>
										<p className="message-txt">You just Created Specials!!</p>
									</div>
								</div>
							</div>
						</> : <></>
					}
				</div>
			</div>
		</>
	);
};

export default CreateSpecial;
const initialState = {
    userData: null,
    restaurant: null,
    // step: 1,
    foodFilters: {
        foodCategory: [],
        days: [],
        sports: [],
        teams: [],
        discountTypes: []
    }
};

export default function rootReducer(state = initialState, action) {
    switch (action.type) {
        case "onLogin":
            return {
                ...state,
                userData: action.payload,
                restaurant: action.payload.restaurant,
                subscribe: action.payload.subscribe
            };
            
        case 'SET_SUBSCRIBE':
            return {
                ...state,
                subscribe: action.payload
            }
        case "onRegister":
            return {
                ...state,
                userData: action.payload,
                restaurant: action.payload.restaurant
            };
        // case "onStep":
        //     return {
        //         ...state,
        //         step: action.payload
        //     };
        case "LOGOUT":
            return {
                ...state,
                userData: null,
                restaurant: null,
                subscribe:null
            };
        case "UPDATE_RESTAURANT":
            return {
                ...state,
                restaurant: action.payload
            };
        case 'SET_FOOD_CATEGORY':
            return {
                ...state,
                foodFilters: {
                    ...state.foodFilters,
                    foodCategory: action.payload
                }
            }
        case 'SET_DAYS':
            return {
                ...state,
                foodFilters: {
                    ...state.foodFilters,
                    days: action.payload
                }
            }
        case 'SET_TEAMS':
            return {
                ...state,
                foodFilters: {
                    ...state.foodFilters,
                    teams: action.payload
                }
            }
        case 'SET_DISCOUNT_TYPES':
            return {
                ...state,
                foodFilters: {
                    ...state.foodFilters,
                    discountTypes: action.payload
                }
            }
        default:
            return state;
    }
}
